import { FormControl, FormLabel, Grid } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TableGrid } from "./TableGrid";
import { QuotationDetailBaseStore } from "../../../store/QuotationDetailBaseStore";

export type QuotationLogComponentProps = {
  store: () => QuotationDetailBaseStore;
};

export const QuotationLogComponent: React.FC<QuotationLogComponentProps> = ({ store }) => {
  const { t } = useTranslation(["general", "quotation"]);

  const { loadQuotationLogsMessages, quotationLogs } =
    store();

  const [loading, setLoading] = React.useState(true);

  const fetchQuotationLogMessages = React.useCallback(async () => {
    await loadQuotationLogsMessages().finally(() => setLoading(false));
  }, [loadQuotationLogsMessages]);

  React.useEffect(() => {
    fetchQuotationLogMessages();
  }, [fetchQuotationLogMessages]);

  return (
    <>
      {!loading && (
        <>
          <FormControl>
            <FormLabel
              id="quotation-log-label"
              sx={{ fontSize: "0.8rem", marginBottom: "1em" }}
            >
              {t("details.sections.general.quotationLogs", { ns: "quotation" })}
            </FormLabel>
          </FormControl>
          <Grid item lg={12}>
            <TableGrid data={quotationLogs} t={t} />
          </Grid>
        </>
      )}
    </>
  );
};
