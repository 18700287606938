import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../../apis/CtadmvenApi";
import {
	truckFieldsModel,
	truckFieldsModelBuilder,
} from "../models/TruckFieldsModel";
import { busFieldsModelBuilder } from "../models/BusFieldsModel";
import { orderMCDeliveryFieldsModelBuilder } from "../models/OrderMCDeliveryFieldsModel";
import { orderMCManagementFieldsModelBuilder } from "../models/OrderMCManagementFieldsModel";
import { TruckFieldsProps } from "../interfaces/TruckFieldsProps";
import { BusFieldsProps } from "../interfaces/BusFieldsProps";
import { OrderMCManagementFieldsProps } from "../interfaces/OrderMCManagementFieldsProps";
import {
	isOrderMCDeliveryCurrentDateUserType,
	OrderMCDeliveryFieldsProps,
} from "../interfaces/OrderMCDeliveryFieldsProps";
import { BaseFieldsProps } from "../interfaces/BaseFieldsProps";
import { SelectOptionProps } from "../../../../../utils/GenericInterfaces";
import { VehicleType } from "../../../../../utils/GenericTypes";
import { propExistsInArray } from "../../../../../utils/ArrayUtils";
import { getPaymentTermsOptions } from "../../../../../utils/PaymentTermsUtil";

configure({ enforceActions: "always" });

export class ArgDetailsBaseStore {
	@observable fields: TruckFieldsProps | BusFieldsProps = truckFieldsModel;
	@observable hasChanges: boolean = false;
	@observable userEmail: string = "";
	@observable vehicleType: VehicleType = "Truck";
	@observable dealersOptions: Array<SelectOptionProps> = [];
	@observable vehicleLocationOptions: Array<SelectOptionProps> = [];
	@observable shippingCompaniesOptions: Array<SelectOptionProps> = [];
	@observable paymentTermsOptions: Array<SelectOptionProps> = [];
	@observable paqueteOptions: Array<SelectOptionProps> = [];
	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	resetStore = () => {};
	resetBaseStore = () => {
		this.fields = truckFieldsModel;
		this.hasChanges = false;
		this.userEmail = "";
	};

	//========================================set observables=========================================
	setUserEmail = (userEmail: string) => (this.userEmail = userEmail);

	setVehicleType = (vehicleType: VehicleType) =>
		(this.vehicleType = vehicleType);

	setTruckFieldsDetails = (data: any) =>
		(this.fields = truckFieldsModelBuilder(data));

	setBusFieldsDetailsBus = (data: any) =>
		(this.fields = busFieldsModelBuilder(data));

	setVariants = (data: any) => (this.fields.variants = data ?? []);

	setOrderMCDelivery = (data: any) =>
		(this.fields.orderMCDelivery = orderMCDeliveryFieldsModelBuilder(data));

	setOrderMCManagement = (data: any) =>
		(this.fields.orderMCManagement = orderMCManagementFieldsModelBuilder(data));

	setPaymentTermsOptions = (data: any, language: string) =>
		(this.paymentTermsOptions = getPaymentTermsOptions(data, language));

	setHasChanges = () => {};

	setEditableDetails = () => {};
	//END: observables

	//=========================================set fields on change=========================================
	@action setMCManagementFieldByKeyOnChange = <
		K extends keyof OrderMCManagementFieldsProps,
	>(
		fieldKey: K,
		newValue: OrderMCManagementFieldsProps[K],
		hasErrors: boolean = false
	) => {
		if (hasErrors) {
			return;
		}
		this.fields.orderMCManagement[fieldKey] = newValue;
		this.setHasChanges();
	};

	@action setFieldByKeyOnChange = <K extends keyof BaseFieldsProps>(
		fieldKey: K,
		newValue: TruckFieldsProps[K] | BusFieldsProps[K],
		hasErrors: boolean = false
	) => {
		if (hasErrors) {
			return;
		}
		this.fields[fieldKey] = newValue;
		this.setHasChanges();
	};

	@action setMCDeliveryFieldByKeyOnChange = <
		K extends keyof OrderMCDeliveryFieldsProps,
	>(
		fieldKey: K,
		newValue: OrderMCDeliveryFieldsProps[K],
		hasErrors: boolean = false
	) => {
		if (hasErrors) {
			return;
		}

		this.fields.orderMCDelivery[fieldKey] = newValue;

		if (isOrderMCDeliveryCurrentDateUserType(fieldKey)) {
			if (newValue !== null) {
				this.fields.orderMCDelivery[`${fieldKey}User`] = this.userEmail;
			} else {
				this.fields.orderMCDelivery[`${fieldKey}User`] = "";
			}
			this.setOrderMCDeliveryCurrentFinalDelivery();
		}
		this.setHasChanges();
	};

	private readonly setOrderMCDeliveryCurrentFinalDelivery = () => {
		if (
			this.fields.orderMCDelivery.firmBilling &&
			this.fields.orderMCDelivery.financialDelivery &&
			this.fields.orderMCDelivery.physicalDelivery
		) {
			this.fields.orderMCDelivery.finalDelivery =
				this.fields.orderMCDelivery.physicalDelivery;
			this.fields.orderMCDelivery.finalDeliveryUser =
				this.fields.orderMCDelivery.physicalDeliveryUser;
		} else {
			this.fields.orderMCDelivery.finalDelivery = null;
			this.fields.orderMCDelivery.finalDeliveryUser = "";
		}
	};

	//END: set fields on change

	//========================================handle options========================================
	private readonly handleDealersOptions = async (
		fieldsAvailableForEdition: Array<string>
	) => {
		const canEdit = propExistsInArray("dealerId", fieldsAvailableForEdition);
		if (canEdit) {
			await this.loadDealers();
		} else {
			this.dealersOptions = [
				{
					label: this.fields.orderMCManagement.dealerName,
					value: this.fields.orderMCManagement.dealerId,
				},
			];
		}
	};

	private readonly handleVehicleLocationOptions = async (
		fieldsAvailableForEdition: Array<string>
	) => {
		const canEdit = propExistsInArray(
			"vehicleLocationId",
			fieldsAvailableForEdition
		);
		if (canEdit) {
			await this.loadVehicleLocations();
		} else {
			this.vehicleLocationOptions = [
				{
					label: this.fields.orderMCManagement.vehicleLocationName,
					value: this.fields.orderMCManagement.vehicleLocationId,
				},
			];
		}
	};

	private readonly handleShippingCompaniesOptions = async (
		fieldsAvailableForEdition: Array<string>
	) => {
		const canEdit = propExistsInArray(
			"shippingCompany",
			fieldsAvailableForEdition
		);
		if (canEdit) {
			await this.loadShippingCompanies();
		} else {
			this.shippingCompaniesOptions = [
				{
					label: this.fields.orderMCManagement.shippingCompany,
					value: this.fields.orderMCManagement.shippingCompany,
				},
			];
		}
	};

	private readonly handlePaymentTermsOptions = async (
		fieldsAvailableForEdition: Array<string>,
		language: string
	) => {
		const canEdit = propExistsInArray("paymentForm", fieldsAvailableForEdition);
		if (canEdit) {
			await this.loadPaymentTerms(language);
		} else {
			this.setPaymentTermsOptions(
				[
					{
						descriptionEsEs: this.fields.orderMCManagement.paymentFormEsEs,
						descriptionEnUs: this.fields.orderMCManagement.paymentFormEnUs,
						descriptionPtBr: this.fields.orderMCManagement.paymentFormPtBr,
						id: this.fields.orderMCManagement.paymentForm,
					},
				],
				language
			);
		}
	};

	private readonly handlePaquetesOptions = async (
		fieldsAvailableForEdition: Array<string>
	) => {
		if (this.vehicleType === "Truck") {
			const canEdit = propExistsInArray("paqueteId", fieldsAvailableForEdition);
			if (canEdit) {
				await this.loadPaquetes();
			} else {
				this.paqueteOptions = [
					{
						label: this.fields.orderMCManagement.paqueteDescription,
						value: this.fields.orderMCManagement.paqueteId,
					},
				];
			}
		}
	};

	//=========================================load details=========================================
	loadDetails = async (orderId: string) => {};
	loadDetailsBus = async (orderId: string) => {};
	loadOrderMktCompanyDelivery = async () => {};
	loadOrderMktCompanyManagement = async () => {};
	loadDealers = async () => {};
	loadVehicleLocations = async () => {};
	loadShippingCompanies = async () => {};
	loadPaymentTerms = async (language: string) => {};
	loadPaquetes = async () => {};
	loadVariants = async () => {};
	loadVariantsBus = async () => {};

	@action loadAllDetails = async (
		orderId: string,
		userEmail: string,
		vehicleType: VehicleType
	) => {
		this.resetStore();

		this.setVehicleType(vehicleType);
		this.setUserEmail(userEmail);

		if (vehicleType === "Truck") {
			await this.loadDetails(orderId);
		} else {
			await this.loadDetailsBus(orderId);
		}
		await this.loadOrderMktCompanyManagement();

		await this.loadOrderMktCompanyDelivery();

		this.setEditableDetails();
	};

	@action loadAllOptions = async (
		fieldsAvailableForEdition: Array<string>,
		language: string
	) => {
		await this.handleDealersOptions(fieldsAvailableForEdition);
		await this.handleVehicleLocationOptions(fieldsAvailableForEdition);
		await this.handleShippingCompaniesOptions(fieldsAvailableForEdition);
		await this.handlePaymentTermsOptions(fieldsAvailableForEdition, language);
		await this.handlePaquetesOptions(fieldsAvailableForEdition);
	};
	//END: load details
	update = async () => {};
}
