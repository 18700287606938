import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { VehicleAccordionProps } from "../../interfaces/Accordions/VehicleAccordionProps";
import AccordionCard from "../../../../AccordionCard/AccordionCard";
import ModelSectionBus from "../Sections/Bus/ModelSectionBus";
import ModelSection from "../Sections/ModelSection";
import ModelSectionEurope from "../../../Europe/DetailsBase/containers/Sections/ModelSection";
import { ModelSectionBusProps } from "../../interfaces/Sections/Bus/ModelSectionBus";
import { ModelSectionProps } from "../../interfaces/Sections/ModelSection";
import { ModelSectionProps as ModelSectionEuropeProps } from "../../../Europe/DetailsBase/interfaces/Sections/ModelSection";
import VehicleIdentificationSection from "../Sections/VehicleIdentificationSection";
import VariantsSection from "../Sections/VariantsSection";

const getModelByVehicleType = (props: VehicleAccordionProps) => {
	if (props.vehicleType === "Bus") {
		return (
			<ModelSectionBus {...(props.modelDetails as ModelSectionBusProps)} />
		);
	}
	if (props.vehicleType === "TruckEurope") {
		return (
			<ModelSectionEurope
				{...(props.modelDetails as ModelSectionEuropeProps)}
			/>
		);
	}
	return <ModelSection {...(props.modelDetails as ModelSectionProps)} />;
};

const VehicleAccordion: React.FC<VehicleAccordionProps> = (props) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="vehicleAccordion"
			title={t("details.accordions.vehicle")}
		>
			{getModelByVehicleType(props)}
			<VehicleIdentificationSection {...props.vehicleIdentificationDetails} />
			<VariantsSection {...props.variantsDetails} />
			{props.children}
		</AccordionCard>
	);
};

export default observer(VehicleAccordion);
