import { getNumberOrDefault, getStringOrDefault } from "../../../../../utils/ModelBuilderUItils";
import { TruckEditableProps } from "../../interfaces/Editable/TruckEditableDetails";

export const truckEditableModel: TruckEditableProps = {
	id: "",
	finalCustomer: "",
	bodybuilder: "",
	generalComment: "",
};

export function truckEditableModelBuilder(data: TruckEditableProps) {
	if (!data || !data.id) {return truckEditableModel;}
	return {
		id: data.id,
		bodybuilder: getStringOrDefault(data.bodybuilder),
		finalCustomer: getStringOrDefault(data.finalCustomer),
		generalComment: getStringOrDefault(data.generalComment),
		zoneId: getNumberOrDefault(data.zoneId),
	} as TruckEditableProps;
}
