import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { InvoiceDeliveryAccordionProps } from "../../interfaces/Accordions/InvoiceDeliveryAccordion";
import AccordionCard from "../../../../../AccordionCard/AccordionCard";
import ExportationLocationSection from "../../../../Details/containers/Sections/ExportationLocationSection";
import ExportationInvoiceSection from "../Sections/ExportationInvoiceSection";
import InvoicingDeliveryMarketCompanySection from "../../../../Details/containers/Sections/InvoicingDeliveryMarketCompanySection";
import ReservationExportSection from "../../../../Details/containers/Sections/ReservationExportSection";

const InvoiceDeliveryAccordion: React.FC<InvoiceDeliveryAccordionProps> = (
	details
) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="invoiceDeliveryAccordion"
			title={t("details.accordions.invoicingDelivery")}
		>
			<ReservationExportSection {...details.reservationExportDetails} />
			<ExportationLocationSection {...details.exportationLocationProps} />
			<ExportationInvoiceSection {...details.exportationInvoiceProps} />
			<InvoicingDeliveryMarketCompanySection
				{...details.invoicingDeliveryMarketCompanyProps}
			/>
		</AccordionCard>
	);
};

export default observer(InvoiceDeliveryAccordion);
