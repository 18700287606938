import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../../../apis/CtadmvenApi";
import _ from "lodash";
import {
	quotationReadOnlyDetailsModel,
	quotationReadOnlyDetailsModelBuilder,
} from "../model/QuotationReadOnlyDetailsModel";
import { QuotationReadOnlyDetails } from "../interfaces/QuotationReadOnlyDetails";
import { LocalCustomizationProps } from "../interfaces/LocalCustomizationProps";
import { QuotationEditableDetails } from "../interfaces/QuotationEditableDetails";
import {
	quotationEditableDetailsModel,
	quotationEditableDetailsModelBuilder,
} from "../model/QuotationEditableDetailsModel";
import {
	ReservationsProps,
	EditableReservationsProps,
} from "../interfaces/ReservationsProps";
import { QuotationRestoreOrCancelOptions } from "../interfaces/QuotationRestoreOrCancelOptions";
import {
	quotationRestoreOrCancelOptionsModel,
	quotationRestoreOrCancelOptionsModelBuilder,
} from "../model/QuotationRestoreOrCancelOptionsModel";
import { QuotationVehicleInfo } from "../interfaces/QuotationVehicleInfo";
import { AutoCompleteOptionProps } from "../../../../../utils/GenericInterfaces";
import {
	quotationManagerEditableDetailsModel,
	quotationManagerEditableDetailsModelBuilder,
} from "../model/QuotationManagerEditableDetailsModel";
import { QuotationManagerEditableDetails } from "../interfaces/QuotationManagerEditableDetails";
import { PaymentTermsProps } from "../interfaces/PaymentTermsProps";
import { getPaymentTermsModel } from "../model/PaymentTermsModel";
import { QuotationSalesmanEditableDetails } from "../interfaces/QuotationSalesmanEditableDetails";
import {
	quotationSalesmanEditableDetailsModel,
	quotationSalesmanEditableDetailsModelBuilder,
} from "../model/QuotationSalesmanEditableDetailsModel";
import { GlosaFieldsEditableProps } from "../interfaces/GlosaFieldsEditableProps";
import {
	glosaReadOnlyDetailsModel,
	glosaReadOnlyDetailsModelBuilder,
} from "../model/GlosaReadOnlyDetailsModel";
import { GlosaReadOnlyDetails } from "../interfaces/GlosaReadOnlyDetails";
import { GlosaTextEditableProps } from "../interfaces/GlosaTextEditableProps";
import {
	glosaFieldsEditableModel,
	glosaFieldsEditableModelBuilder,
} from "../model/GlosaFieldsEditableModel";
import {
	glosaTextEditableModel,
	glosaTextEditableModelBuilder,
} from "../model/GlosaTextEditableModel";
import { PaymentRetoma } from "../interfaces/QuotationPaymentTermRetoma";
import {
	getPaymentRetoma,
	paymentTermRetomaModelBuilder,
} from "../model/PaymentTermRetomaModel";
import { QuotationBaseEditableDetails } from "../interfaces/QuotationBaseEditableDetails";
import { QuotationLogProps } from "../interfaces/QuotationLogProps";
import { PriceAndPaymentProps } from "../interfaces/PriceAndPaymentProps";
import { getEnumKeyByValue } from "../../../../../utils/enums/EnumMethods";
import { QuotationWorkflowStatusEnum } from "../../../../../utils/enums/QuotationWorkflowStatusEnum";
import { CountryRegionIso } from "../../../../../enums/CountryRegionIso";
import { getCountryCode, isChileQuotation } from "../../List/utils/StoreUtils";
import { QuotationStatusSASEnum } from "../../../../../utils/enums/QuotationStatusSASEnum";

configure({ enforceActions: "always" });

export class QuotationDetailBaseStore {
	//models
	@observable readOnlyDetails: QuotationReadOnlyDetails =
	quotationReadOnlyDetailsModel;
	@observable editableDetails: QuotationEditableDetails =
	quotationEditableDetailsModel;
	@observable initialEditableDetails: QuotationEditableDetails =
	quotationEditableDetailsModel;
	@observable managerEditableDetails: QuotationManagerEditableDetails =
	quotationManagerEditableDetailsModel;
	@observable initialManagerEditableDetails: QuotationManagerEditableDetails =
	quotationManagerEditableDetailsModel;
	@observable salesmanEditableDetails: QuotationSalesmanEditableDetails =
	quotationSalesmanEditableDetailsModel;
	@observable initialSalesmanEditableDetails: QuotationSalesmanEditableDetails =
	quotationSalesmanEditableDetailsModel;
	@observable glosaReadOnlyDetails: GlosaReadOnlyDetails =
	glosaReadOnlyDetailsModel;
	@observable glosaTextEditableDetails: GlosaTextEditableProps =
	glosaTextEditableModel;
	@observable initialGlosaTextEditableDetails: GlosaTextEditableProps =
	glosaTextEditableModel;
	@observable glosaFieldsEditableDetails: GlosaFieldsEditableProps =
	glosaFieldsEditableModel;
	@observable initialGlosaFieldsEditableDetails: GlosaFieldsEditableProps =
	glosaFieldsEditableModel;
	@observable restoreOrCancelOptions: QuotationRestoreOrCancelOptions =
	quotationRestoreOrCancelOptionsModel;
	//flags
	@observable hasChanges: boolean = false;
	@observable hasGeneralChanges: boolean = false;
	@observable hasManagerChanges: boolean = false;
	@observable hasPaymentTermsChanges: boolean = false;
	@observable hasPriceAndPaymentChanges: boolean = false;
	@observable hasPaymentTermRetomaChanges: boolean = false;
	@observable hasSalesmanChanges: boolean = false;
	@observable hasGlosaTextChanges: boolean = false;
	@observable hasGlosaFieldsChanges: boolean = false;
	@observable hasErrors: boolean = false;
	@observable hasRetomaErrors: boolean = false;
	@observable hasReservationsErrors: boolean = false;
	@observable hasPaymentTermsErrors: boolean = false;
	@observable hasPriceAndPaymentErrors: boolean = false;
	@observable loading: boolean = true;
	@observable hasDuplicate: boolean = false;
	//options
	@observable salesmanOptions: Array<AutoCompleteOptionProps> = [];
	@observable paymentTermsOptions: Array<PaymentTermsProps> = [];
	@observable financialCustomerOptions: Array<AutoCompleteOptionProps> = [];
	@observable quotationLogs: Array<QuotationLogProps> = [];
	@observable isFinancialCustomer: boolean = false;
	@observable region: CountryRegionIso = CountryRegionIso.BR;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action setIsFinancialCustomer = (isFinancialCustomer: boolean) =>
		(this.isFinancialCustomer = isFinancialCustomer);

	@action private resetStoreModels = () => {
		this.readOnlyDetails = quotationReadOnlyDetailsModel;
		this.editableDetails = quotationEditableDetailsModel;
		this.initialEditableDetails = quotationEditableDetailsModel;
		this.managerEditableDetails = quotationManagerEditableDetailsModel;
		this.initialManagerEditableDetails = quotationManagerEditableDetailsModel;
		this.salesmanEditableDetails = quotationSalesmanEditableDetailsModel;
		this.initialSalesmanEditableDetails = quotationSalesmanEditableDetailsModel;
		this.glosaReadOnlyDetails = glosaReadOnlyDetailsModel;
		this.glosaTextEditableDetails = glosaTextEditableModel;
		this.initialGlosaTextEditableDetails = glosaTextEditableModel;
		this.glosaFieldsEditableDetails = glosaFieldsEditableModel;
		this.initialGlosaFieldsEditableDetails = glosaFieldsEditableModel;
		this.restoreOrCancelOptions = quotationRestoreOrCancelOptionsModel;
	};

	@action private resetStoreFlags = () => {
		this.hasChanges = false;
		this.hasGeneralChanges = false;
		this.hasManagerChanges = false;
		this.hasPaymentTermsChanges = false;
		this.hasPaymentTermRetomaChanges = false;
		this.hasPriceAndPaymentChanges = false;
		this.hasSalesmanChanges = false;
		this.hasGlosaTextChanges = false;
		this.hasGlosaFieldsChanges = false;
		this.hasErrors = false;
		this.loading = true;
		this.hasRetomaErrors = false;
		this.hasReservationsErrors = false;
		this.hasPaymentTermsErrors = false;
		this.hasPriceAndPaymentErrors = false;
		this.hasDuplicate = false;
		this.isFinancialCustomer = false;
	};

	@action resetStore = () => {
		this.resetStoreModels();
		this.resetStoreFlags();
	};

	@action private setGlosaDetails = (glosaDetails: any, id: string) => {
		this.glosaReadOnlyDetails = glosaReadOnlyDetailsModelBuilder({
			...this.glosaReadOnlyDetails,
			...glosaDetails,
		});

		const glosaTextEditableDetails = glosaTextEditableModelBuilder(
			{
				...this.initialGlosaTextEditableDetails,
				...glosaDetails,
			},
			id
		);

		this.glosaTextEditableDetails = glosaTextEditableDetails;
		this.initialGlosaTextEditableDetails = glosaTextEditableDetails;

		const glosaFieldsEditableDetails = glosaFieldsEditableModelBuilder(
			{
				...this.initialGlosaFieldsEditableDetails,
				...glosaDetails,
			},
			id
		);
		this.glosaFieldsEditableDetails = glosaFieldsEditableDetails;
		this.initialGlosaFieldsEditableDetails = glosaFieldsEditableDetails;
	};

	@action setRegion = (value: CountryRegionIso) =>
		(this.region = value);

	@action setDetails = (data: any) => {
		this.readOnlyDetails = quotationReadOnlyDetailsModelBuilder({
			...this.readOnlyDetails,
			...data,
		});

		this.editableDetails = quotationEditableDetailsModelBuilder({
			...this.editableDetails,
			...data,
		});

		this.initialEditableDetails = quotationEditableDetailsModelBuilder({
			...this.initialEditableDetails,
			...data,
		});

		this.managerEditableDetails = quotationManagerEditableDetailsModelBuilder({
			...this.managerEditableDetails,
			...data,
		});

		this.initialManagerEditableDetails =
			quotationManagerEditableDetailsModelBuilder({
				...this.initialManagerEditableDetails,
				...data,
			});

		this.setIsFinancialCustomer(Boolean(data.financialCustomerCode));

		this.salesmanEditableDetails = quotationSalesmanEditableDetailsModelBuilder(
			{
				...this.salesmanEditableDetails,
				...data,
			}
		);

		this.initialSalesmanEditableDetails =
			quotationSalesmanEditableDetailsModelBuilder({
				...this.initialSalesmanEditableDetails,
				...data,
			});

		this.restoreOrCancelOptions = quotationRestoreOrCancelOptionsModelBuilder({
			...data,
		});

		this.setGlosaDetails(data?.glosaDetails, data?.id);
	};

	@action loadDetails = async (id: string, isQuotationManagerUser: boolean) => {
		this.resetStore();
		if (id) {
			await this.api.quotationService
				.getDetails(id, this.region)
				.then(async (response) => {
					this.setDetails(response.data);
					await this.getSalesmanOptions(response.data, isQuotationManagerUser);
					await this.getPaymentTermsOptions();
					await this.getPaymentTermRetoma(id);
				});
		}
	};

	@action setQuotationManagerEditableField = <
		K extends keyof QuotationManagerEditableDetails,
	>(
		fieldKey: K,
		newValue: QuotationManagerEditableDetails[K]
	) => {
		const _managerEditableDetails: QuotationManagerEditableDetails =
			Object.assign({}, this.managerEditableDetails);
		_managerEditableDetails[fieldKey] = newValue;
		this.managerEditableDetails = _managerEditableDetails;

		this.checkManagerChanges();
	};

	@action setQuotationBaseEditableField = <
		K extends keyof QuotationBaseEditableDetails,
	>(
		fieldKey: K,
		newValue: QuotationBaseEditableDetails[K],
		isQuotationManagerUser: boolean
	) => {
		if (isQuotationManagerUser) {
			this.setQuotationManagerEditableField(fieldKey, newValue);
		}
		else {
			this.setQuotationSalesmanEditableField(fieldKey, newValue);
		}
	};

	@action setLocalCustomizationComment = (
		localCustomization: LocalCustomizationProps,
		newValue: string
	) => {
		let localToChange = this.editableDetails.customizations.find(
			(x) => x.id === localCustomization.id
		);

		if (!localToChange) {
			return;
		}

		localToChange.comment = newValue;

		this.checkGeneralChanges();
	};

	@action setQuotationSalesmanEditableField = <
		K extends keyof QuotationSalesmanEditableDetails,
	>(
		fieldKey: K,
		newValue: QuotationSalesmanEditableDetails[K]
	) => {
		const _salesmanEditableDetails: QuotationSalesmanEditableDetails =
			Object.assign({}, this.salesmanEditableDetails);
		_salesmanEditableDetails[fieldKey] = newValue;
		this.salesmanEditableDetails = _salesmanEditableDetails;

		this.checkSalesmanChanges();
	};

	@action setReservations = <K extends keyof EditableReservationsProps>(
		fieldKey: K,
		reservations: ReservationsProps,
		newValue: ReservationsProps[K]
	) => {
		let applicationToChange = this.editableDetails.reservations.find(
			(x) => x.id === reservations.id
		);

		if (!applicationToChange) {
			return;
		}

		applicationToChange[fieldKey] = newValue;

		this.checkGeneralChanges();
	};

	@action setPaymentTerms = <K extends keyof PaymentTermsProps>(
		fieldKey: K,
		paymentTermIndex: number,
		newValue: any,
		errors: string
	) => {
		let applicationToChange =
			this.editableDetails.paymentTerms[paymentTermIndex];

		if (!applicationToChange) {
			return;
		}

		applicationToChange[fieldKey] = newValue;
		applicationToChange.hasErrors = errors;

		this.checkAndSetHasPaymentTermsErrors();
		this.checkPaymentTerms();
	};

	@action setPriceAndPayment = <K extends keyof PriceAndPaymentProps>(
		fieldKey: K,
		newValue: any,
		errors: string
	) => {
		let applicationToChange = this.editableDetails.priceAndPayment;

		if (!applicationToChange) {
			return;
		}

		applicationToChange[fieldKey] = newValue;
		applicationToChange.hasErrors = errors;

		this.checkAndSetHasPriceAndPaymentErrors();
		this.checkPriceAndPaymentTerms();
	};

	@action setGlosaTextEditableFieldByKey = <
		K extends keyof GlosaTextEditableProps,
	>(
		fieldKey: K,
		newValue: GlosaTextEditableProps[K]
	) => {
		const _glosaTextEditableDetails: GlosaTextEditableProps = Object.assign(
			{},
			this.glosaTextEditableDetails
		);
		_glosaTextEditableDetails[fieldKey] = newValue;
		this.glosaTextEditableDetails = _glosaTextEditableDetails;

		this.checkGlosaTextChanges();
	};

	@action setGlosaFieldsEditableFieldByKey = <
		K extends keyof GlosaFieldsEditableProps,
	>(
		fieldKey: K,
		newValue: GlosaFieldsEditableProps[K]
	) => {
		const _glosaFieldsEditableDetails: GlosaFieldsEditableProps = Object.assign(
			{},
			this.glosaFieldsEditableDetails
		);
		_glosaFieldsEditableDetails[fieldKey] = newValue;
		this.glosaFieldsEditableDetails = _glosaFieldsEditableDetails;

		this.checkGlosaFieldsChanges();
	};

	@action setPaymentTermRetoma = <K extends keyof PaymentRetoma>(
		fieldKey: K,
		paymentTermIndex: number,
		newValue: any,
		error: string
	) => {
		let applicationToChange =
			this.editableDetails.paymentTermRetoma.paymentRetomaList[
			paymentTermIndex
			];

		if (!applicationToChange) {
			return;
		}

		applicationToChange[fieldKey] = newValue;
		applicationToChange.hasErrors = error;

		this.checkAndSetHasRetomaErrors();
		this.checkPaymentTermRetoma();
	};

	@action setRetomaTotalProvision = (retomaTotalProvision: any) => {
		this.editableDetails.paymentTermRetoma.retomaTotalProvision =
			retomaTotalProvision;

		this.checkPaymentTermRetoma();
	};

	@action private checkGeneralChanges = () => {
		this.hasGeneralChanges = !_.isEqual(
			this.initialEditableDetails,
			this.editableDetails
		);
		this.checkChanges();
	};

	@action private checkManagerChanges = () => {
		this.hasManagerChanges = !_.isEqual(
			this.initialManagerEditableDetails,
			this.managerEditableDetails
		);
		this.checkChanges();
	};

	@action private checkPaymentTerms = () => {
		this.hasPaymentTermsChanges = !_.isEqual(
			this.initialEditableDetails?.paymentTerms,
			this.editableDetails?.paymentTerms
		);
		this.checkChanges();
	};

	@action private checkPriceAndPaymentTerms = () => {
		this.hasPriceAndPaymentChanges = !_.isEqual(
			this.initialEditableDetails?.priceAndPayment,
			this.editableDetails?.priceAndPayment
		);
		this.checkChanges();
	};

	@action private checkPaymentTermRetoma = () => {
		this.hasPaymentTermRetomaChanges = !_.isEqual(
			this.initialEditableDetails?.paymentTermRetoma,
			this.editableDetails?.paymentTermRetoma
		);
		this.checkChanges();
	};

	@action private checkSalesmanChanges = () => {
		this.hasSalesmanChanges = !_.isEqual(
			this.initialSalesmanEditableDetails,
			this.salesmanEditableDetails
		);
		this.checkChanges();
	};

	@action private checkGlosaTextChanges = () => {
		this.hasGlosaTextChanges = !_.isEqual(
			this.initialGlosaTextEditableDetails,
			this.glosaTextEditableDetails
		);
		this.checkChanges();
	};

	@action private checkGlosaFieldsChanges = () => {
		this.hasGlosaFieldsChanges = !_.isEqual(
			this.initialGlosaFieldsEditableDetails,
			this.glosaFieldsEditableDetails
		);
		this.checkChanges();
	};

	@action private checkChanges = () => {
		this.hasChanges =
			this.hasGeneralChanges ||
			this.hasManagerChanges ||
			this.hasPaymentTermsChanges ||
			this.hasPaymentTermRetomaChanges ||
			this.hasSalesmanChanges ||
			this.hasGlosaTextChanges ||
			this.hasGlosaFieldsChanges ||
			this.hasPriceAndPaymentChanges;
	};

	@action getFactoryOrderByChassi = async (
		chassi: string,
		id: string,
		translate: any
	) => {
		if (chassi.length === 0) {
			return;
		}

		await this.api.orderService
			.getVehicleInfoByChassi(chassi, getCountryCode(this.region))
			.then(async (apiSasResponse) => {
				let quotationVehicleInfoEurope: QuotationVehicleInfo | null = null;

				if (!apiSasResponse?.data || apiSasResponse?.data === "") {
					quotationVehicleInfoEurope =
						await this.getVehicleInfoByChassiFromEurope(chassi);
				}

				let applicationToChange = this.editableDetails.reservations.filter(
					(x) => x.id === id
				)[0];

				applicationToChange.factoryOrder =
					quotationVehicleInfoEurope?.factoryOrder ||
					apiSasResponse?.data?.factoryOrder;

				applicationToChange.commercialModel =
					quotationVehicleInfoEurope?.commercialModel ||
					apiSasResponse?.data?.commercialModel;

				applicationToChange.orderId = apiSasResponse?.data?.orderId || "";

				applicationToChange.europeOrderSASId =
					quotationVehicleInfoEurope?.europeOrderSASId || "";

				applicationToChange.foError = "";

				if (!apiSasResponse?.data && !quotationVehicleInfoEurope) {
					applicationToChange.chassiError = translate("validator.notFound", {
						ns: "general",
					});
				}
				else {
					applicationToChange.chassiError = "";
				}
			});

		this.hasDuplicate = this.checkHasDuplicate();

		this.checkGeneralChanges();
	};

	@action getChassiByFactoryOrder = async (
		factoryOrder: string,
		id: string,
		translate: any
	) => {
		if (factoryOrder.length === 0) {
			return;
		}

		await this.api.orderService
			.getVehicleInfoByFactoryOrder(factoryOrder, getCountryCode(this.region))
			.then(async (apiSasResponse) => {
				let quotationVehicleInfoEurope: QuotationVehicleInfo | null = null;

				if (!apiSasResponse?.data || apiSasResponse?.data === "") {
					quotationVehicleInfoEurope =
						await this.getVehicleInfoByFactoryOrderEurope(factoryOrder);
				}

				let applicationToChange = this.editableDetails.reservations.filter(
					(x) => x.id === id
				)[0];

				applicationToChange.chassi =
					quotationVehicleInfoEurope?.chassi || apiSasResponse?.data?.chassi;

				applicationToChange.commercialModel =
					quotationVehicleInfoEurope?.commercialModel ||
					apiSasResponse?.data?.commercialModel;

				applicationToChange.orderId = apiSasResponse?.data?.orderId || "";

				applicationToChange.europeOrderSASId =
					quotationVehicleInfoEurope?.europeOrderSASId || "";

				applicationToChange.chassiError = "";

				if (!apiSasResponse?.data && !quotationVehicleInfoEurope) {
					applicationToChange.foError = translate("validator.notFound", {
						ns: "general",
					});
				}
				else {
					applicationToChange.foError = "";
				}
			});

		this.hasDuplicate = this.checkHasDuplicate();

		this.checkGeneralChanges();
	};

	@action getSalesmanOptions = async (
		quotationData: any,
		isQuotationManagerUser: boolean
	) => {
		const defaultSalesman = {
			label: quotationData.salesmanDisplayName,
			value: quotationData.salesmanId,
			searchValue: "",
		};

		if (!isQuotationManagerUser) {
			return (this.salesmanOptions = [defaultSalesman]);
		}

		await this.api.salesmanService.getSalesmanOptions().then((rs: any) => {
			if (rs.data) {
				this.salesmanOptions = toJS(rs.data).map(
					(m: any) =>
						({
							label: m.displayName,
							value: m.id,
							searchValue: `${m.displayName}/${m.email}/${m.tbCode}`,
						}) as AutoCompleteOptionProps
				);
			}
		});

		if (
			quotationData.salesmanId &&
			!this.salesmanOptions.find((f) => f.value === quotationData.salesmanId)
		) {
			this.salesmanOptions.push(defaultSalesman);
		}
	};

	@action getPaymentTermsOptions = async () => {
		await this.api.paymentTermsService
			.getPaymentTerms(getCountryCode(this.region))
			.then((rs: any) => {
				if (rs.data) {
					this.paymentTermsOptions = toJS(rs.data);
				}
			});
	};

	@action getPaymentTermRetoma = async (id: string) => {
		await this.api.paymentTermsService
			.getPaymentTermRetoma(id)
			.then((rs: any) => {
				const paymentRetomaData = paymentTermRetomaModelBuilder(rs.data, id);

				this.editableDetails = {
					...this.editableDetails,
					paymentTermRetoma: paymentRetomaData,
				};

				this.initialEditableDetails = {
					...this.initialEditableDetails,
					paymentTermRetoma: paymentRetomaData,
				};
			});
	};

	updateReservations = async () => { };

	updateLocalCustomization = async () => { };

	updateManagerFields = async () => { };

	updatePaymentTerms = async () => { };

	updateSalesmanFields = async () => { };

	updateGlosaText = async () => { };

	updateGlosaFields = async () => { };

	updateAdelanto = async () => { };

	update = async (isAllowedToEditReservation: boolean) => { };

	isCanceledQuotation = () => this.readOnlyDetails.statusSAS === QuotationStatusSASEnum.Canceled
	isRequestedApprovalQuotation = () => this.readOnlyDetails.workflowStatus ===
		getEnumKeyByValue(QuotationWorkflowStatusEnum.Requested, QuotationWorkflowStatusEnum);
	isApprovedQuotation = () => this.readOnlyDetails.workflowStatus ===
		getEnumKeyByValue(QuotationWorkflowStatusEnum.Approved, QuotationWorkflowStatusEnum);
	isRejectedQuotation = () => this.readOnlyDetails.workflowStatus ===
		getEnumKeyByValue(QuotationWorkflowStatusEnum.Rejected, QuotationWorkflowStatusEnum);
	canEditCustomerDetails = () => isChileQuotation(this.region) &&
		!this.isRequestedApprovalQuotation() &&
		!this.isCanceledQuotation() &&
		!this.isApprovedQuotation();
	canEditAndIsNotRejected = () => this.canEditCustomerDetails() && !this.isRejectedQuotation();

	@action checkAndSetReservationsHasErrors = () => {
		const hasFoError = this.editableDetails.reservations.some((x) => x.foError);

		const hasChassiError = this.editableDetails.reservations.some(
			(x) => x.chassiError.length > 0
		);

		this.setHasReservationsErrors(hasFoError || hasChassiError);
		this.setHasErrors();
	};

	@action checkAndSetHasRetomaErrors = () => {
		const hasErrors =
			this.editableDetails.paymentTermRetoma.paymentRetomaList.some(
				(x) => x.hasErrors
			);

		this.setHasRetomaErrors(hasErrors);
		this.setHasErrors();
	};

	@action checkAndSetHasPaymentTermsErrors = () => {
		const hasErrors = this.editableDetails.paymentTerms.some(
			(x) => x.hasErrors
		);

		this.setHasPaymentTermsErrors(hasErrors);
		this.setHasErrors();
	};

	@action checkAndSetHasPriceAndPaymentErrors = () => {
		const hasErrors =
			this.editableDetails.priceAndPayment.hasErrors !== null &&
			this.editableDetails.priceAndPayment.hasErrors !== undefined &&
			this.editableDetails.priceAndPayment.hasErrors.length > 0;

		this.setHasPriceAndPaymentErrors(hasErrors);
		this.setHasErrors();
	};

	@action private setHasErrors = () =>
	(this.hasErrors =
		this.hasReservationsErrors ||
		this.hasRetomaErrors ||
		this.hasPaymentTermsErrors ||
		this.hasPriceAndPaymentErrors);

	@action private setHasReservationsErrors = (hasReservationErrors: boolean) =>
		(this.hasReservationsErrors = hasReservationErrors);

	@action private setHasRetomaErrors = (hasRetomaErrors: boolean) =>
		(this.hasRetomaErrors = hasRetomaErrors);

	@action private setHasPaymentTermsErrors = (hasPaymentTermsErrors: boolean) =>
		(this.hasPaymentTermsErrors = hasPaymentTermsErrors);

	@action private setHasPriceAndPaymentErrors = (
		hasPriceAndPaymentErrors: boolean
	) => (this.hasPriceAndPaymentErrors = hasPriceAndPaymentErrors);

	@action setLoading = (loading: boolean) => (this.loading = loading);

	@action restoreOrCancel = async () => {
		return await this.api.quotationService.restoreOrCancelQuotation(
			this.restoreOrCancelOptions
		);
	};

	@action sendToWorkflowApproval = async () => {
		return await this.api.quotationService.sendToWorkflowApproval(
			this.readOnlyDetails.id
		);
	};

	@action setHasFoError = (error: string, id: string) => {
		let appMerge = this.editableDetails.reservations.find((x) => x.id === id);

		if (!appMerge) {
			return;
		}

		appMerge.foError = error;
	};

	@action setChassiError = (error: string, id: string) => {
		let appMerge = this.editableDetails.reservations.find((x) => x.id === id);

		if (!appMerge) {
			return;
		}

		appMerge.chassiError = error;
	};

	checkHasDuplicate = (): boolean => {
		const hasDuplicateFO = this.editableDetails.reservations
			.map((item) => item.factoryOrder)
			.filter(
				(item, index, self) =>
					item !== "" && item && self.indexOf(item) !== index
			);

		return hasDuplicateFO.length > 0;
	};

	getVehicleInfoByFactoryOrderEurope = async (
		factoryOrder: string
	): Promise<QuotationVehicleInfo | null> => {
		let quotationVehicleInfo: QuotationVehicleInfo | null = null;
		return quotationVehicleInfo;
	};

	getVehicleInfoByChassiFromEurope = async (
		chassi: string
	): Promise<QuotationVehicleInfo | null> => {
		let quotationVehicleInfo: QuotationVehicleInfo | null = null;
		return quotationVehicleInfo;
	};

	addNewPaymentTerm = () => {
		const paymentTerm = getPaymentTermsModel(this.paymentTermsOptions[0]);

		this.editableDetails.paymentTerms.push(paymentTerm);

		this.checkPaymentTerms();
	};

	addNewPaymentTermRetoma = () => {
		const paymentRetoma = getPaymentRetoma();

		this.editableDetails.paymentTermRetoma.paymentRetomaList.push(
			paymentRetoma
		);

		this.checkPaymentTermRetoma();
	};

	removePaymentTerm = (paymentTermToDelete: PaymentTermsProps) => {
		this.editableDetails.paymentTerms =
			this.editableDetails.paymentTerms.filter(
				(x) =>
					x.quotationPaymentTermsId !==
					paymentTermToDelete.quotationPaymentTermsId
			);

		this.checkPaymentTerms();
	};

	removePaymentTermRetoma = (paymentRetomaToDelete: PaymentRetoma) => {
		this.editableDetails.paymentTermRetoma.paymentRetomaList =
			this.editableDetails.paymentTermRetoma.paymentRetomaList.filter(
				(x) => x.id !== paymentRetomaToDelete.id
			);

		if (!this.editableDetails.paymentTermRetoma.paymentRetomaList.length) {
			this.editableDetails.paymentTermRetoma.retomaTotalProvision = 0;
		}

		this.checkPaymentTermRetoma();
	};

	@action loadFinancialCustomer = async (isQuotationSalesmanUser: boolean) => {
		const defaultFinancialCustomer = {
			label: this.readOnlyDetails.financialCustomerName,
			value: this.salesmanEditableDetails.financialCustomerCode ?? "",
			searchValue: "",
		};

		if (!isQuotationSalesmanUser) {
			return (this.financialCustomerOptions = [defaultFinancialCustomer]);
		}

		await this.api.customerService
			.getFinancialCustomerByCountry(getCountryCode(this.region))
			.then((rs: any) => {
				if (rs.data) {
					this.financialCustomerOptions = toJS(rs.data).map(
						(m: any) =>
							({
								label: m.name,
								value: m.code,
								searchValue: `${m.name}/${m.code}`,
							}) as AutoCompleteOptionProps
					);
				}
			});
	};

	isWorkflowRejected = (): boolean =>
		this.readOnlyDetails.workflowStatus ===
		getEnumKeyByValue(
			QuotationWorkflowStatusEnum.Rejected,
			QuotationWorkflowStatusEnum
		);

	@action loadQuotationLogsMessages = async () => {
		await this.api.quotationLogService
			.getQuotationLogsMessages(this.readOnlyDetails.id)
			.then((rs: any) => {
				if (rs.data) {
					this.quotationLogs = toJS(rs.data);
				}
			});
	};
}