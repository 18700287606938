import { invoiceExportationModel } from "../../../../components/OrderPages/Details/models/InvoiceExportationModel";
import { invoicingDeliveryMarketCompanyFieldsModel } from "../../../../components/OrderPages/Details/models/InvoicingDeliveryMarketCompanyFieldsModel";
import { localizationExportFieldsModel } from "../../../../components/OrderPages/Details/models/LocalizationExportFieldsModel";
import { TruckReadOnlyProps } from "../interfaces/TruckReadOnlyDetails";
import { reservationExportModel } from "./ReservationExportModel";

export const truckReadOnlyModel: TruckReadOnlyProps = {
	id: "",
	assemblyOrder: "",
	deliveryProposal: null,
	commercialTrimmingPackages: "",
	availabilityDate: null,
	salesman: "",
	factoryOrder: "",
	chassi: "",
	saleStatus: "",
	foOriginalCustomerOM: "",
	lastUpdateEventTypeName: "",
	productionModel: "",
	lineCode: "",
	commercialModel: "",
	class: "",
	vehicleIdentificationNumber: "",
	engineSerialNumber: "",
	variants: [],
	lcd: null,
	cdd: null,
	cddeol: null,
	online: null,
	offline: null,
	asWeekDate: null,
	greenOk: null,
	lineSequenceDate: null,
	lineSequence: "",
	onlineDelay: 0,
	asWeekDelay: 0,
	asWeekDateInitial: null,
	cddRespec: null,
	respecStartDate: null,
	variantsRespec: [],
	respecEndDate: null,
	invoiceExportation: invoiceExportationModel,
	reservationExport: reservationExportModel,
	invoicingDeliveryMarketCompany: invoicingDeliveryMarketCompanyFieldsModel,
	exportLocalization: localizationExportFieldsModel,
};

export function truckReadOnlyModelBuilder(data: TruckReadOnlyProps) {
	if (!data) return truckReadOnlyModel;
	return {
		...truckReadOnlyModel,
		...data,
		assemblyOrder: data.assemblyOrder ?? "",
		commercialTrimmingPackages: data.commercialTrimmingPackages ?? "",
		salesman: data.salesman ?? "",
		id: data.id ?? "",
		factoryOrder: data.factoryOrder ?? "",
		chassi: data.chassi ?? "",
		saleStatus: data.saleStatus ?? "",
		foOriginalCustomerOM: data.foOriginalCustomerOM ?? "",
		lastUpdateEventTypeName: data.lastUpdateEventTypeName ?? "",
		productionModel: data.productionModel ?? "",
		lineCode: data.lineCode ?? "",
		commercialModel: data.commercialModel ?? "",
		class: data.class ?? "",
		vehicleIdentificationNumber: data.vehicleIdentificationNumber ?? "",
		engineSerialNumber: data.engineSerialNumber ?? "",
		lineSequence: data.lineSequence ?? "",
	} as TruckReadOnlyProps;
}
