import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { toast } from "react-toastify";
import { Cancel, Restore } from "@mui/icons-material";
import DialogForm from "../../../../../components/Dialog/DialogForm";
import CancelButton from "../../../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../../../components/Dialog/Buttons/ApplyButton";
import { QuotationDetailBaseStore } from "../store/QuotationDetailBaseStore";

type QuotationRestoreCancelButtonProps = {
	fetchDetails: () => Promise<void>;
	store: () => QuotationDetailBaseStore;
};
const QuotationRestoreCancelButton: React.FC<
	QuotationRestoreCancelButtonProps
> = ({ fetchDetails, store }) => {
	const { t } = useTranslation(["quotation", "general"]);

	const [dialogVisible, setDialogVisible] = React.useState(false);

	const {
		restoreOrCancelOptions,
		restoreOrCancel,
		setLoading,
		readOnlyDetails,
	} = store();

	const handleRestoreOrCancelSubmit = async () => {
		setLoading(true);
		await restoreOrCancel()
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err.response.data))
			.finally(() => fetchDetails());
	};

	let buttonIcon = <Cancel />;
	let restoreCancelLabel = t("cancel", { ns: "general" });
	let restoreCancelLabelMsg = t("details.cancelQuotationMsg", {
		ns: "quotation",
		quotationId: readOnlyDetails.quotationId,
	});

	if (restoreOrCancelOptions.isRestoreAction) {
		buttonIcon = <Restore />;
		restoreCancelLabel = t("restore", { ns: "general" });
		restoreCancelLabelMsg = t("details.restoreQuotationMsg", {
			ns: "quotation",
			quotationId: readOnlyDetails.quotationId,
		});
	}

	return (
		<>
			<Button
				variant="contained"
				color="error"
				onClick={() => setDialogVisible(true)}
				startIcon={buttonIcon}
			>
				{restoreCancelLabel}
			</Button>
			<DialogForm
				id="quotation-restore-cancel-dialog"
				open={dialogVisible}
				title={t("confirmationMsg", { ns: "general" })}
				onClose={() => setDialogVisible(false)}
			>
				<DialogContent dividers>{restoreCancelLabelMsg}</DialogContent>
				<DialogActions>
					<CancelButton
						id="quotation-restore-cancel-cancelbtn"
						onCancel={() => setDialogVisible(false)}
						title={t("cancel", { ns: "general" })}
					/>
					<ApplyButton
						id="quotation-restore-cancel-applybtn"
						onSubmit={handleRestoreOrCancelSubmit}
						title={t("confirm", { ns: "general" })}
						startIcon={buttonIcon}
						color="error"
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(QuotationRestoreCancelButton);
