import {
	getDateOrDefault,
	getNumberOrDefault,
	getStringOrDefault,
} from "../../../../../utils/ModelBuilderUItils";
import { OrderMCManagementFieldsProps } from "../interfaces/OrderMCManagementFieldsProps";

export const orderMCManagementFieldsModel: OrderMCManagementFieldsProps = {
	dealerId: "",
	dealerName: "",
	invoiceProposalNumber: "",
	invoiceProposalDate: null,
	comission: undefined,
	commercialCircular: "",
	usedDelivery: false,
	paymentForm: "",
	paymentFormEnUs: "",
	paymentFormEsEs: "",
	paymentFormPtBr: "",
	currency: "",
	finalValue: undefined,
	vehicleLocationId: "",
	vehicleLocationName: "",
	shippingDate: null,
	shippingNumber: "",
	certificateDate: null,
	billingForecast: null,
	shippingCompany: "",
	destination: "",
	engineeringComments: "",
	paqueteId: "",
	paqueteDescription: "",
	salesSupportComments: "",
	financialComments: "",
	commercialComments: "",
	accountingComments: "",
	logisticComments: "",
};

export function orderMCManagementFieldsModelBuilder(
	data: any
): OrderMCManagementFieldsProps {
	if (!data) {
		return orderMCManagementFieldsModel;
	}
	return {
		...orderMCManagementFieldsModel,
		dealerId: getStringOrDefault(data.dealerId),
		dealerName: getStringOrDefault(data.dealerName),
		invoiceProposalDate: getDateOrDefault(data.invoiceProposalDate),
		invoiceProposalNumber: getStringOrDefault(data.invoiceProposalNumber),
		comission: getNumberOrDefault(data.comission),
		commercialCircular: getStringOrDefault(data.commercialCircular),
		usedDelivery: data.usedDelivery,
		paymentForm: getStringOrDefault(data.paymentForm),
		paymentFormEnUs: getStringOrDefault(data.paymentFormEnUs),
		paymentFormEsEs: getStringOrDefault(data.paymentFormEsEs),
		paymentFormPtBr: getStringOrDefault(data.paymentFormPtBr),
		currency: getStringOrDefault(data.currency),
		finalValue: getNumberOrDefault(data.finalValue),
		vehicleLocationId: getStringOrDefault(data.vehicleLocationId),
		vehicleLocationName: getStringOrDefault(data.vehicleLocationName),
		shippingDate: getDateOrDefault(data.shippingDate),
		shippingNumber: getStringOrDefault(data.shippingNumber),
		certificateDate: getDateOrDefault(data.certificateDate),
		billingForecast: getDateOrDefault(data.billingForecast),
		shippingCompany: getStringOrDefault(data.shippingCompany),
		destination: getStringOrDefault(data.destination),
		engineeringComments: getStringOrDefault(data.engineeringComments),
		paqueteId: getStringOrDefault(data.paqueteId),
		paqueteDescription: getStringOrDefault(data.paqueteDescription),
		salesSupportComments: getStringOrDefault(data.salesSupportComments),
		financialComments: getStringOrDefault(data.financialComments),
		commercialComments: getStringOrDefault(data.commercialComments),
		accountingComments: getStringOrDefault(data.accountingComments),
		logisticComments: getStringOrDefault(data.logisticComments),
	};
}
