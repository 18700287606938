import CtadmvenApi from "../../apis/CtadmvenApi";
import { PaqueteProps } from "../../components/OrderPages/Arg/DetailsBase/interfaces/PaqueteProps";

export interface IPaqueteService {
	getPaqueteList(): Promise<PaqueteProps>;
}

const base = "/Paquete";

export class PaqueteService implements IPaqueteService {
	constructor(private api: CtadmvenApi) {}

	getPaqueteList = async (): Promise<any> => this.api.ctadmvenClient.get(base);
}
