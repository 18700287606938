
import { observer } from "mobx-react";
import { groupsAllowedToEditQuotationReservation, groupsQuotationManager, groupsAllowedToSendToWorkflowApproval, groupsQuotationSalesman } from "../../../../../constants/AuthGroups/quotationArgentinaPage";
import { CountryRegionIso } from "../../../../../enums/CountryRegionIso";
import QuotationDetailIndex from "../../../Components/Details/container/QuotationDetailIndex";
import { useQuotationDetailArgentinaStore } from "../stores/QuotationDetailArgentinaStore";


const DetailsArgentina: React.FC = () => {
  return (
    <QuotationDetailIndex groupsAllowedToEditQuotationReservation={groupsAllowedToEditQuotationReservation}
      groupsAllowedToSendToWorkflowApproval={groupsAllowedToSendToWorkflowApproval}
      groupsQuotationManager={groupsQuotationManager}
      groupsQuotationSalesman={groupsQuotationSalesman}
      region={CountryRegionIso.AR}
      store={useQuotationDetailArgentinaStore}
    />
  );
}

export default observer(DetailsArgentina);


