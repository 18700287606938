import {
	PaymentRetoma,
	QuotationPaymentTermRetoma,
} from "../interfaces/QuotationPaymentTermRetoma";
import { GUID_EMPTY } from "../../../../../app/constants/config";

export const paymentTermRetomaModel: QuotationPaymentTermRetoma = {
	quotationId: GUID_EMPTY,
	retomaTotalProvision: 0,
	paymentRetomaList: [],
};

export const paymentTermRetomaModelBuilder = (
	data: any,
	id: string
): QuotationPaymentTermRetoma => {
	if (!data) data = paymentTermRetomaModel;
	return {
		quotationId: id,
		retomaTotalProvision: data.retomaTotalProvision ?? 0,
		paymentRetomaList: data.paymentRetomaList ?? [],
	};
};

export const getPaymentRetoma = (): PaymentRetoma => {
	return {
		id: crypto.randomUUID(),
		plate: "",
		value: 1,
		hasErrors: "",
	};
};
