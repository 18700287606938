import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../AccordionCard/AccordionCard";
import { observer } from "mobx-react";
import CustomerSection from "../Sections/CustomerSection";
import MktCompanyCommercialSection from "../Sections/MktCompanyCommercialSection";
import MktCompanyAdditionalCommentsSection from "../Sections/MktCompanyAdditionalCommentsSection";
import MktCompanyDeliverySection from "../Sections/MktCompanyDeliverySection";
import { CommercialAccordionProps } from "../../interfaces/Accordions/CommercialAccordion";
import GeneralCommentsSection from "../../../../Details/containers/Sections/GeneralCommentsSection";

const CommercialAccordion: React.FC<CommercialAccordionProps> = ({
	customerDetails,
	mktCompanyCommercial,
	mktCompanyAdditionalComments,
	mktCompanyDeliverySection,
	generalComment,
}) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="commercialAccordion"
			title={t("details.accordions.commercial")}
		>
			<CustomerSection {...customerDetails} />
			<MktCompanyCommercialSection {...mktCompanyCommercial} />
			<MktCompanyAdditionalCommentsSection {...mktCompanyAdditionalComments} />
			<MktCompanyDeliverySection {...mktCompanyDeliverySection} />
			<GeneralCommentsSection  {...generalComment}
			/>
		</AccordionCard>
	);
};

export default observer(CommercialAccordion);
