import { action, configure, makeObservable, observable } from "mobx";
import {
    CompositeFilterDescriptor,
    FilterDescriptor,
    normalizeFilters
} from "@progress/kendo-data-query";
import { removeFilter } from "../PreFilterUtils";

configure({ enforceActions: "always" });

class BaseFilterStore {
    @observable rootStore;
    @observable filters: CompositeFilterDescriptor | undefined = normalizeFilters({
        logic: "and",
        filters: [],
    });
    @observable hasPreFiltersApplied = false;
    @observable hasFormatError = false;

    constructor(rootStore: any) {
        this.rootStore = rootStore;
        makeObservable(this);
    }

    @action setHasFormatError = (value: boolean) => (this.hasFormatError = value);

    @action cleanAllFilters = () => {
        this.cleanFilters();
        this.cleanPreFilters();
    };

    @action cleanFilters = () => {
        this.filters = normalizeFilters({
            logic: "and",
            filters: [],
        });
    };

    @action cleanPreFilters = () => {
        if (!this.hasPreFiltersApplied) {
            return;
        }
        this.cleanSpecificPreFilters();
        this.hasPreFiltersApplied = false;
    };

    @action onUndo = () => {
        this.cleanSpecificPreFilters();
        this.cleanFilters();
    };

    cleanSpecificPreFilters() {
        // Implement in subclasses
    }

    @action getFiltersAsCompositeFilterDescriptor = () =>
        this.filters
            ? (this.filters.filters.slice() as CompositeFilterDescriptor[])
            : [];

    @action applyFilters = () => {
        this.cleanFilters();
        let andFilters = [] as Array<FilterDescriptor>;
        const orFiltersComposite = [] as Array<CompositeFilterDescriptor>;
        let orFiltersGroups = [] as Array<Array<FilterDescriptor>>;
        const allFiltersGroups = [] as Array<CompositeFilterDescriptor | FilterDescriptor>;

        ({ andFilters, orFiltersGroups } = this.applySpecificFilters(andFilters, orFiltersGroups));

        orFiltersGroups.forEach((element) => {
            if (element?.length > 0) {
                orFiltersComposite.push({
                    logic: "or",
                    filters: element,
                } as CompositeFilterDescriptor);
            }
        });

        if (andFilters.length > 0) {
            allFiltersGroups.push(...andFilters);
        }
        if (orFiltersComposite.length > 0) {
            allFiltersGroups.push(...orFiltersComposite);
        }
        if (allFiltersGroups.length > 0) {
            this.filters?.filters.push(...allFiltersGroups);
        }

        this.setHasPreFiltersApplied();
    };

    applySpecificFilters(
        andFilters: Array<FilterDescriptor>,
        orFiltersGroups: Array<Array<FilterDescriptor>>): any {
        return { andFilters, orFiltersGroups };
    }

    @action setHasPreFiltersApplied = () => {
        this.hasPreFiltersApplied = Boolean(this.filters && this.filters.filters.length > 0);
    };

    @action removeFilter = (filterToRemove: any) => {
        this.filters = removeFilter(filterToRemove, this.filters, this.rootStore);
    };
}

export default BaseFilterStore;
