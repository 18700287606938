import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import { observer } from "mobx-react";
import { CommercialAccordionProps } from "../../interfaces/Accordions/CommercialAccordion";
import GeneralCommentsSection from "../../../../../components/OrderPages/Details/containers/Sections/GeneralCommentsSection";
import CustomerSection from "../../../../../components/OrderPages/Details/containers/Sections/CustomerSection";

const CommercialAccordion: React.FC<CommercialAccordionProps> = ({
	customerDetails,
	generalComment,
}) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="commercialAccordion"
			title={t("details.accordions.commercial")}
		>
			<>
				<CustomerSection {...customerDetails} />
				<GeneralCommentsSection {...generalComment} />
			</>
		</AccordionCard>
	);
};

export default observer(CommercialAccordion);
