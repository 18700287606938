import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import { LocalizationExportSectionProps } from "../../interfaces/Sections/LocalizationExportSectionProps";
import TextForm from "../../../../FormInputs/TextForm";
import DateForm from "../../../../FormInputs/DateForm";
import OverlineTextCentered from "../../../../OverlineTextCentered/OverlineTextCentered";
import { objectHasValue } from "../../../../../utils/ObejctUtils";

const LocalizationExportSection: React.FC<LocalizationExportSectionProps> = ({
	fields,
	loadLocalizationExport,
}) => {
	const { t } = useTranslation(["order", "general"]);
	const [loading, setLoading] = React.useState(true);

	const fetchLocalizationExport = React.useCallback(async () => {
		await loadLocalizationExport().finally(() => setLoading(false));
	}, []);

	React.useEffect(() => {
		fetchLocalizationExport();
	}, [fetchLocalizationExport]);

	return (
		<>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={30} />
				</Grid>
			) : (
				<>
					{objectHasValue(fields) ? (
						<>
							<Grid item xs={9}>
								<TextForm
									label={t(
										"details.sections.localizationExport.thirdPartnerName"
									)}
									value={fields.thirdPartnerName}
								/>
							</Grid>
							<Grid item xs={3}>
								<DateForm
									label={t("details.sections.localizationExport.documentDate")}
									value={fields.documentDate}
									inputFormat="dd/MM/yyyy"
								/>
							</Grid>
							<Grid item xs={12}>
								<TextForm
									label={t("details.sections.localizationExport.facility")}
									value={fields.facility}
								/>
							</Grid>
						</>
					) : (
						<Grid item xs={12}>
							<OverlineTextCentered>
								{t("noRecordsAvailable", { ns: "general" })}
							</OverlineTextCentered>
						</Grid>
					)}
				</>
			)}
		</>
	);
};

export default observer(LocalizationExportSection);
