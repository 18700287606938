import { getDateOrDefault, getStringOrDefault } from "../../../../utils/ModelBuilderUItils";
import { LocalizationExportFieldsProps } from "../interfaces/LocalizationExportFieldsProps";

export const localizationExportFieldsModel: LocalizationExportFieldsProps = {
  thirdPartnerName: "",
  documentDate: null,
  facility: "",
};

export function localizationExportFieldsModelBuilder(
  data: any
): LocalizationExportFieldsProps {
  if (!data) {
    return localizationExportFieldsModel;
  }
  return {
    thirdPartnerName: getStringOrDefault(data.thirdPartnerName),
    documentDate: getDateOrDefault(data.documentDate),
    facility: getStringOrDefault(data.facility),
  };
}
