import { TFunction } from "i18next";
import { IColumnProps } from "../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import {
	MarketCompanyMenus,
	OrderBookTruckMenuType,
	TruckMarketCompanyMenus,
	TruckMenus,
} from "../../../../constants/Menus/OrderMenuUtils";
import {
	getBooleanOptions,
	getExportationStatusOptionsByEnum,
} from "../../../../utils/SelectOptions";
import { getDateTimeCell } from "../../../../utils/GenericGridUtils";

export function getGridColumnsByMenu(
	t: TFunction<"order" | "general", undefined>,
	menu: OrderBookTruckMenuType
): Array<IColumnProps> {
	return [
		{
			menus: [...TruckMenus],
			field: "order.assemblyOrder",
			title: t("gridFields.ao"),
			alwaysVisible: true,
			width: 100,
			customCell: {
				type: "linkCell",
				to: "./",
				toParams: "order.id",
				value: "order.assemblyOrder",
			},
		},
		{
			menus: [...TruckMenus],
			field: "order.chassi",
			title: t("gridFields.chassi"),
			alwaysVisible: true,
			width: 60,
		},
		{
			menus: [...TruckMenus],
			field: "order.foDealerRegion",
			title: t("gridFields.region"),
			alwaysVisible: true,
			width: 50,
		},
		{
			...getDateTimeCell("order.deliveryProposal", t("gridFields.serviceProposal"), [...TruckMenus], true),
		},
		{
			...getDateTimeCell("order.productionDate", t("gridFields.productionDate"), [...TruckMenus]),
		},
		{
			menus: [...TruckMenus],
			field: "order.commercialModel",
			title: t("gridFields.commercialModel"),
			alwaysVisible: true,
		},
		{
			menus: [...TruckMarketCompanyMenus],
			field: "order.businessControlVehicle",
			title: t("gridFields.bc_vehicle"),
			alwaysVisible: true,
		},
		{
			menus: [...TruckMenus],
			field: "order.commercialTrimmingPackages",
			title: t("gridFields.package"),
			alwaysVisible: true,
		},
		{
			...getDateTimeCell("order.asWeekDate", t("gridFields.asWeek"), [...TruckMenus]),
		},
		{
			...getDateTimeCell("order.lineSequenceDate", t("gridFields.lineSequenceDate"), [...TruckMenus]),
		},
		{
			...getDateTimeCell("order.greenOk", t("gridFields.greenOk"), [...TruckMenus]),
		},
		{
			...getDateTimeCell("order.lcd", t("gridFields.lcd"), [...TruckMenus]),
		},
		{
			...getDateTimeCell("order.cdd", t("gridFields.cdd"), [...TruckMenus]),
		},
		{
			menus: [...TruckMenus],
			field: "variant.engine",
			title: t("gridFields.engine"),
		},
		{
			menus: [...TruckMenus],
			field: "variant.axisArrangement",
			title: t("gridFields.axisArrangement"),
		},
		{
			menus: [...TruckMenus],
			field: "variant.application",
			title: t("gridFields.application"),
		},
		{
			menus: [...TruckMenus],
			field: "variant.cabin",
			title: t("gridFields.cabin"),
		},
		{
			menus: [...TruckMenus],
			field: "variant.gearbox",
			title: t("gridFields.gearbox"),
		},
		{
			menus: [...TruckMenus],
			field: "variant.tyreBrand",
			title: t("gridFields.tyreBrand"),
		},
		{
			menus: [...TruckMenus],
			field: "variant.suspension",
			title: t("gridFields.suspension"),
		},
		{
			menus: [...TruckMenus],
			field: "variant.color",
			title: t("gridFields.color"),
		},
		{
			menus: [...TruckMenus],
			field: "variant.axle",
			title: t("gridFields.axle"),
		},
		{
			menus: [...TruckMenus],
			field: "variant.wheelbase",
			title: t("gridFields.wheelbase"),
		},
		{
			menus: [...TruckMenus],
			field: "order.foOriginalCustomerOM",
			title: t("gridFields.customer"),
		},
		{
			menus: [...TruckMenus],
			field: "generalComment",
			title: t("gridFields.generalComment"),
			minWidth: 150,
		},
		{
			...getDateTimeCell("invoiceInstructionCreationDate", t("gridFields.if"), [...TruckMenus]),
		},
		{
			...getDateTimeCell("invoiceSaleDate", t("gridFields.invoiceSaleDate"), [...TruckMenus]),
		},
		{
			menus: [...TruckMenus],
			field: "order.salesman",
			title: t("gridFields.salesman"),
		},
		{
			menus: [...MarketCompanyMenus],
			field: "order.exportationStatus",
			title: t("gridFields.stockStatus"),
		},
		{
			menus: [...TruckMenus],
			field: "hasCustomerAdaptation",
			title: t("gridFields.hasCostumerAdaptation"),
			customCell: {
				type: "optionCell",
				value: "hasCustomerAdaptation",
				options: getBooleanOptions(t),
			},
		},
	].filter((f: IColumnProps) => f.menus && f.menus.includes(menu));
}

export function getEuropeGridColumns(
	t: TFunction<("order" | "europe")[], undefined>
): Array<IColumnProps> {
	return [
		{
			field: "factCode",
			title: t("gridFields.factCode", { ns: "europe" }),
			alwaysVisible: true,
			width: 70,
		},
		{
			field: "countryName",
			title: t("gridFields.countryName", { ns: "europe" }),
			alwaysVisible: true,
			width: 100,
		},
		{
			field: "factoryOrder",
			title: t("gridFields.factoryOrder", { ns: "order" }),
			width: 120,
			alwaysVisible: true,
			customCell: {
				type: "linkCell",
				to: "./",
				toParams: "sasId",
				value: "factoryOrder",
			},
		},
		{
			field: "omNumber",
			title: t("gridFields.omNumber", { ns: "europe" }),
			alwaysVisible: true,
			width: 90,
		},
		{
			field: "chassi",
			title: t("gridFields.chassi", { ns: "order" }),
			alwaysVisible: true,
		},
		{
			field: "commercialModel",
			title: t("gridFields.commercialModel", { ns: "order" }),
			alwaysVisible: true,
		},
		{
			field: "asweek",
			title: t("gridFields.asWeek", { ns: "order" }),
			alwaysVisible: true,
		},
		{
			...getDateTimeCell("lcd", t("gridFields.lcd", { ns: "order" }), undefined, true),
		},
		{
			...getDateTimeCell("cdd", t("gridFields.cdd", { ns: "order" }), undefined, true),
		},
		{
			...getDateTimeCell("gokDate", t("gridFields.gokDate", { ns: "europe" })),
		},
		{
			...getDateTimeCell("dps", t("gridFields.dpsDate", { ns: "europe" })),
		},
		{
			field: "destinationPort",
			width: 90,
			title: t("gridFields.destinationPort", { ns: "europe" }),
		},
		{
			...getDateTimeCell("estimatedDeparture", t("gridFields.estimatedDeparture", { ns: "europe" })),
		},
		{
			...getDateTimeCell("estimatedArrival", t("gridFields.estimatedArrival", { ns: "europe" })),
		},
		{
			field: "vin",
			width: 170,
			title: t("gridFields.vin", { ns: "europe" }),
		},
		{
			field: "climateUnit",
			width: 70,
			title: t("variants.climateUnit", { ns: "europe" }),
		},
		{
			field: "engineEmissionControl",
			width: 70,
			title: t("variants.engineEmissionControl", { ns: "europe" }),
		},
		{
			field: "cabVersion",
			width: 80,
			title: t("variants.cabVersion", { ns: "europe" }),
		},
		{
			field: "cabColor",
			width: 80,
			title: t("variants.cabColor", { ns: "europe" }),
		},
		{
			field: "transmission",
			width: 80,
			title: t("variants.transmission", { ns: "europe" }),
		},
		{
			field: "wheelbase",
			width: 80,
			title: t("variants.wheelbase", { ns: "europe" }),
		},
		{
			field: "rearSpringType",
			width: 80,
			title: t("variants.rearSpringType", { ns: "europe" }),
		},
		{
			field: "rearAxle",
			width: 80,
			title: t("variants.rearAxle", { ns: "europe" }),
		},
		{
			field: "rearAxleRatio",
			width: 80,
			title: t("variants.rearAxleRatio", { ns: "europe" }),
		},
		{
			field: "engineSerialNumber",
			width: 80,
			title: t("variants.engineSerialNumber", { ns: "europe" }),
		},
		{
			field: "engineVersion",
			width: 80,
			title: t("variants.engineVersion", { ns: "europe" }),
		},
		{
			field: "tireTreadFront",
			width: 80,
			title: t("variants.tireTreadFront", { ns: "europe" }),
		},
		{
			field: "tireTreadDrive",
			width: 80,
			title: t("variants.tireTreadDrive", { ns: "europe" }),
		},
		{
			field: "tireDimensionFront",
			width: 80,
			title: t("variants.tireDimensionFront", { ns: "europe" }),
		},
		{
			field: "tireDimensionDrive",
			width: 80,
			title: t("variants.tireDimensionDrive", { ns: "europe" }),
		},
		{
			field: "msp",
			width: 80,
			title: t("gridFields.msp", { ns: "europe" }),
		},
		{
			...getDateTimeCell("invoiceDate", t("gridFields.invoiceDate", { ns: "europe" })),
		},
		{
			field: "customerName",
			title: t("gridFields.customer", { ns: "europe" }),
		},
		{
			field: "sasExportationStatus",
			title: t("gridFields.stockStatus"),
			width: 90,
			customCell: {
				type: "optionCell",
				value: "sasExportationStatus",
				options: getExportationStatusOptionsByEnum(t),
			},
		},
	];
}
