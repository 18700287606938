import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import {
	groupsAllowedToSeeInvoiceExportationSection,
	groupsAllowedToSeeInvoiceInstructionExportationSection,
	groupsAllowedToSeeInvoicingDeliveryMarketCompanySection,
	groupsAllowedToSeeInvoicingDeliverySection,
} from "../../../../../constants/AuthGroups/orderDetails";
import { FactoryDeliveryMenus } from "../../../../../constants/Menus/OrderMenuUtils";
import { AvailabilityInvoicingDeliveryAccordionProps } from "../../interfaces/Accordions/AvailabilityInvoicingDeliveryAccordion";
import InvoiceExportationSection from "../../../../../components/OrderPages/Details/containers/Sections/InvoiceExportationSection";
import InvoiceInstructionExportationSection from "../Sections/InvoiceInstructionExportationSection";
import InvoicingDeliverySection from "../Sections/InvoicingDeliverySection";
import InvoicingDeliveryMarketCompanySection from "../../../../../components/OrderPages/Details/containers/Sections/InvoicingDeliveryMarketCompanySection";

const AvailabilityInvoicingDeliveryAccordion: React.FC<
	AvailabilityInvoicingDeliveryAccordionProps
> = (details) => {
	const { t } = useTranslation("order");
	const { isAuthorizedGroup } = useAuthStore();

	const userCanSeeInvoiceInstructionExportationSection = isAuthorizedGroup(
		groupsAllowedToSeeInvoiceInstructionExportationSection
	);

	const userCanSeeInvoicingDeliverySection = isAuthorizedGroup(
		groupsAllowedToSeeInvoicingDeliverySection
	);

	const userCanSeeInvoiceExportationSection = isAuthorizedGroup(
		groupsAllowedToSeeInvoiceExportationSection
	);

	const userCanSeeInvoicingDeliveryMarketCompanySection = isAuthorizedGroup(
		groupsAllowedToSeeInvoicingDeliveryMarketCompanySection
	);

	const menuAllowedToShowInvincingDeliveryMarketCompany =
		FactoryDeliveryMenus.some((x) => x === details.menuKey);

	return (
		<AccordionCard
			id="invoicingAndDeliveryAccordion"
			title={t("details.accordions.invoicingDelivery")}
		>
			{userCanSeeInvoiceInstructionExportationSection && (
				<InvoiceInstructionExportationSection
					{...details.invoiceInstructionExportationDetails}
				/>
			)}
			{(details.userIsOwner || userCanSeeInvoicingDeliverySection) && (
				<InvoicingDeliverySection {...details.invoicingDeliveryDetails} />
			)}
			{(details.userIsOwner || userCanSeeInvoiceExportationSection) && (
				<InvoiceExportationSection {...details.invoiceExportationDetails} />
			)}
			{(details.userIsOwner ||
				userCanSeeInvoicingDeliveryMarketCompanySection) &&
				menuAllowedToShowInvincingDeliveryMarketCompany && (
					<InvoicingDeliveryMarketCompanySection
						{...details.invoicingDeliveryMarketCompanyDetails}
					/>
				)}
		</AccordionCard>
	);
};

export default observer(AvailabilityInvoicingDeliveryAccordion);
