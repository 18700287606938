import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../../components/AccordionCard/AccordionCard";
import { observer } from "mobx-react";
import FactoryOptionalSection from "../Sections/FactoryOptionalSection";
import LocalCustomizationsSection from "../Sections/LocalCustomizationsSection";
import { VehicleCustomizationAccordionProps } from "../../interfaces/Accordions/VehicleCustomizationAccordion";
import { EXTERNAL, INTERNAL } from "../../model/LocalCustomizationTypes";
import _ from "lodash";
import { Grid } from "@mui/material";

const VehicleCustomizationAccordion: React.FC<
	VehicleCustomizationAccordionProps
> = (details) => {
	const { t } = useTranslation("quotation");

	const internalArray = _.sortBy(details.customizationsDetails.editableFields, [
		"type",
		"value",
	]).filter((x) => x.type === INTERNAL);

	const externalArray = _.sortBy(details.customizationsDetails.editableFields, [
		"type",
		"value",
	]).filter((x) => x.type === EXTERNAL);

	return (
		<AccordionCard
			id="generalAccordion"
			title={t("details.accordions.vehicleCustomization")}
		>
			<FactoryOptionalSection {...details.factoryOptionalDetails} />
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.localCustomizations.title")}{" "}
				{t("details.sections.localCustomizations.internal")}
			</Grid>
			<LocalCustomizationsSection
				{...details.customizationsDetails}
				editableFields={internalArray}
				currency={details.customizationsDetails.currency}
				region={details.region}
			/>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.localCustomizations.title")}{" "}
				{t("details.sections.localCustomizations.external")}
			</Grid>
			<LocalCustomizationsSection
				{...details.customizationsDetails}
				editableFields={externalArray}
				currency={details.customizationsDetails.currency}
				region={details.region}
			/>
		</AccordionCard>
	);
};

export default observer(VehicleCustomizationAccordion);
