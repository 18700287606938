import { getStringOrDefault } from "../../../../utils/ModelBuilderUItils";
import { InvoiceExportationProps } from "../interfaces/InvoiceExportationDetails";

export const invoiceExportationModel: InvoiceExportationProps = {
	invoiceNumber: "",
	statusCode: "",
	boardingDate: null,
	importer: "",
	businessPartner: "",
};

export function invoiceExportationModelBuilder(
	data: InvoiceExportationProps
): InvoiceExportationProps {
	if (!data) {
		return invoiceExportationModel;
	}
	return {
		...invoiceExportationModel,
		invoiceNumber: getStringOrDefault(data.invoiceNumber),
		statusCode: getStringOrDefault(data.statusCode),
		importer: getStringOrDefault(data.importer),
		businessPartner: getStringOrDefault(data.businessPartner),
	};
}
