export const TIMEOUT_CSV_BUTTON = 300000;
export const CSV_DISABLED_TRUCKS_CLOSED_PERIOD_ARG =
	"isButtonCsvDisabledTrucksClosedPeriodArg";
export const CSV_DISABLED_BUSES_CLOSED_PERIOD_ARG =
	"isButtonCsvDisabledBusesClosedPeriodArg";
export const CSV_DISABLED_TRUCKS_FOLLOW_UP_ARG =
	"isButtonCsvDisabledTrucksFollowUpArg";
export const CSV_DISABLED_BUSES_FOLLOW_UP_ARG =
	"isButtonCsvDisabledBusesFollowUpArg";
export const CSV_DISABLED_FOLLOW_UP_CHL = "isButtonCsvDisabledFollowUpChl";
export const CSV_DISABLED_QUOTATIONS_CHL = "isButtonCsvDisabledQuotationsChl";
export const CSV_DISABLED_QUOTATIONS_ARG = "isButtonCsvDisabledQuotationsArg";
export const CSV_DISABLED_TRUCKS_ORDERS = "isButtonCsvDisabledTrucksOrder";
export const CSV_DISABLED_BUSES_ORDERS = "isButtonCsvDisabledBusesOrder";
export const CSV_DISABLED_EUROPE_ORDERS = "isButtonCsvDisabledEuropeOrder";
