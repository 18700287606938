import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Alert, Button, DialogActions, DialogContent } from "@mui/material";
import { CheckCircle, Launch } from "@mui/icons-material";
import DialogForm from "../../../../../components/Dialog/DialogForm";
import CancelButton from "../../../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../../../components/Dialog/Buttons/ApplyButton";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { QuotationDetailBaseStore } from "../store/QuotationDetailBaseStore";

export type QuotationGoToSharePointButtonProps = {
  store: () => QuotationDetailBaseStore;
};

const QuotationGoToSharePointButton: React.FC<QuotationGoToSharePointButtonProps> = ({ store }) => {
  const link =
    "https://volvogroup.sharepoint.com/:f:/r/sites/unit-soporte-ventas-camiones-nuevos/Shared Documents/FACTURACION/DOCUMENTACION PARA FACTURAR";

  const { t } = useTranslation(["quotation", "general"]);

  const [dialogVisible, setDialogVisible] = React.useState(false);

  const { readOnlyDetails } = store();

  const goToSharePoint = () => {
    const folder = `${link}/${readOnlyDetails.quotationId}`;
    window.open(folder, "_blank", "noreferrer");
  };

  return (
    <>
      <Button
        variant="contained"
        color="inherit"
        onClick={() => setDialogVisible(true)}
        startIcon={<FolderSharedIcon />}
      >
        {t("sharepoint", { ns: "general" })}
      </Button>
      <DialogForm
        id="quotation-share-point-close"
        open={dialogVisible}
        title={t("goTo", {
          name: t("sharepoint", { ns: "general" }),
          ns: "general",
        })}
        onClose={() => setDialogVisible(false)}
      >
        <DialogContent dividers>
          {t("sharepointRedirect", { ns: "general" })}
        </DialogContent>
        <DialogContent>
          <Alert severity="warning">
            {t("ifProblemSharepoint", {
              quotationId: readOnlyDetails.quotationId,
              ns: "general",
            })}
            <a href={link} target="_blank" rel="noreferrer">
              {t("clickHere", { ns: "general" })}
              <Launch
                fontSize="small"
                sx={{ fontSize: "inherit", marginLeft: "1px" }}
              />
            </a>
          </Alert>
        </DialogContent>
        <DialogActions>
          <CancelButton
            id="quotation-share-point-cancel"
            onCancel={() => setDialogVisible(false)}
            title={t("cancel", { ns: "general" })}
          />
          <ApplyButton
            id="quotation-share-point-confirm"
            onSubmit={goToSharePoint}
            title={t("confirm", { ns: "general" })}
            startIcon={<CheckCircle />}
            color="success"
          />
        </DialogActions>
      </DialogForm>
    </>
  );
};

export default observer(QuotationGoToSharePointButton);
