import { TFunction } from "i18next";
import {
	CTADMVEN_ORDER_EXTERNAL_BRAZIL,
	CTADMVEN_ORDER_MKT_COMPANY,
	CTADMVEN_ORDER_AVAILABILITY,
	CTADMVEN_ORDER_INTERNAL_VDB,
	CTADMVEN_ORDER_TOTAL,
	CTADMVEN_ORDER_BUS_TOTAL,
	CTADMVEN_ORDER_BUS_BRAZIL,
	CTADMVEN_ORDER_BUS_PI,
	CTADMVEN_ORDER_BUS_MKT_COMPANY_TOTAL,
	CTADMVEN_ORDER_BUS_MEAC,
	CTADMVEN_ORDER_BUS_FREE_UNIT_VCI,
	CTADMVEN_ORDER_FD_MKT_COMPANY,
	CTADMVEN_ORDER_EUROPE_FACTORY_ORDERBOOK,
	CTADMVEN_ORDER_FD_MKT_COMPANY_BUS,
	CTADMVEN_ORDER_PRIVATE_IMPORTERS,
	CTADMVEN_ORDER_VCI,
	CTADMVEN_ORDER_FD_MKT_COMPANY_EUROPE,
	CTADMVEN_ORDER_BUS_FREE_UNIT_VCI_VDB,
} from "../routes";

//constants
export const TOTAL = "total";
export const INTERNAL_VDB = "internal";
export const EXTERNAL_BRAZIL = "external";
export const AVAILABILITY = "availability";
export const MKT_COMPANY = "mktCompany";
export const PRIVATE_IMPORTERS = "pi";
export const VCI = "vci";
export const EUROPE_FACTORY_ORDERBOOK = "europeOrderBook";
export const BUS_TOTAL = "busTotal";
export const BUS_BRAZIL = "busBrazil";
export const BUS_FREE_UNIT_VCI = "busFreeUnitVCI";
export const BUS_FREE_UNIT_VCI_VDB = "busFreeUnitVCIVDB";
export const BUS_MEAC = "busMEAC";
export const BUS_MKT_COMPANY = "busMktCompany";
export const BUS_PI = "busPI";
export const FD_MKT_COMPANY = "fdMktCompany";
export const FD_MKT_COMPANY_BUS = "fdMktCompanyBus";
export const FD_MKT_COMPANY_EUROPE = "fdMktCompanyEurope";

const orderBookBus: OrderBookBusMenuType[] = [
	BUS_TOTAL,
	BUS_BRAZIL,
	BUS_PI,
	BUS_MKT_COMPANY,
	BUS_MEAC,
	BUS_FREE_UNIT_VCI,
	BUS_FREE_UNIT_VCI_VDB,
];
const fdMktCompanyTruck: OrderBookTruckMenuType[] = [
	FD_MKT_COMPANY,
	FD_MKT_COMPANY_EUROPE,
];
export const BusMenus: OrderBookBusMenuType[] = [
	...orderBookBus,
	FD_MKT_COMPANY_BUS,
];
export const TruckBrMenus: OrderBookMenuType[] = [
	TOTAL,
	INTERNAL_VDB,
	EXTERNAL_BRAZIL,
	MKT_COMPANY,
	AVAILABILITY,
	FD_MKT_COMPANY,
	PRIVATE_IMPORTERS,
	VCI,
];
export const EuropeMenus: OrderBookMenuType[] = [
	EUROPE_FACTORY_ORDERBOOK,
	FD_MKT_COMPANY_EUROPE,
];
export const TruckMenus: OrderBookMenuType[] = [
	...TruckBrMenus,
	...EuropeMenus,
];
export const MarketCompanyMenus: OrderBookMenuType[] = [
	MKT_COMPANY,
	BUS_MKT_COMPANY,
	FD_MKT_COMPANY_BUS,
	...fdMktCompanyTruck,
];
export const FactoryDeliveryMenus: OrderBookMenuType[] = [
	FD_MKT_COMPANY_BUS,
	...fdMktCompanyTruck,
];
export const TruckMarketCompanyMenus: OrderBookMenuType[] = [
	MKT_COMPANY,
	FD_MKT_COMPANY,
	PRIVATE_IMPORTERS,
];

//types
export type OrderBookTruckMenuType =
	| typeof TOTAL
	| typeof INTERNAL_VDB
	| typeof EXTERNAL_BRAZIL
	| typeof MKT_COMPANY
	| typeof FD_MKT_COMPANY
	| typeof AVAILABILITY
	| typeof EUROPE_FACTORY_ORDERBOOK
	| typeof PRIVATE_IMPORTERS
	| typeof VCI
	| typeof FD_MKT_COMPANY_EUROPE;

export type OrderBookBusMenuType =
	| typeof BUS_TOTAL
	| typeof BUS_BRAZIL
	| typeof BUS_PI
	| typeof BUS_MKT_COMPANY
	| typeof BUS_MEAC
	| typeof BUS_FREE_UNIT_VCI
	| typeof BUS_FREE_UNIT_VCI_VDB
	| typeof FD_MKT_COMPANY_BUS;

export type OrderBookMenuType = OrderBookTruckMenuType | OrderBookBusMenuType;

const routeAndMenuMapping: { [key: string]: OrderBookMenuType } = {
	[CTADMVEN_ORDER_EXTERNAL_BRAZIL]: EXTERNAL_BRAZIL,
	[CTADMVEN_ORDER_TOTAL]: TOTAL,
	[CTADMVEN_ORDER_INTERNAL_VDB]: INTERNAL_VDB,
	[CTADMVEN_ORDER_MKT_COMPANY]: MKT_COMPANY,
	[CTADMVEN_ORDER_AVAILABILITY]: AVAILABILITY,
	[CTADMVEN_ORDER_FD_MKT_COMPANY]: FD_MKT_COMPANY,
	[CTADMVEN_ORDER_EUROPE_FACTORY_ORDERBOOK]: EUROPE_FACTORY_ORDERBOOK,
	[CTADMVEN_ORDER_PRIVATE_IMPORTERS]: PRIVATE_IMPORTERS,
	[CTADMVEN_ORDER_VCI]: VCI,
	[CTADMVEN_ORDER_FD_MKT_COMPANY_EUROPE]: FD_MKT_COMPANY_EUROPE,
	[CTADMVEN_ORDER_BUS_TOTAL]: BUS_TOTAL,
	[CTADMVEN_ORDER_BUS_BRAZIL]: BUS_BRAZIL,
	[CTADMVEN_ORDER_BUS_PI]: BUS_PI,
	[CTADMVEN_ORDER_BUS_MKT_COMPANY_TOTAL]: BUS_MKT_COMPANY,
	[CTADMVEN_ORDER_BUS_MEAC]: BUS_MEAC,
	[CTADMVEN_ORDER_BUS_FREE_UNIT_VCI_VDB]: BUS_FREE_UNIT_VCI_VDB,
	[CTADMVEN_ORDER_BUS_FREE_UNIT_VCI]: BUS_FREE_UNIT_VCI,
	[CTADMVEN_ORDER_FD_MKT_COMPANY_BUS]: FD_MKT_COMPANY_BUS,
};
//utils
function getRouteAndMenu() : {route: string, menu: (OrderBookMenuType | "")} {
	for (const [key, value] of Object.entries(routeAndMenuMapping)) {
		if (window.location.hash.includes(key)) {
			return {route: key, menu: value};
		}
	}
	return {route: "", menu: ""};
}
function getMenuTitleByMenuKey(menuKey: OrderBookMenuType | "") {
	if (orderBookBus.includes(menuKey as OrderBookBusMenuType)) {
		return "orderBookBus.orderBook";
	}
	if (fdMktCompanyTruck.includes(menuKey as OrderBookTruckMenuType)) {
		return "orderBookFactoryDeliveries.orderBook";
	}
	if (FD_MKT_COMPANY_BUS === menuKey) {
		return "orderBookFactoryDeliveriesBus.orderBook";
	}
	if (menuKey === EUROPE_FACTORY_ORDERBOOK) {
		return "europeFactory.title";
	}

	return "orderBookTrucks.orderBook";
}

export function getMenuKeyAndBreadcrumbs(t: TFunction<"menu"[], undefined>) {
	const menuRouteAndMenu = getRouteAndMenu();
  const menuRoute = menuRouteAndMenu.route;
	const menuKey = menuRouteAndMenu.menu;

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t(getMenuTitleByMenuKey(menuKey), { ns: "menu" })}`,
			href: `#/${menuRoute}`,
			main: false,
		},
		{
			title: t("details", { ns: "menu" }),
			main: true,
		},
	];
	return {
		menuKey,
		breadcrumbs,
	};
}
