import CtadmvenApi from "../../apis/CtadmvenApi";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { TruckEditableProps } from "../../pages/FollowUpChl/Details/interfaces/Editable/TruckEditableDetails";
import { CsvOptionsProps } from "../../pages/Order/List/interfaces/CsvOptionsProps";
import { BatchDataProps } from "../../pages/FollowUpChl/List/interfaces/BatchEdition/BatchDataProps";
import { EuropeEditDetailsProps } from "../../components/OrderPages/Europe/DetailsBase/interfaces/EuropeEditDetailsProps";

const base = "/followUpChl";

export class FollowUpChlService {
	constructor(private api: CtadmvenApi) {}

	listPaginated = async (dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/list?${toDataSourceRequestString(dataState)}`
		);

	getDetails = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/details/${id}`);

	getEuropeDetails = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/europeDetails/${id}`);

	getDetailsVariants = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/variants/${id}`);

	getEuropeDetailsVariants = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/europeVariants/${id}`);

	getDetailsVariantsRespecList = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/respecVariant/${id}`);

	getInvoiceExportationByOrderId = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/orderInvoiceExp/${id}`);

	getInvoicingDeliveryMarketCompanyByOrderId = async (
		id: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/orderInvoiceMarketcompany/${id}`);

	getLocalization = async (
		europeOrderSASId: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/europeLocalization/${europeOrderSASId}`
		);

	getInvoicingDeliveryMarketCompanyByEuropeOrderSASId = async (
		europeOrderSASId: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/europeInvoiceMarketcompany/${europeOrderSASId}`
		);

	update = async (orderDetails: TruckEditableProps): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/`, JSON.stringify(orderDetails));

	updateEurope = async (europeDetails: EuropeEditDetailsProps): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/europe`,
			JSON.stringify(europeDetails)
		);

	getReservationExportDetails = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/reservationExport/${id}`);

	csvExport = async (
		dataState: State,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csv?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	updateBatch = async (
		dataState: State,
		orderDetails: BatchDataProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batch?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(orderDetails)
		);
}
