import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { ProductionDatesSectionProps } from "../../interfaces/Sections/ProductionDatesSection";
import PopoverOverlay from "../../../../../PopoverOverlay/PopoverOverlay";
import DateForm from "../../../../../FormInputs/DateForm";

const ProductionDatesSection: React.FC<ProductionDatesSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.productionDates.title")}
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.lcd")}
					body={t("tooltips.lcd")}
				>
					<DateForm
						label={t("details.sections.productionDates.lcd")}
						value={readOnlyFields.lcd}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.cdd")}
					body={t("tooltips.cdd")}
				>
					<DateForm
						label={t("details.sections.productionDates.cdd")}
						value={readOnlyFields.cdd}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.asWeekDate")}
					body={t("tooltips.asWeekDate")}
				>
					<DateForm
						label={t("details.sections.productionDates.asWeekDate")}
						value={readOnlyFields.asweekDate}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.greenOk")}
					body={t("tooltips.greenOk")}
				>
					<DateForm
						label={t("details.sections.productionDates.greenOk")}
						value={readOnlyFields.greenOk}
					/>
				</PopoverOverlay>
			</Grid>
			<Grid item xs={4}>
				<PopoverOverlay
					header={t("details.sections.productionDates.dps")}
					body={t("tooltips.dps")}
				>
					<DateForm
						label={t("details.sections.productionDates.dps")}
						value={readOnlyFields.dps}
					/>
				</PopoverOverlay>
			</Grid>
		</>
	);
};

export default observer(ProductionDatesSection);
