import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import {
	getFilterObject,
	getMultiValueFilterSplitter,
} from "../../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterQuotationIDStore {
	@observable rootStore;
	@observable preFilter = "";

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}
	@action reset = () => {
		this.preFilter = "";
	};

	@action clean = () => (this.preFilter = "");

	@action get = () => {
		if (this.preFilter === "") {
			return [];
		}

		return [getFilterObject("quotationId", this.preFilter, "eq")];
	};

	@action set = (value: string) => (this.preFilter = value);

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		const quotationIdFilters = this.get();
		if (quotationIdFilters) {
			andFilters = andFilters.concat(quotationIdFilters);
		}

		return andFilters;
	}

	@action getMultiValueFilterDescriptor(
		orFiltersGroups: Array<Array<FilterDescriptor>>,
		splitBy: string
	) {
		if (this.preFilter === "") {
			return;
		}

		const splittedFilter = this.preFilter.split(splitBy);

		if (splittedFilter.every((x) => x.length === 0)) {
			return;
		}

		const filters = splittedFilter.map((x: string) =>
			getFilterObject("quotationId", x, "eq")
		);
		if (filters) {
			orFiltersGroups.push(filters);
		}
	}

	@action getFilterDescriptorBySize = (
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>
	) => {
		const splitter = getMultiValueFilterSplitter(this.preFilter);
		if (!splitter) {
			andFilters = this.getFilterDescriptor(andFilters);
		}
		else {
			this.getMultiValueFilterDescriptor(orFiltersGroups, splitter);
		}

		return andFilters;
	};
}

export default PreFilterQuotationIDStore;
