import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { ExportationLocationSectionProps } from "../../interfaces/Sections/ExportationLocationSection";
import DateForm from "../../../../FormInputs/DateForm";

const ExportationLocationSection: React.FC<ExportationLocationSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation(["order", "general"]);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.partnerReceivingInformation.title")}
			</Grid>
			<Grid item xs={6}>
				<DateForm
					label={t(
						"details.sections.partnerReceivingInformation.estimatedDeparture"
					)}
					value={readOnlyFields.estimatedDeparture}
				/>
			</Grid>
			<Grid item xs={6}>
				<DateForm
					label={t(
						"details.sections.partnerReceivingInformation.realDeparture"
					)}
					value={readOnlyFields.realDeparture}
				/>
			</Grid>
			<Grid item xs={6}>
				<DateForm
					label={t(
						"details.sections.partnerReceivingInformation.estimatedArrival"
					)}
					value={readOnlyFields.estimatedArrival}
				/>
			</Grid>
			<Grid item xs={6}>
				<DateForm
					label={t("details.sections.partnerReceivingInformation.realArrival")}
					value={readOnlyFields.realArrival}
				/>
			</Grid>
		</>
	);
};

export default observer(ExportationLocationSection);
