import { action, configure, makeObservable, observable } from "mobx";
import _ from "lodash";
import { FollowUpArgEditableProps } from "../interfaces/FollowUpArgEditable";
import {
	followUpArgAccountingEditableModelBuilder,
	followUpArgArgMarketCompanyEditableModelBuilder,
	followUpArgBusinessEditableModelBuilder,
	followUpArgEditableModel,
	followUpArgEditableModelBuilder,
	followUpArgFinancialEditableModelBuilder,
	followUpArgBaseEditableModelBuilder,
	followUpArgLogisticEditableModelBuilder,
	followUpArgEngineeringEditableModelBuilder,
} from "../models/FollowUpArgEditableModel";
import React from "react";
import { StoreUtils } from "../../../../utils/StoreUtils";
import {
	getArrayOfObjAsOptions,
	getArrayOfStringAsOptions,
	getPaqueteOptions,
} from "../../../../utils/SelectOptions";
import { ArgDetailsBaseStore } from "../../../../components/OrderPages/Arg/DetailsBase/stores/ArgDetailsBaseStore";

configure({ enforceActions: "always" });

/* Store start */
class FollowUpArgStore extends ArgDetailsBaseStore {
	@observable initialEditableDetails: FollowUpArgEditableProps =
		followUpArgEditableModel;
	@observable userUpdatePermission: string = "";

	constructor() {
		super();
		makeObservable(this);
	}

	resetStore = () => {
		this.initialEditableDetails = followUpArgEditableModel;
		this.resetBaseStore();
	};

	getEditableFields = () => followUpArgEditableModelBuilder(this.fields);

	//=========================================set observables=========================================
	setEditableDetails = () =>
		(this.initialEditableDetails = this.getEditableFields());

	setDealersOptions = (data: any) =>
		(this.dealersOptions = getArrayOfObjAsOptions(data));

	setVehicleLocationOptions = (data: any) =>
		(this.vehicleLocationOptions = getArrayOfObjAsOptions(data));

	setShippingCompaniesOptions = (data: any) =>
		(this.shippingCompaniesOptions = getArrayOfStringAsOptions(data));

	setPaqueteOptions = (data: any) =>
		(this.paqueteOptions = getPaqueteOptions(data));

	setHasChanges = () => {
		this.hasChanges = !_.isEqual(
			this.initialEditableDetails,
			this.getEditableFields()
		);
	};

	@action setUserUpdatePermission = (userUpdatePermission: string) =>
		(this.userUpdatePermission = userUpdatePermission);
	//END: observables

	//=========================================load details=========================================
	loadDetails = async (orderId: string) => {
		await StoreUtils.LoadData(
			() => this.api.followUpArgService.getDetails(orderId),
			this.setTruckFieldsDetails,
			orderId
		);
	};

	loadDetailsBus = async (orderId: string) => {
		await StoreUtils.LoadData(
			() => this.api.followUpArgService.getDetailsBus(orderId),
			this.setBusFieldsDetailsBus,
			orderId
		);
	};

	loadVariants = async () => {
		const orderId = this.fields.id;
		await StoreUtils.LoadData(
			() => this.api.followUpArgService.getDetailsVariants(orderId),
			this.setVariants,
			orderId
		);
	};

	loadVariantsBus = async () => {
		const orderId = this.fields.id;
		await StoreUtils.LoadData(
			() => this.api.followUpArgService.getDetailsVariantsBus(orderId),
			this.setVariants,
			orderId
		);
	};

	loadOrderMktCompanyDelivery = async () => {
		const orderId = this.fields.id;
		await StoreUtils.LoadData(
			() => this.api.followUpArgService.getDetailsMCDelivery(orderId),
			this.setOrderMCDelivery,
			orderId
		);
	};

	loadDealers = async () => {
		await StoreUtils.LoadData(
			() => this.api.dealerService.getDictionaryIdNameByCountry("ARG"),
			this.setDealersOptions,
			"ARG"
		);
	};

	loadVehicleLocations = async () => {
		await StoreUtils.LoadData(
			() => this.api.vehicleLocationService.getDictionaryIdNameByCountry("ARG"),
			this.setVehicleLocationOptions,
			"ARG"
		);
	};

	loadShippingCompanies = async () => {
		await this.api.followUpArgService
			.getShippingCompanies()
			.then((response) => {
				this.setShippingCompaniesOptions(response.data);
			});
	};

	loadPaymentTerms = async (language: string) => {
		await this.api.paymentTermsService
			.getPaymentTerms("ARG")
			.then((rs: any) => {
				if (rs.data) this.setPaymentTermsOptions(rs.data, language);
			});
	};

	loadPaquetes = async () => {
		await this.api.paqueteService.getPaqueteList().then((rs: any) => {
			if (rs.data) this.setPaqueteOptions(rs.data);
		});
	};

	loadOrderMktCompanyManagement = async () => {
		const orderId = this.fields.id;
		await StoreUtils.LoadData(
			() => this.api.followUpArgService.getDetailsMCManagement(orderId),
			this.setOrderMCManagement,
			orderId
		);
	};
	//END: load details

	update = async () => {
		switch (this.userUpdatePermission) {
			case "UpdateFollowUpArg":
				return await this.api.followUpArgService.update(
					followUpArgArgMarketCompanyEditableModelBuilder(this.fields)
				);
			case "UpdateFollowUpArgFinancial":
				return await this.api.followUpArgService.updateAsFinancial(
					followUpArgFinancialEditableModelBuilder(this.fields)
				);
			case "UpdateFollowUpArgLogistic":
				return await this.api.followUpArgService.updateAsLogistic(
					followUpArgLogisticEditableModelBuilder(this.fields)
				);
			case "UpdateFollowUpArgAccounting":
				return await this.api.followUpArgService.updateAsAccounting(
					followUpArgAccountingEditableModelBuilder(this.fields)
				);
			case "UpdateFollowUpArgBusiness":
				return await this.api.followUpArgService.updateAsBusiness(
					followUpArgBusinessEditableModelBuilder(this.fields)
				);
			case "UpdateFollowUpArgEngineering":
				return await this.api.followUpArgService.updateAsEngineering(
					followUpArgEngineeringEditableModelBuilder(this.fields)
				);
			default:
				return await this.api.followUpArgService.updateGeneralComment(
					followUpArgBaseEditableModelBuilder(this.fields)
				);
		}
	};
}
/* Store end */
const FollowUpArgStoreContext = React.createContext(new FollowUpArgStore());

export const useFollowUpArgStore = () =>
	React.useContext(FollowUpArgStoreContext);
