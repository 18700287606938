import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import { FinalDetailSectionProps } from "../../interfaces/Sections/FinalDetailSection";

const FinalDetailSection: React.FC<FinalDetailSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation("quotation");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.finalDetail.title")}
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.finalDetail.freeRemarks")}
					value={readOnlyFields.freeRemarks}
				/>
			</Grid>
		</>
	);
};

export default observer(FinalDetailSection);
