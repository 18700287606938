import * as AuthGroups from "./authGroups";

const allQuotationGroups: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.ARG_MARKET_COMPANY
];

export const groupsQuotationManager: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.ARG_MARKET_COMPANY
];

export const groupsQuotationSalesman: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN
];

const groupsMarketCompany: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.ARG_MARKET_COMPANY,
];

export const groupsAllowedToSeeArgentinaQuotationMenu: Array<AuthGroups.AuthGroupsType> =
	allQuotationGroups;

export const groupsAllowedToEditGlosaText: Array<AuthGroups.AuthGroupsType> =
	allQuotationGroups;

export const groupsAllowedToEditGlosaFields: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ADMIN, AuthGroups.ARG_MARKET_COMPANY];

export const groupsAllowedToEditAdelanto: Array<AuthGroups.AuthGroupsType> = [
	...groupsQuotationManager,
];

export const groupsToExportQuotationCsv: Array<AuthGroups.AuthGroupsType> =
	allQuotationGroups;

export const groupsAllowedToSendToWorkflowApproval: Array<AuthGroups.AuthGroupsType> =
	allQuotationGroups;

export const groupsAllowedToEditQuotationReservation: Array<AuthGroups.AuthGroupsType> =
	groupsMarketCompany;
