import * as AuthGroups from "./authGroups";

const allQuotationGroups: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.CHL_MARKET_COMPANY,
	AuthGroups.CHL_MARKET_COMPANY_SALESMAN_MANAGER,
	AuthGroups.CHL_MARKET_COMPANY_SALESMAN,
];

export const groupsQuotationManager: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.CHL_MARKET_COMPANY,
	AuthGroups.CHL_MARKET_COMPANY_SALESMAN_MANAGER,
];

export const groupsQuotationSalesman: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.CHL_MARKET_COMPANY_SALESMAN,
];

const groupsMarketCompany: Array<AuthGroups.AuthGroupsType> = [
	AuthGroups.ADMIN,
	AuthGroups.CHL_MARKET_COMPANY,
];

export const groupsAllowedToSeeQuotationChileMenu: Array<AuthGroups.AuthGroupsType> =
	allQuotationGroups;

export const groupsAllowedToEditGlosaText: Array<AuthGroups.AuthGroupsType> =
	allQuotationGroups;

export const groupsAllowedToEditGlosaFields: Array<AuthGroups.AuthGroupsType> =
	[AuthGroups.ADMIN, AuthGroups.CHL_MARKET_COMPANY];

export const groupsAllowedToEditAdelanto: Array<AuthGroups.AuthGroupsType> = [
	...groupsQuotationManager,
];

export const groupsToExportQuotationCsv: Array<AuthGroups.AuthGroupsType> =
	allQuotationGroups;

export const groupsAllowedToSendToWorkflowApproval: Array<AuthGroups.AuthGroupsType> =
	allQuotationGroups;

export const groupsAllowedToEditQuotationReservation: Array<AuthGroups.AuthGroupsType> =
	groupsMarketCompany;
