import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../../components/AccordionCard/AccordionCard";
import { observer } from "mobx-react";
import { QuotationCommentsAccordionProps } from "../../interfaces/Accordions/QuotationCommentsAccordion";
import FinalDetailSection from "../Sections/FinalDetailSection";

const QuotationCommentsAccordion: React.FC<QuotationCommentsAccordionProps> = (
	details
) => {
	const { t } = useTranslation("quotation");
	return (
		<AccordionCard
			id="generalAccordion"
			title={t("details.accordions.quotationComments")}
		>
			<FinalDetailSection {...details.finalDetailDetails} />
		</AccordionCard>
	);
};

export default observer(QuotationCommentsAccordion);
