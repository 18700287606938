import { observer } from "mobx-react";
import { groupsAllowedToSeeArgentinaQuotationMenu, groupsQuotationManager, groupsToExportQuotationCsv } from "../../../../../constants/AuthGroups/quotationArgentinaPage";
import { CountryRegionIso } from "../../../../../enums/CountryRegionIso";
import QuotationIndex from "../../../Components/List/containers/QuotationIndex";


const MainList: React.FC = () => {
  return (
    <QuotationIndex region={CountryRegionIso.AR} groupsAllowedToSeeQuotationMenu={groupsAllowedToSeeArgentinaQuotationMenu}
      groupsQuotationManager={groupsQuotationManager} groupsToExportQuotationCsv={groupsToExportQuotationCsv} />
  );
}

export default observer(MainList);