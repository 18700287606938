import { action, configure, makeObservable } from "mobx";
import BasePreFilterStringArrayStore from "../../../../../PreFilter/Stores/BasePreFilterStringArrayStore";
import CtadmvenApi from "../../../../../../apis/CtadmvenApi";

configure({ enforceActions: "always" });

class PreFilterDealerStore extends BasePreFilterStringArrayStore {
	constructor(
		rootStore: any,
		private readonly api: CtadmvenApi
	) {
		super(rootStore, "orderMCManagementDetail.dealerId");
		makeObservable(this);
	}

	@action setDealerOptions = (data: any) => {
		this.preFilterOptions = [];

		if (!data) {
			return;
		}

		const arrayOfKeyValues = Object.entries(data);
		this.preFilterOptions = arrayOfKeyValues.map((m) => ({
			value: m[0],
			label: m[1] as string,
		}));
	};

	@action getOptions = async () =>
		this.baseGetOptions(
			this.setDealerOptions,
			this.api.dealerService.getDictionaryIdNameByCountry
		);
}

export default PreFilterDealerStore;
