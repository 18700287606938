import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../../components/AccordionCard/AccordionCard";
import { observer } from "mobx-react";
import { VehicleAccordionProps } from "../../interfaces/Accordions/VehicleAccordion";
import ModelSection from "../Sections/ModelSection";
import VariantsSection from "../Sections/VariantsSection";

const VehicleAccordion: React.FC<VehicleAccordionProps> = (details) => {
	const { t } = useTranslation("quotation");
	return (
		<AccordionCard
			id="generalAccordion"
			title={t("details.accordions.vehicle")}
		>
			<ModelSection {...details.modelDetails} />
			<VariantsSection {...details.variantsDetails} />
		</AccordionCard>
	);
};

export default observer(VehicleAccordion);
