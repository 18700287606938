export function getDateTimeCell(field: string, title: string, menus: Array<string> | undefined = undefined, alwaysVisible: boolean | undefined = undefined) {
	return {
		field,
		title,
		width: 90,
		customCell: {
			type: "dateTimeCell",
			date: field,
			format: "DD/MM/yy",
		},
		menus: menus ?? undefined,
		alwaysVisible: alwaysVisible ?? undefined
	};
}
