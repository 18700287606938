import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../AccordionCard/AccordionCard";
import GeneralSection from "../Sections/GeneralSection";
import { observer } from "mobx-react";
import LastUpdateChip from "../../../../../LastUpdateChip/LastUpdateChip";
import { GeneralAccordionProps } from "../../interfaces/Accordions/GeneralAccordion";
import GeneralSectionBus from "../Sections/Bus/GeneralSectionBus";
import { GeneralSectionProps } from "../../interfaces/Sections/GeneralSection";

const GeneralAccordion: React.FC<GeneralAccordionProps> = (props) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="generalAccordion"
			title={t("details.accordions.general")}
			summaryBody={<LastUpdateChip {...props.lastUpdateDetails} />}
			defaultExpanded
		>
			{props.vehicleType === "Bus" ? (
				<GeneralSectionBus {...props.generalDetails} />
			) : (
				<GeneralSection {...(props.generalDetails as GeneralSectionProps)} />
			)}
		</AccordionCard>
	);
};

export default observer(GeneralAccordion);
