import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Button, DialogActions, DialogContent, Grid } from "@mui/material";
import { toast } from "react-toastify";
import {
	AdelantoEditSectionProps,
	AdelantoSectionProps,
} from "../../interfaces/Sections/AdelantoSection";
import DialogForm from "../../../../../../components/Dialog/DialogForm";
import CancelButton from "../../../../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../../../../components/Dialog/Buttons/ApplyButton";
import { DepartureBoard, Save } from "@mui/icons-material";
import { groupsAllowedToEditAdelanto } from "../../../../../../constants/AuthGroups/quotationChilePage";
import { useAuthStore } from "../../../../../../auth/store/AuthStore";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../../components/FormInputs/DateForm";

const AdelantoSection: React.FC<AdelantoSectionProps> = ({
	fetchDetails,
	editable,
	isAllowedToEditReservation,
	store
}) => {
	const { isAuthorizedGroup } = useAuthStore();

	const { t } = useTranslation(["quotation", "general"]);

	const { readOnlyDetails, editableDetails } = store();
	const { adelanto, adelantoApprovalUser, adelantoApprovalDate } = {
		...readOnlyDetails,
	};

	const canEditAdelanto =
		!adelanto &&
		editable &&
		editableDetails.reservations.some(
			(s) => s.orderId !== "" || s.europeOrderSASId !== ""
		) &&
		isAuthorizedGroup(groupsAllowedToEditAdelanto);

	return (
		<>
			{canEditAdelanto && (
				<AdelantoEditSection
					fetchDetails={fetchDetails}
					isAllowedToEditReservation={isAllowedToEditReservation}
					store={store}
				/>
			)}
			{adelanto && (
				<>
					<Grid item xs>
						<TextForm
							label={t("details.sections.adelanto.approvalUser")}
							value={adelantoApprovalUser}
						/>
					</Grid>
					<Grid item xs={3}>
						<DateForm
							label={t("details.sections.adelanto.approvalDate")}
							value={adelantoApprovalDate}
							inputFormat="dd/MM/yy"
						/>
					</Grid>
				</>
			)}
		</>
	);
};

const AdelantoEditSection: React.FC<AdelantoEditSectionProps> = ({
	fetchDetails,
	isAllowedToEditReservation,
	store
}) => {
	const { t } = useTranslation(["quotation", "general"]);
	const [dialogVisible, setDialogVisible] = React.useState(false);

	const { update, updateAdelanto, setLoading } = store();

	const handleAdelantoOnChange = async () => {
		setLoading(true);
		await update(isAllowedToEditReservation)
			.then(() => updateAdelanto())
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err.response.data))
			.finally(() => fetchDetails());
	};

	return (
		<>
			<Grid item xs>
				<Button
					variant="contained"
					color="warning"
					onClick={() => setDialogVisible(true)}
					startIcon={<DepartureBoard />}
					sx={{ alignSelf: "end" }}
				>
					{t("details.sections.adelanto.title")}
				</Button>
			</Grid>
			<DialogForm
				id="quotation-approve-adelanto-dialog"
				open={dialogVisible}
				title={t("confirmationMsg", { ns: "general" })}
				onClose={() => setDialogVisible(false)}
			>
				<DialogContent dividers>
					{t("saveAllConfirmationMsg", { ns: "general" })}{" "}
					{t("details.sections.adelanto.confirmationMsg")}
				</DialogContent>
				<DialogActions>
					<CancelButton
						id="quotation-approve-adelanto-cancelbtn"
						onCancel={() => setDialogVisible(false)}
						title={t("cancel", { ns: "general" })}
					/>
					<ApplyButton
						id="quotation-approve-adelanto-applybtn"
						onSubmit={handleAdelantoOnChange}
						title={t("confirm", { ns: "general" })}
						startIcon={<Save />}
						color="warning"
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(AdelantoSection);
