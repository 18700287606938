import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { CustomerSectionProps } from "../../interfaces/Sections/CustomerSection";
import { useAuthStore } from "../../../../../../auth/store/AuthStore";
import {
	groupsAllowedToEditChileZone,
	groupsAllowedToEditEuropeFactory,
} from "../../../../../../constants/AuthGroups/orderDetails";
import { getChileZoneOptions } from "../../../../../../utils/SelectOptions";
import TextForm from "../../../../../FormInputs/TextForm";
import SelectForm from "../../../../../FormInputs/SelectForm";

const CustomerSection: React.FC<CustomerSectionProps> = ({
	readOnlyFields,
	editableFields,
	setFieldByKeyOnChange,
	isChilean,
}) => {
	const { t } = useTranslation(["order", "general"]);
	const { isAuthorizedGroup } = useAuthStore();

	const userCanEditInfo = isAuthorizedGroup(groupsAllowedToEditEuropeFactory);

	const userCanEditChileZone = isAuthorizedGroup(groupsAllowedToEditChileZone);

	const salesmanEditable = userCanEditInfo && !isChilean;

	const zoneOptions = getChileZoneOptions(t);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.customer.title")}
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.originalCustomer")}
					value={readOnlyFields.endCustomerName}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.sasCustomer")}
					value={editableFields.customerSAS}
					editable={userCanEditInfo}
					name="CustomerSAS"
					slotProps={{ htmlInput: { maxLength: 100 } }}
					onChange={(e) => setFieldByKeyOnChange("customerSAS", e.target.value)}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.customer.bodybuilder")}
					value={editableFields.bodybuilder}
					editable={userCanEditInfo}
					name="Bodybuilder"
					slotProps={{ htmlInput: { maxLength: 100 } }}
					onChange={(e) => setFieldByKeyOnChange("bodybuilder", e.target.value)}
				/>
			</Grid>
			<Grid item xs>
				<TextForm
					label={t("details.sections.customer.salesman")}
					value={editableFields.salesman}
					editable={salesmanEditable}
					name="Salesman"
					slotProps={{ htmlInput: { maxLength: 100 } }}
					onChange={(e) => setFieldByKeyOnChange("salesman", e.target.value)}
				/>
			</Grid>
			{isChilean && (
				<Grid item xs={4}>
					<SelectForm
						label={t("details.sections.customer.zone")}
						labelId={"sections.customer-zone-label"}
						value={editableFields.zoneId}
						options={zoneOptions}
						editable={userCanEditChileZone}
						hasEditIcon
						hasEmptyDefaultItem
						onChange={(e) =>
							setFieldByKeyOnChange(
								"zoneId",
								e?.target?.value as number | undefined
							)
						}
					/>
				</Grid>
			)}
		</>
	);
};

export default observer(CustomerSection);
