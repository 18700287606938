import React from "react";
import { configure, makeObservable } from "mobx";
import { QuotationDetailBaseStore } from "../../../Components/Details/store/QuotationDetailBaseStore";
import { QuotationVehicleInfo } from "../../../Components/Details/interfaces/QuotationVehicleInfo";
import { getCountryName } from "../../../Components/List/utils/StoreUtils";

configure({ enforceActions: "always" });

class QuotationDetailArgentinaStore extends QuotationDetailBaseStore {
  constructor() {
    super();
    makeObservable(this);
  }

  getVehicleInfoByFactoryOrderEurope = async (
    factoryOrder: string
  ): Promise<QuotationVehicleInfo | null> => {
    let quotationVehicleInfo: QuotationVehicleInfo | null = null;

    await this.api.orderService
      .getVehicleInfoByFactoryOrder(factoryOrder, getCountryName(this.region))
      .then((response) => {
        quotationVehicleInfo = response.data;
      });

    return quotationVehicleInfo;
  };

  getVehicleInfoByChassiFromEurope = async (
    chassi: string
  ): Promise<QuotationVehicleInfo | null> => {
    let quotationVehicleInfo: QuotationVehicleInfo | null = null;

    await this.api.orderService
      .getVehicleInfoByChassi(chassi, getCountryName(this.region))
      .then((response) => {
        quotationVehicleInfo = response.data;
      });

    return quotationVehicleInfo;
  };
}

const QuotationDetailStoreContext = React.createContext(
  new QuotationDetailArgentinaStore()
);

export const useQuotationDetailArgentinaStore = () =>
  React.useContext(QuotationDetailStoreContext);
