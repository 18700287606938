import {
	getNumberOrDefault,
	getStringOrDefault,
} from "../../../../../utils/ModelBuilderUItils";
import { EuropeDetailsProps } from "../interfaces/EuropeDetailsProps";
import { EuropeEditDetailsProps } from "../interfaces/EuropeEditDetailsProps";

export const europeEditDetailsModel: EuropeEditDetailsProps = {
	sasId: "",
	customerSAS: "",
	bodybuilder: "",
	salesman: "",
	zoneId: undefined,
};

export function europeEditDetailsModelBuilder(
	data: EuropeDetailsProps
): EuropeEditDetailsProps {
	if (!data) {
		return europeEditDetailsModel;
	}

	return {
		sasId: getStringOrDefault(data.sasId),
		customerSAS: getStringOrDefault(data.customerSAS),
		bodybuilder: getStringOrDefault(data.bodybuilder),
		salesman: getStringOrDefault(data.salesman),
		zoneId: getNumberOrDefault(data.zoneId),
	};
}
