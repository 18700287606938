import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import AutoCompleteForm from "../../../../../components/FormInputs/AutoCompleteForm";
import { useStores } from "../stores/RootStore";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import TextForm from "../../../../../components/FormInputs/TextForm";
import * as AuthGroups from "../../../../../constants/AuthGroups/authGroups";

export type QuotationBatchEditionFieldsProps = {
	groupsQuotationManager: Array<AuthGroups.AuthGroupsType>;
}

const QuotationBatchEditionFields: React.FC<QuotationBatchEditionFieldsProps> = ({ groupsQuotationManager }) => {
	const { t } = useTranslation("quotation");
	const { isAuthorizedGroup } = useAuthStore();

	const { batchData, salesmanOptions, customerOptions, setQuotationOptions } =
		useStores().quotationBatchEditionStore;

	const canSeeSalesman = isAuthorizedGroup(groupsQuotationManager);

	return (
		<>
			{canSeeSalesman && (
				<Grid item xs={12}>
					<AutoCompleteForm
						label={t("details.sections.general.salesman")}
						labelId={"details.sections.general.salesman"}
						options={salesmanOptions}
						value={batchData.salesmanId}
						editable
						onChange={(e) => setQuotationOptions("salesmanId", e as string)}
						isCustomSearch
						hasEditIcon
						editTooltip={t("tooltips.salesmanSearch")}
						listboxStyle={{ maxHeight: "230px" }}
						style={{ padding: "10px 0" }}
					/>
				</Grid>
			)}
			<Grid item xs={12}>
				<AutoCompleteForm
					label={t("details.sections.customer.name")}
					labelId={"details.sections.customer.name"}
					options={customerOptions}
					value={batchData.customerCode}
					editable
					onChange={(e) => setQuotationOptions("customerCode", e as string)}
					isCustomSearch
					hasEditIcon
					editTooltip={t("tooltips.customerSearch")}
					listboxStyle={{ maxHeight: "180px" }}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.customer.rut")}
					value={batchData.rut}
					onChange={(e) => setQuotationOptions("rut", e.target.value)}
					inputProps={{ maxLength: 10 }}
					editable
				/>
			</Grid>
		</>
	);
};

export default observer(QuotationBatchEditionFields);
