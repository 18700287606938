import { GUID_EMPTY } from "../../../../../app/constants/config";
import { CurrencyType } from "../../../../../utils/GenericTypes";
import { CurrencyChlEnum as CurrencyEnum } from "../../../../../utils/enums/CurrencyEnum";
import { getEnumKeyByValue } from "../../../../../utils/enums/EnumMethods";
import { QuotationReadOnlyDetails } from "../interfaces/QuotationReadOnlyDetails";

export const quotationReadOnlyDetailsModel: QuotationReadOnlyDetails = {
	id: GUID_EMPTY,
	// general
	quotationId: "",
	statusSAS: 0,
	quotationDate: new Date(),
	dealerShipName: "",
	lastUpdate: new Date(),
	quantity: 0,
	workflowStatus: "",
	isPermittedToCancel: false,
	isPermittedToSendWorkflowToApproval: false,
	// customer
	customerName: "",
	customerCode: "",
	business: "",
	// vehicle model
	commercialModel: "",
	package: "",
	cabinStyle: "",
	cabinColor: "",
	// vehicle / variants
	variants: [],
	// vehicle / factory options
	factoryOptional: [],
	// quotation comments / final detail
	freeRemarks: "",

	deliveryDateRequested: null,
	priceByUnit: 0,
	totalTaxAmount: 0,
	currency: getEnumKeyByValue(0, CurrencyEnum) as CurrencyType,
	currentExchangeRate: 0,

	financialCustomerName: "",
	//adelanto
	adelanto: false,
	adelantoApprovalUser: "",
	adelantoApprovalDate: null,
};

export function quotationReadOnlyDetailsModelBuilder(data: any) {
	if (!data) return quotationReadOnlyDetailsModel;

	return {
		id: data.id ?? GUID_EMPTY,
		// general
		quotationId: data.quotationId ?? "",
		statusSAS: data.statusSAS ?? "",
		quotationDate: data.quotationDate ?? new Date(),
		dealerShipName: data.dealerShipName ?? "",
		lastUpdate: data.lastUpdate ?? new Date(),
		quantity: data.quantity ?? 0,
		workflowStatus: data.workflowStatus ?? "",
		isPermittedToCancel: data.isPermittedToCancel ?? false,
		isPermittedToSendWorkflowToApproval:
			data.isPermittedToSendWorkflowToApproval ?? false,
		// customer
		customerName: data.customerName ?? "",
		customerCode: data.customerCode ?? "",
		business: data.business ?? "",
		// vehicle model
		commercialModel: data.commercialModel ?? "",
		package: data.package ?? "",
		cabinStyle: data.cabinStyle ?? "",
		cabinColor: data.cabinColor ?? "",
		// vehicle / variants
		variants: data.variants ?? [],
		// vehicle / factory options
		factoryOptional: data.factoryOptional ?? [],
		// quotation comments / final detail
		freeRemarks: data.freeRemarks ?? "",

		deliveryDateRequested: data.deliveryDateRequested,

		priceByUnit: data.priceByUnit ?? 0,
		discount: data.discount ?? 0,
		totalTaxAmount: data.totalTaxAmount ?? 0,
		totalPrice: data.totalPrice ?? 0,
		currency: getEnumKeyByValue(
			data.currency,
			CurrencyEnum
		) as CurrencyType,
		currentExchangeRate: data.currentExchangeRate ?? 0,

		financialCustomerName: data.financialCustomerName ?? "",
		//adelanto
		adelanto: data.adelanto,
		adelantoApprovalUser: data.adelantoApprovalUser ?? "",
		adelantoApprovalDate: data.adelantoApprovalDate ?? null,
	} as QuotationReadOnlyDetails;
}
