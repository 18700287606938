import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { ExportationInvoiceSectionProps } from "../../interfaces/Sections/ExportationInvoiceSection";
import TextForm from "../../../../../FormInputs/TextForm";
import DateForm from "../../../../../FormInputs/DateForm";

const ExportationInvoiceSection: React.FC<ExportationInvoiceSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.invoiceExportation.title")}
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.invoiceExportation.invoiceNumber")}
					value={readOnlyFields.invoiceNumber}
				/>
			</Grid>
			<Grid item xs={6}>
				<DateForm
					label={t("details.sections.invoiceExportation.invoiceDate")}
					value={readOnlyFields.invoiceDate}
				/>
			</Grid>
		</>
	);
};

export default observer(ExportationInvoiceSection);
