import {
	getDateOrDefault,
	getStringOrDefault,
} from "../../../../../utils/ModelBuilderUItils";
import { TruckFieldsProps } from "../interfaces/TruckFieldsProps";
import { baseReadOnlyModel, baseReadOnlyModelBuilder } from "./BaseFieldsModel";

export const truckFieldsModel: TruckFieldsProps = {
	...baseReadOnlyModel,
	assemblyOrder: "",
	deliveryProposal: null,
	commercialTrimmingPackages: "",
	availabilityDate: null,
};

export function truckFieldsModelBuilder(data: TruckFieldsProps) {
	if (!data) {
		return truckFieldsModel;
	}
	const model = baseReadOnlyModelBuilder(data);
	return {
		...truckFieldsModel,
		...model,
		assemblyOrder: getStringOrDefault(data.assemblyOrder),
		deliveryProposal: getDateOrDefault(data.deliveryProposal),
		availabilityDate: getDateOrDefault(data.availabilityDate),
		commercialTrimmingPackages: getStringOrDefault(
			data.commercialTrimmingPackages
		),
	} as TruckFieldsProps;
}
