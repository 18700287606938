import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import { getBreadcrumbs } from "../utils/BreadcrumbsUtils";
import {
	getFieldsAvailableForEditionByUser,
	getUserUpdatePermission,
} from "../utils/EditableFieldsUtils";
import { useFollowUpArgStore } from "../stores/FollowUpArgStore";
import { ownerGroupsOfFollowUpArg } from "../../../../constants/AuthGroups/followUpArgPage";
import BaseDetailIndex from "../../../../components/OrderPages/Arg/DetailsBase/containers/Utils/BaseDetailIndex";
import { VehicleType } from "../../../../utils/GenericTypes";
interface FollowUpDetailIndexProps {
	vehicleType: VehicleType;
}
const FollowUpDetailIndex: React.FC<FollowUpDetailIndexProps> = ({
	vehicleType,
}) => {
	const { t } = useTranslation("menu");
	const { isAuthorizedGroup } = useAuthStore();
	const store = useFollowUpArgStore();
	const userIsOwner = isAuthorizedGroup(ownerGroupsOfFollowUpArg);
	const userUpdatePermission = getUserUpdatePermission(isAuthorizedGroup);
	store.setUserUpdatePermission(userUpdatePermission);
	const fieldsAvailableForEdition =
		getFieldsAvailableForEditionByUser(userUpdatePermission);
	const breadcrumbs = getBreadcrumbs(t);
	return (
		<BaseDetailIndex
			userIsOwner={userIsOwner}
			vehicleType={vehicleType}
			fieldsAvailableForEdition={fieldsAvailableForEdition}
			store={store}
		>
			<TitleBox breadcrumbs={breadcrumbs} />
		</BaseDetailIndex>
	);
};

export default observer(FollowUpDetailIndex);
