import { GUID_EMPTY } from "../../../../../app/constants/config";
import { QuotationEditableDetails } from "../interfaces/QuotationEditableDetails";
import { paymentTermRetomaModel } from "./PaymentTermRetomaModel";
import { ReservationsProps } from "../interfaces/ReservationsProps";
import {
	priceAndPaymentModel,
	priceAndPaymentModelBuilder,
} from "./PriceAndPaymentModel";

export const quotationEditableDetailsModel: QuotationEditableDetails = {
	id: GUID_EMPTY,
	// vehicle / local customizations
	customizations: [],
	// factory order / application merges
	reservations: [],
	paymentTerms: [],
	paymentTermRetoma: paymentTermRetomaModel,
	priceAndPayment: priceAndPaymentModel,
};

export function quotationEditableDetailsModelBuilder(data: any) {
	if (!data) return quotationEditableDetailsModel;

	return {
		id: data.id ?? GUID_EMPTY,
		// vehicle / local customizations
		customizations: data.customizations ?? [],
		// factory order / application merges
		reservations: data.reservations
			? data.reservations.map((x: ReservationsProps) => ({
				...x,
				chassiError: "",
				foError: "",
				adelanto: x.factoryOrder ? data.adelanto : false,
			}))
			: [],
		paymentTerms: data.paymentTerms ?? [],
		paymentTermRetoma: data.paymentTermRetoma ?? paymentTermRetomaModel,
		priceAndPayment: priceAndPaymentModelBuilder(data, data.id),
	} as QuotationEditableDetails;
}
