import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../../apis/CtadmvenApi";
import _ from "lodash";
import { EuropeDetailsProps } from "../interfaces/EuropeDetailsProps";
import {
	europeDetailsModel,
	europeDetailsModelBuilder,
} from "../models/EuropeDetailsModel";
import { EuropeEditDetailsProps } from "../interfaces/EuropeEditDetailsProps";
import {
	europeEditDetailsModel,
	europeEditDetailsModelBuilder,
} from "../models/EuropeEditDetailsModel";
import { localizationExportFieldsModelBuilder } from "../../../Details/models/LocalizationExportFieldsModel";
import { invoicingDeliveryMarketCompanyFieldsModelBuilder } from "../../../Details/models/InvoicingDeliveryMarketCompanyFieldsModel";

configure({ enforceActions: "always" });

/* Store start */
export class EuropeDetailsBaseStore {
	@observable fields: EuropeDetailsProps = europeDetailsModel;
	@observable initialEditableDetails: EuropeEditDetailsProps =
		europeEditDetailsModel;
	@observable hasChanges = false;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action resetStore = () => {
		this.fields = europeDetailsModel;
		this.initialEditableDetails = europeEditDetailsModel;
		this.hasChanges = false;
	};

	getEditableFields = () => europeEditDetailsModelBuilder(this.fields);

	//========================================set observables=========================================
	setFieldsDetails = (data: any) =>
		(this.fields = europeDetailsModelBuilder(data));

	setInitialEditableDetails = (data: any) =>
		(this.initialEditableDetails = europeEditDetailsModelBuilder(data));

	setInvoiceDeliveryFieldsDetails = (data: any) =>
		(this.fields.invoicingDeliveryMarketCompany =
			invoicingDeliveryMarketCompanyFieldsModelBuilder(data));

	setLocalizationFieldsDetails = (data: any) =>
		(this.fields.localizationExport =
			localizationExportFieldsModelBuilder(data));

	setVariants = (data: any) => {
		this.fields.variants = data ?? [];
	};

	setHasChanges = () => {
		this.hasChanges = !_.isEqual(
			this.initialEditableDetails,
			this.getEditableFields()
		);
	};

	//END: observables

	//=========================================set fields on change=========================================
	@action setEditableFieldByKeyOnChange = <
		K extends keyof EuropeEditDetailsProps,
	>(
		fieldKey: K,
		newValue: EuropeDetailsProps[K]
	) => {
		this.fields[fieldKey] = newValue;
		this.setHasChanges();
	};

	//END: set fields on change

	//=========================================load details=========================================
	loadDetails = async (sasId: string, menu?: string) => {};
	loadInvoiceDeliveryDetails = async (menu?: string) => {};
	loadLocalizationDetails = async (menu?: string) => {};
	loadVariantsDetails = async (menu?: string) => {};

	@action loadAllDetails = async (sasId: string, menu?: string) => {
		this.resetStore();
		await this.loadDetails(sasId, menu);
		this.setInitialEditableDetails(this.fields);
	};
	//END: load details
	update = async (menu?: string) => {};
}
/* Store end */
