import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { RespecificationSectionProps } from "../../interfaces/Sections/RespecificationSection";
import DateForm from "../../../../FormInputs/DateForm";

const RespecificationSection: React.FC<RespecificationSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.respecification.title")}
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.respecification.cddRespec")}
					value={readOnlyFields.cddRespec}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.respecification.respecStartDate")}
					value={readOnlyFields.respecStartDate}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.respecification.respecEndDate")}
					value={readOnlyFields.respecEndDate}
				/>
			</Grid>
		</>
	);
};

export default observer(RespecificationSection);
