import { observer } from "mobx-react";
import { groupsAllowedToSeeQuotationChileMenu, groupsQuotationManager, groupsToExportQuotationCsv } from "../../../../../constants/AuthGroups/quotationChilePage";
import { CountryRegionIso } from "../../../../../enums/CountryRegionIso";
import QuotationIndex from "../../../Components/List/containers/QuotationIndex";


const MainList: React.FC = () => {
  return (
    <QuotationIndex region={CountryRegionIso.CL} groupsAllowedToSeeQuotationMenu={groupsAllowedToSeeQuotationChileMenu}
      groupsQuotationManager={groupsQuotationManager} groupsToExportQuotationCsv={groupsToExportQuotationCsv} />
  );
}

export default observer(MainList);