import { CountryRegionIso } from "../../../../../enums/CountryRegionIso";
import { CountryType, CurrencyType } from "../../../../../utils/GenericTypes";
import RootStore from "../stores/RootStore";

export function newRootStore(): RootStore {
	return {
		filterStore: {},
		listStore: {},
		quotationBatchEditionStore: {},
		preFilterQuotationIDStore: {},
		preFilterQuotationDateStore: {},
		preFilterStatusSASStore: {},
		preFilterFactoryOrderStore: {},
		preFilterChassiStore: {},
		preFilterSalesmanStore: {},
		preFilterReservationStatusStore: {},
	};
}


export function isChileQuotation(region: CountryRegionIso) {
	return region === CountryRegionIso.CL;
}

export function isArgentinaQuotation(region: CountryRegionIso) {
	return region === CountryRegionIso.AR;
}

export function getCountryCode(region: CountryRegionIso): CountryType {
	switch (region) {
		case CountryRegionIso.AR:
			return "ARG";
		case CountryRegionIso.CL:
			return "CHL";
		default:
			return "BRA";
	}
}

export function getCountryName(region: CountryRegionIso): string {
	switch (region) {
		case CountryRegionIso.AR:
			return "ARGENTINA";
		case CountryRegionIso.CL:
			return "CHILE";
		default:
			return "BRAZIL";
	}
}

export function getCountryCurrency(region: CountryRegionIso): CurrencyType {
	switch (region) {
		case CountryRegionIso.AR:
			return "ARS";
		case CountryRegionIso.CL:
			return "CLP";
		default:
			return "USD";
	}
}

