import {
	getBooleanOrDefaultFalse,
	getDateOrDefault,
	getNumberOrDefault,
	getStringOrDefault,
} from "../../../../../utils/ModelBuilderUItils";
import { localizationExportFieldsModel } from "../../../Details/models/LocalizationExportFieldsModel";
import { invoicingDeliveryMarketCompanyFieldsModel } from "../../../Details/models/InvoicingDeliveryMarketCompanyFieldsModel";
import { EuropeDetailsProps } from "../interfaces/EuropeDetailsProps";
import {
	europeEditDetailsModel,
	europeEditDetailsModelBuilder,
} from "./EuropeEditDetailsModel";

export const europeDetailsModel: EuropeDetailsProps = {
	...europeEditDetailsModel,

	//general
	assemblyOrder: "",
	factoryOrder: "",
	factory: "",
	deliveryProposal: null,
	chassi: "",
	msp: undefined,
	brand: "",

	//customer
	endCustomerName: "",

	//vehicle model
	industrialModel: "",
	commercialModel: "",
	factoryYear: "",
	traction: "",
	tractionType: "",
	weigth: "",
	power: "",

	//vin
	vehicleIdentificationNumber: "",
	engineSerialNumber: "",

	//variants
	variants: [],

	//production dates
	lcd: null,
	cdd: null,
	asweekDate: null,
	greenOk: null,
	dps: null,

	//exportation location
	estimatedDeparture: null,
	realDeparture: null,
	estimatedArrival: null,
	realArrival: null,

	//exportation invoice
	invoiceDate: null,
	invoiceNumber: "",

	//exportation reservation
	exportationStatus: "",
	available: false,
	availableDate: null,
	reserved: false,
	quotationNumber: "",
	reservationCreationDate: null,

	invoicingDeliveryMarketCompany: invoicingDeliveryMarketCompanyFieldsModel,
	localizationExport: localizationExportFieldsModel,

	countryName: "",
	isChilean: false,
};

export function europeDetailsModelBuilder(data: any): EuropeDetailsProps {
	if (!data) {
		return europeDetailsModel;
	}
	const europeEditDetails = europeEditDetailsModelBuilder(data);
	return {
		...europeDetailsModel,
		...europeEditDetails,
		//general
		assemblyOrder: getStringOrDefault(data.assemblyOrder),
		factoryOrder: getStringOrDefault(data.factoryOrder),
		factory: getStringOrDefault(data.factory),
		deliveryProposal: getDateOrDefault(data.deliveryProposal),
		chassi: getStringOrDefault(data.chassi),
		msp: getNumberOrDefault(data.msp),
		brand: getStringOrDefault(data.brand),

		//customer
		endCustomerName: getStringOrDefault(data.endCustomerName),

		//vehicle model
		industrialModel: getStringOrDefault(data.industrialModel),
		commercialModel: getStringOrDefault(data.commercialModel),
		factoryYear: getStringOrDefault(data.factoryYear),
		traction: getStringOrDefault(data.traction),
		tractionType: getStringOrDefault(data.tractionType),
		weigth: getStringOrDefault(data.weigth),
		power: getStringOrDefault(data.power),

		//vin
		vehicleIdentificationNumber: getStringOrDefault(
			data.vehicleIdentificationNumber
		),
		engineSerialNumber: getStringOrDefault(data.engineSerialNumber),

		//variants
		variants: data.variants ?? [],

		//production dates
		lcd: getDateOrDefault(data.lcd),
		cdd: getDateOrDefault(data.cdd),
		asweekDate: getDateOrDefault(data.asWeekDate),
		greenOk: getDateOrDefault(data.greenOk),
		dps: getDateOrDefault(data.dps),

		//exportation location
		estimatedDeparture: getDateOrDefault(data.estimatedDeparture),
		realDeparture: getDateOrDefault(data.realDeparture),
		estimatedArrival: getDateOrDefault(data.estimatedArrival),
		realArrival: getDateOrDefault(data.realArrival),

		//exportation invoice
		invoiceDate: getDateOrDefault(data.invoiceDate),
		invoiceNumber: getStringOrDefault(data.invoiceNumber),

		//exportation reservation
		exportationStatus: getStringOrDefault(
			data.reservationExport.exportationStatus
		),
		available: getBooleanOrDefaultFalse(data.reservationExport.available),
		availableDate: getDateOrDefault(data.reservationExport.availableDate),
		reserved: getBooleanOrDefaultFalse(data.reservationExport.reserved),
		quotationNumber: getStringOrDefault(data.reservationExport.quotationNumber),
		reservationCreationDate: getDateOrDefault(
			data.reservationExport.reservationCreationDate
		),

		countryName: getStringOrDefault(data.countryName),
		isChilean: getIsChilean(data.countryName),
	};
}

const getIsChilean = (countryName: string) => countryName === "CHILE";
