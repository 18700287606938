import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { ModelSectionBusProps } from "../../../interfaces/Sections/Bus/ModelSectionBus";
import BaseModelSection from "../BaseModelSection";
import TextForm from "../../../../../FormInputs/TextForm";

const ModelSectionBus: React.FC<ModelSectionBusProps> = ({ fields }) => {
	const { t } = useTranslation("order");

	return (
		<>
			<BaseModelSection fields={fields} />
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.busApplication")}
					value={fields.busApplication}
				/>
			</Grid>
		</>
	);
};

export default observer(ModelSectionBus);
