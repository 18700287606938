import { GUID_EMPTY } from "../../../../app/constants/config";
import { BaseFieldsProps } from "../../../../components/OrderPages/Arg/DetailsBase/interfaces/BaseFieldsProps";
import {
	getDateOrDefault,
	getStringOrDefault,
} from "../../../../utils/ModelBuilderUItils";
import { ClosedPeriodUpdateProps } from "../interfaces/ClosedPeriodUpdateProps";

export const closedPeriodUpdateModel: ClosedPeriodUpdateProps = {
	orderId: GUID_EMPTY,
	shippingNumber: "",
	certificateDate: null,
};

export function closedPeriodUpdateModelBuilder(
	data: BaseFieldsProps
): ClosedPeriodUpdateProps {
	if (!data) {
		return closedPeriodUpdateModel;
	}
	return {
		orderId: data.id,
		shippingNumber: getStringOrDefault(data.orderMCManagement.shippingNumber),
		certificateDate: getDateOrDefault(data.orderMCManagement.certificateDate),
	};
}
