import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { TruckEditableProps } from "../interfaces/Editable/TruckEditableDetails";
import { TruckReadOnlyProps } from "../interfaces/TruckReadOnlyDetails";
import _ from "lodash";
import { SelectOptionProps } from "../../../../utils/GenericInterfaces";
import {
	truckReadOnlyModel,
	truckReadOnlyModelBuilder,
} from "../models/TruckReadOnlyModel";
import {
	truckEditableModel,
	truckEditableModelBuilder,
} from "../models/Editable/TruckEditableModel";
import { saleInvoiceModelBuilder } from "../models/SaleInvoiceModel";
import { invoiceExportationModelBuilder } from "../../../../components/OrderPages/Details/models/InvoiceExportationModel";
import { invoiceInstructionModelBuilder } from "../models/InvoiceInstructionModel";
import { invoiceInstructionExportationModelBuilder } from "../models/InvoiceInstructionExportationModel";
import { reservationAvailabilityModelBuilder } from "../models/ReservationAvailabilityModel";
import RootStore from "./RootStore";
import { newRootStore } from "../utils/StoreUtils";
import { StoreUtils } from "../../../../utils/StoreUtils";
import BaseTruckAndBusStore from "./BaseTruckAndBusStore";
import { invoicingDeliveryMarketCompanyFieldsModelBuilder } from "../../../../components/OrderPages/Details/models/InvoicingDeliveryMarketCompanyFieldsModel";

configure({ enforceActions: "always" });

/* Store start */
class TruckStore extends BaseTruckAndBusStore {
	@observable menuKey: string = "";
	@observable readOnlyDetails: TruckReadOnlyProps = truckReadOnlyModel;
	@observable editableDetails: TruckEditableProps = truckEditableModel;
	@observable initialEditableDetails: TruckEditableProps = truckEditableModel;
	@observable districtsList: Array<SelectOptionProps> = [];
	@observable regionsList: Array<SelectOptionProps> = [];
	@observable hasChanges: boolean = false;

	rootStore: RootStore = newRootStore();

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		super(mainStore);
		this.rootStore = mainStore;
		makeObservable(this);
	}

	@action resetStore = (menuKey: string) => {
		this.readOnlyDetails = truckReadOnlyModel;
		this.editableDetails = truckEditableModel;
		this.initialEditableDetails = truckEditableModel;
		this.districtsList = [];
		this.regionsList = [];
		this.hasChanges = false;
		this.menuKey = menuKey;
	};

	@action setEditableDetailsFieldByKeyOnChange = (
		fieldKey: keyof TruckEditableProps,
		newValue: string & Date
	) => {
		this.editableDetails[fieldKey] = newValue ?? "";
		this.checkChanges();
	};

	@action setReadOnlyDetails = (data: any) => {
		this.readOnlyDetails = truckReadOnlyModelBuilder({
			...this.readOnlyDetails,
			...data,
		});
	};

	@action setEditableDetails = (data: any) => {
		this.editableDetails = truckEditableModelBuilder({
			...this.editableDetails,
			...data,
		});
		this.initialEditableDetails = truckEditableModelBuilder({
			...this.initialEditableDetails,
			...data,
		});
	};

	@action setRegionsList = (regions: any) => {
		const defaultRegionsMissing = [this.editableDetails.quotaRegion].filter(
			(value: string) => !regions.includes(value)
		);
		regions = regions.concat(defaultRegionsMissing).sort();
		this.regionsList = regions.map((value: string) => ({
			label: value,
			value: value,
		}));
	};
	@action setDistrictsList = (districts: any) => {
		const defaultDistrictsMissing = [this.editableDetails.quotaDistrict].filter(
			(value: string) => !districts.includes(value)
		);
		districts = districts.concat(defaultDistrictsMissing).sort();
		this.districtsList = districts.map((value: string) => ({
			label: value,
			value: value,
		}));
	};

	@action private checkChanges = () => {
		this.hasChanges = !_.isEqual(
			this.initialEditableDetails,
			this.editableDetails
		);
		this.rootStore.commonStore.setHasChanges();
	};

	@action loadDetails = async (
		id: string,
		menuKey: string,
		userDisplayName: string
	) => {
		this.rootStore.commonStore.resetAllStores(id, menuKey);

		await StoreUtils.LoadData(
			() => this.api.orderService.getDetails(id, this.menuKey),
			(data: any) => {
				this.setReadOnlyDetails(data);
				this.setEditableDetails(data);
				this.rootStore.commonStore.setStore(data.id, menuKey);
				this.rootStore.commonStore.setBaseFields(userDisplayName);
			},
			id,
			this.menuKey
		);
	};

	@action loadVolvoExclusiveDetails = async (
		id: string,
		userCanSeeQuotaInfo: boolean,
		userCanEditQuotaInfo: boolean
	) => {
		if (!id || !this.menuKey) {
			return Promise.resolve();
		}
		if (userCanEditQuotaInfo) {
			return await this.loadQuotaEditableDetails(id);
		}
		if (userCanSeeQuotaInfo) {
			return await this.loadQuotaDetails(id);
		}
		return Promise.resolve();
	};

	@action loadQuotaEditableDetails = async (id: string) => {
		const loadQuotaDetails_request = this.api.orderService.getQuotaDetails(
			id,
			this.menuKey
		);
		const loadDistrictsList_request = this.api.districtService.getList();
		const loadRegionsList_request = this.api.regionService.getList();

		await Promise.all([
			loadQuotaDetails_request,
			loadDistrictsList_request,
			loadRegionsList_request,
		]).then((responses) => {
			this.setReadOnlyDetails(responses[0].data);
			this.setEditableDetails(responses[0].data);
			this.setDistrictsList(responses[1].data);
			this.setRegionsList(responses[2].data);
		});
	};

	@action loadQuotaDetails = async (id: string) => {
		await StoreUtils.LoadData(
			() => this.api.orderService.getQuotaDetails(id, this.menuKey),
			(data: any) => {
				this.setReadOnlyDetails(data);
				this.setEditableDetails(data);
				this.setDistrictsList([]);
				this.setRegionsList([]);
			},
			id,
			this.menuKey
		);
	};

	@action loadVariants = async () => {
		const id = this.readOnlyDetails.id;

		await StoreUtils.LoadData(() => this.api.orderService.getDetailsVariants(id, this.menuKey), this.setVariants, id, this.menuKey);
	};

	@action update = async () => {
		await this.rootStore.commonStore.update();

		if (!this.hasChanges) {
			return;
		}

		if (this.hasZoneChanges()) {
			await this.api.orderService.updateZone(
				this.editableDetails.id,
				this.editableDetails.zoneId
			);
		}

		await this.api.orderService.update(this.editableDetails, this.menuKey);
	};

	private hasZoneChanges = (): boolean =>
		this.editableDetails.zoneId !== this.initialEditableDetails.zoneId;

	setReservationAvailability = (data: any) => {
		this.readOnlyDetails.reservationAvailability = reservationAvailabilityModelBuilder(data);
	}

	setVariants = (data: any) => {
		this.readOnlyDetails.variants = data ?? [];
	}

	setVariantsReespec = (data: any) => {
		this.readOnlyDetails.variantsRespec = data ?? [];
	}

	setSaleInvoice = (data: any) => {
		this.readOnlyDetails.saleInvoice = saleInvoiceModelBuilder(data);
	}

	setLocalizationInvoices = (data: any) => {
		this.readOnlyDetails.localizationInvoices = data ?? [];
	}

	setInvoiceExportation = (data: any) => {
		this.readOnlyDetails.invoiceExportation = invoiceExportationModelBuilder(data);
	}

	setInvoicingDeliveryMarketCompany = (data: any) => {
		this.readOnlyDetails.invoicingDeliveryMarketCompany = invoicingDeliveryMarketCompanyFieldsModelBuilder(data);
	}

	setAllInvoiceInstruction = (data: any) => {
		this.readOnlyDetails.invoiceInstruction = invoiceInstructionModelBuilder(data);
	}

	setMinimalInvoiceInstruction = (data: any) => {
		this.readOnlyDetails.invoiceInstruction = invoiceInstructionModelBuilder(data);
	}

	setAllInvoiceInstructionExportation = (data: any) => {
		this.readOnlyDetails.invoiceInstructionExportation = invoiceInstructionExportationModelBuilder(data);
	}

	setMinimalInvoiceInstructionExportation = (data: any) => {
		this.readOnlyDetails.invoiceInstructionExportation = invoiceInstructionExportationModelBuilder(data);
	}
}
/* Store end */
export default TruckStore;
