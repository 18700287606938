import { FilterDescriptor } from "@progress/kendo-data-query";
import { action, configure, makeObservable, observable } from "mobx";

configure({ enforceActions: "always" });

class BasePreFilterStringStore {
    @observable rootStore: any;
    @observable preFilter = "";

    constructor(rootStore: any) {
        this.rootStore = rootStore;
        this.reset();
        makeObservable(this);
    }

    @action reset = () => {
        this.preFilter = "";
    };

    @action clean = () => {
        this.preFilter = "";
    };

    getSpecificFilter(): any {
        // Implement in subclasses
    }

    @action set = (value: string) => {
        this.preFilter = value;
    };

    @action getFilterDescriptor(filters: Array<FilterDescriptor> | Array<Array<FilterDescriptor>>) {
        const filterObjects = this.getSpecificFilter();

        if (Array.isArray(filters) && filters.length > 0 && Array.isArray(filters[0])) {
            const orFiltersGroups = filters as Array<Array<FilterDescriptor>>;
            if (!Array.isArray(filterObjects)) {
                orFiltersGroups.push(filterObjects);
            } 
            else {
                orFiltersGroups.push(filterObjects[0]);
            }
        }
        else {
            const filtersParam = filters as Array<FilterDescriptor>;
            filtersParam.push(...filterObjects);
        }

        return filters;
    }
}

export default BasePreFilterStringStore;
