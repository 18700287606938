import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../../components/AccordionCard/AccordionCard";
import { observer } from "mobx-react";
import ReservationsSection from "../Sections/ReservationsSection";
import { Grid } from "@mui/material";
import DateForm from "../../../../../../components/FormInputs/DateForm";
import { TruckReservationAccordionProps } from "../../interfaces/Accordions/TruckReservationAccordion";
import AdelantoSection from "../Sections/AdelantoSection";
import AdelantoChip from "./AdelantoChip";

const TruckReservationAccordion: React.FC<TruckReservationAccordionProps> = (
	details
) => {
	const { t } = useTranslation("quotation");

	return (
		<AccordionCard
			id="generalAccordion"
			title={t("details.accordions.truckReservation")}
			summaryBody={details.adelanto && <AdelantoChip />}
		>
			<Grid item xs={3}>
				<DateForm
					label={t("details.sections.reservations.deliveryDateRequested")}
					value={
						details?.truckReservationDetails?.readOnlyFields
							?.deliveryDateRequested
					}
					inputFormat="dd/MM/yy"
				/>
			</Grid>
			<AdelantoSection {...details.adelantoSectionProps} />
			<ReservationsSection {...details.reservationSectionProps} />
		</AccordionCard>
	);
};

export default observer(TruckReservationAccordion);
