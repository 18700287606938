import { configure, makeObservable } from "mobx";
import React from "react";
import { StoreUtils } from "../../../../utils/StoreUtils";
import { EuropeDetailsBaseStore } from "../../../../components/OrderPages/Europe/DetailsBase/stores/EuropeDetailsBaseStore";

configure({ enforceActions: "always" });

/* Store start */
export class FollowUpChlEuropeStore extends EuropeDetailsBaseStore {
	constructor() {
		super();
		makeObservable(this);
	}

	//=========================================load details=========================================
	loadDetails = async (orderId: string, menu?: string) => {
		await StoreUtils.LoadData(
			() => this.api.followUpChlService.getEuropeDetails(orderId),
			this.setFieldsDetails,
			orderId
		);
	};
	loadInvoiceDeliveryDetails = async (menu?: string) => {
		await StoreUtils.LoadData(
			() =>
				this.api.followUpChlService.getInvoicingDeliveryMarketCompanyByEuropeOrderSASId(
					this.fields.sasId
				),
			this.setInvoiceDeliveryFieldsDetails,
			this.fields.sasId
		);
	};
	loadLocalizationDetails = async (menu?: string) => {
		await StoreUtils.LoadData(
			() => this.api.followUpChlService.getLocalization(this.fields.sasId),
			this.setLocalizationFieldsDetails,
			this.fields.sasId
		);
	};
	loadVariantsDetails = async (menu?: string) => {
		const id = this.fields.sasId;
		await StoreUtils.LoadData(
			() => this.api.followUpChlService.getEuropeDetailsVariants(id),
			this.setVariants,
			id
		);
	};

	//END: load details
	update = async (menu?: string) => {
		await this.api.followUpChlService.updateEurope(this.getEditableFields());
	};
}
/* Store end */
const FollowUpChlEuropeStoreContext = React.createContext(
	new FollowUpChlEuropeStore()
);

export const useFollowUpChlEuropeStore = () =>
	React.useContext(FollowUpChlEuropeStoreContext);
