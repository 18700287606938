import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import { AvailabilityInvoicingDeliveryAccordionProps } from "../../interfaces/Accordions/AvailabilityInvoicingDeliveryAccordion";
import ReservationExportSection from "../../../../../components/OrderPages/Details/containers/Sections/ReservationExportSection";
import InvoicingDeliveryMarketCompanySection from "../../../../../components/OrderPages/Details/containers/Sections/InvoicingDeliveryMarketCompanySection";
import InvoiceExportationSection from "../../../../../components/OrderPages/Details/containers/Sections/InvoiceExportationSection";

const AvailabilityInvoicingDeliveryAccordion: React.FC<
	AvailabilityInvoicingDeliveryAccordionProps
> = (details) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="invoicingAndDeliveryAccordion"
			title={t("details.accordions.availabilityInvoicingDelivery")}
		>
			<ReservationExportSection {...details.reservationExportDetails} />
			<InvoiceExportationSection {...details.invoiceExportationDetails} />
			<InvoicingDeliveryMarketCompanySection
				{...details.invoicingDeliveryMarketCompanyDetails}
			/>
		</AccordionCard>
	);
};

export default observer(AvailabilityInvoicingDeliveryAccordion);
