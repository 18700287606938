import { SelectOptionProps } from "./GenericInterfaces";
import { dealersBrazil } from "../constants/dealersBrazil";
import { TFunction } from "i18next";
import { PaqueteProps } from "../components/OrderPages/Arg/DetailsBase/interfaces/PaqueteProps";

export function getBooleanOptions(
	t: TFunction<"general", undefined>
): Array<SelectOptionProps> {
	return [
		{ label: t("yes", { ns: "general" }), value: true },
		{ label: t("no", { ns: "general" }), value: false },
	];
}

export function getDefaultStringOption(
	t: TFunction<"general", undefined>
): SelectOptionProps {
	return { label: t("all", { ns: "general" }), value: "" };
}

export function getDefaultArrayStringOption(
	t: TFunction<"general", undefined>,
	options: Array<SelectOptionProps>
): SelectOptionProps {
	return {
		label: t("all", { ns: "general" }),
		value: "defaultArray" + options.map((m) => m.value),
	};
}

export function getVehicleLineOptions(): Array<SelectOptionProps> {
	return [
		{ label: "F", value: "F" },
		{ label: "VM", value: "VM" },
	];
}

export function getMarketOptions(): Array<SelectOptionProps> {
	return [
		{ label: "BR", value: "BR" },
		{ label: "EXP", value: "EXP" },
	];
}

export function getNumbersOptions(numbers: number[]): Array<SelectOptionProps> {
	return numbers.map((x) => ({ label: x.toString(), value: x }));
}

export function getDealerBrazilOptions(): Array<SelectOptionProps> {
	return dealersBrazil.map((x) => ({ label: x, value: x }));
}

export function getDealerExportOptions(): Array<SelectOptionProps> {
	return [
		{ label: "ARG", value: "ARG" },
		{ label: "CHL", value: "CHL" },
		{ label: "PER", value: "PER" },
		{ label: "PI+", value: "PI" },
	];
}

export function getDealerTracbelOptions(): Array<SelectOptionProps> {
	return [
		{ label: "TNE", value: "TNE" },
		{ label: "TNO", value: "TNO" },
	];
}

export function getTimePeriodOptions(
	t: TFunction<"general", undefined>
): Array<SelectOptionProps> {
	return [
		{ label: t("monthly", { ns: "general" }), value: 1 },
		{ label: t("bimonthly", { ns: "general" }), value: 2 },
		{ label: t("threeMonthly", { ns: "general" }), value: 3 },
		{ label: t("quarterly", { ns: "general" }), value: 4 },
		{ label: t("halfYearly", { ns: "general" }), value: 6 },
		{ label: t("yearly", { ns: "general" }), value: 12 },
	];
}

export function getTimePeriodOptionsForProductionWeek(
	t: TFunction<"general", undefined>
): Array<SelectOptionProps> {
	return [
		{ label: t("threeMonthly", { ns: "general" }), value: 3 },
		{ label: t("halfYearly", { ns: "general" }), value: 6 },
		{ label: t("yearly", { ns: "general" }), value: 12 },
	];
}

export function getExportationStatusOptions(
	t: TFunction<"order", undefined>
): Array<SelectOptionProps> {
	return [
		{
			label: t("details.exportationStatus.production", { ns: "order" }),
			value: "Production",
		},
		{
			label: t("details.exportationStatus.invoicedMC", { ns: "order" }),
			value: "InvoicedMC",
		},
		{
			label: t("details.exportationStatus.inTransit", { ns: "order" }),
			value: "InTransit",
		},
		{
			label: t("details.exportationStatus.localStock", { ns: "order" }),
			value: "LocalStock",
		},
		{
			label: t("details.exportationStatus.invoiced", { ns: "order" }),
			value: "Invoiced",
		},
		{
			label: t("details.exportationStatus.delivered", { ns: "order" }),
			value: "Delivered",
		},
	];
}

export function getExportationStatusOptionsByEnum(
	t: TFunction<"order", undefined>
): Array<SelectOptionProps> {
	return [
		{
			label: t("details.exportationStatus.production", { ns: "order" }),
			value: 0,
		},
		{
			label: t("details.exportationStatus.invoicedMC", { ns: "order" }),
			value: 5,
		},
		{
			label: t("details.exportationStatus.inTransit", { ns: "order" }),
			value: 1,
		},
		{
			label: t("details.exportationStatus.localStock", { ns: "order" }),
			value: 2,
		},
		{
			label: t("details.exportationStatus.invoiced", { ns: "order" }),
			value: 3,
		},
		{
			label: t("details.exportationStatus.delivered", { ns: "order" }),
			value: 4,
		},
	];
}

export function getCurrencyOptions(): Array<SelectOptionProps> {
	return [
		{ label: "ARS", value: "ARS" },
		{ label: "USD", value: "USD" },
	];
}

export function getCurrencyEnumOptions(
	t: TFunction<"general", undefined>
): Array<SelectOptionProps> {
	return [
		{ label: "ARS", value: 1 },
		{ label: "USD", value: 0 },
	];
}

export function getQuotationStatusSASOptions(
	t: TFunction<"quotation", undefined>
): Array<SelectOptionProps> {
	return [
		{ label: t("statusSAS.active"), value: 0 },
		{ label: t("statusSAS.canceled"), value: 1 },
		{ label: t("statusSAS.invoiced"), value: 2 },
		{ label: t("statusSAS.delivered"), value: 3 },
	];
}

export function getQuotationReservationStatusOptions(
	t: TFunction<"quotation", undefined>
): Array<SelectOptionProps> {
	return [
		{ label: t("reservationStatus.empty"), value: "Empty" },
		{ label: t("reservationStatus.partial"), value: "Partial" },
		{ label: t("reservationStatus.complete"), value: "Complete" },
	];
}

export function getQuotationWorkflowStatusOptions(
	t: TFunction<"quotation", undefined>
): Array<SelectOptionProps> {
	return [
		{ label: "N/A", value: "" },
		{ label: t("workflowStatus.requested"), value: "Requested" },
		{ label: t("workflowStatus.approved"), value: "Approved" },
		{ label: t("workflowStatus.rejected"), value: "Rejected" },
		{ label: t("workflowStatus.review"), value: "Review" },
	];
}

export function getFollowUpChlHighlightOptions(
	t: TFunction<"followUpChl", undefined>
): Array<SelectOptionProps> {
	return [
		{ label: t("highlight.none"), value: 0, color: "#FFF" },
		{ label: t("highlight.reserved"), value: 3, color: "#FF5000" },
		{
			label: t("highlight.invoicedToFinalCustomer"),
			value: 2,
			color: "#FFA000",
		},
		{
			label: t("highlight.deliveredToFinalCustomer"),
			value: 1,
			color: "#1ABC31",
		},
	];
}

export function getChileZoneOptions(
	t: TFunction<string, undefined>
): Array<SelectOptionProps> {
	return [
		{
			label: t("details.sections.customer.zoneOptions.south", { ns: "order" }),
			value: 1,
		},
		{
			label: t("details.sections.customer.zoneOptions.center", { ns: "order" }),
			value: 2,
		},
		{
			label: t("details.sections.customer.zoneOptions.north", { ns: "order" }),
			value: 3,
		},
		{
			label: t("details.sections.customer.zoneOptions.office", { ns: "order" }),
			value: 4,
		},
	];
}

export function getPreFilterNullOptions(
	t: TFunction<"general", undefined>
): Array<SelectOptionProps> {
	return [
		{ label: t("isNull", { ns: "general" }), value: "false" },
		{ label: t("isNotNull", { ns: "general" }), value: "true" },
	];
}

export function getDateTypeOptions(
	t: TFunction<"quota", undefined>
): Array<SelectOptionProps> {
	return [
		{ label: t("date-type.proposal"), value: "Proposal" },
		{ label: t("date-type.month"), value: "Month" },
	];
}

export function getArrayOfObjAsOptions(
	data: Array<any>
): Array<SelectOptionProps> {
	if (!data) {
		return [];
	}
	const arrayOfKeyValues = Object.entries(data);
	return arrayOfKeyValues.map((m) => ({
		value: m[0],
		label: m[1] as string,
	}));
}

export function getArrayOfStringAsOptions(
	data: Array<string>
): Array<SelectOptionProps> {
	if (!data) {
		return [];
	}
	return data.map((m) => ({
		value: m,
		label: m,
	}));
}

export function getPaqueteOptions(
	data: Array<PaqueteProps>
): Array<SelectOptionProps> {
	if (!data) {
		return [];
	}
	return data.map((m) => ({
		value: m.id,
		label: m.paqueteDescription,
	}));
}
