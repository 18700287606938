import { getStringOrDefault } from "../../../../../utils/ModelBuilderUItils";
import { BusFieldsProps } from "../interfaces/BusFieldsProps";
import { baseReadOnlyModel, baseReadOnlyModelBuilder } from "./BaseFieldsModel";

export const busFieldsModel: BusFieldsProps = {
	...baseReadOnlyModel,
	busApplication: "",
};

export function busFieldsModelBuilder(data: BusFieldsProps) {
	if (!data) {
		return busFieldsModel;
	}
	const model = baseReadOnlyModelBuilder(data);
	return {
		...busFieldsModel,
		...model,
		busApplication: getStringOrDefault(data.busApplication),
	} as BusFieldsProps;
}
