import React from "react";
import { observer } from "mobx-react";
import PreFilter from "../../../../../../components/PreFilter/PreFilter";
import QuotationDatePreFilter from "../../../../../../components/PreFilter/containers/QuotationDatePreFilter";
import { useTranslation } from "react-i18next";
import { useStores } from "../../stores/RootStore";
import { useAuthStore } from "../../../../../../auth/store/AuthStore";
import ChassiAOPreFilter from "../../../../../../components/PreFilter/containers/ChassiAOPreFilter";
import FactoryOrderPreFilter from "../../../../../../components/PreFilter/containers/FactoryOrderPreFilter";
import QuotationIDPreFilter from "../../../../../../components/PreFilter/containers/QuotationIDPreFilter";
import QuotationReservationStatusPreFilter from "../../../../../../components/PreFilter/containers/QuotationReservationStatusPreFilter";
import QuotationStatusSASPreFilter from "../../../../../../components/PreFilter/containers/QuotationStatusSASPreFilter";
import QuotationSalesmanACPreFilter from "../../../../../../components/PreFilter/containers/QuotationSalesmanACPreFilter";
import { ChassiType } from "../../../../../../constants/chassiAoOmFoConst";
import * as AuthGroups from "../../../../../../constants/AuthGroups/authGroups";
import { CountryRegionIso } from "../../../../../../enums/CountryRegionIso";
import { isChileQuotation } from "../../utils/StoreUtils";

export type QuotationPreFiltersProps = {
	groupsQuotationManager: Array<AuthGroups.AuthGroupsType>;
	region: CountryRegionIso;
}

const QuotationPreFilters: React.FC<QuotationPreFiltersProps> = ({ groupsQuotationManager, region }) => {
	const { t } = useTranslation(["quotation", "order"]);

	const {
		preFilterChassiStore,
		preFilterFactoryOrderStore,
		preFilterQuotationIDStore,
		preFilterSalesmanStore,
		preFilterQuotationDateStore,
		preFilterReservationStatusStore,
		preFilterStatusSASStore,
	} = useStores();

	const { reset } = useStores().listStore;
	const { setIsQuotationManagerUser } = useStores().listStore;
	const { isAuthorizedGroup } = useAuthStore();
	const {
		applyFilters,
		hasPreFiltersApplied,
		hasFormatError,
		filters,
		removeFilter,
		cleanSpecificPreFilters,
		onUndo,
	} = useStores().filterStore;
	const isQuotationManagerUser = setIsQuotationManagerUser(
		isAuthorizedGroup(groupsQuotationManager)
	);

	React.useEffect(() => {
		reset();

		return () => {
			cleanSpecificPreFilters();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (isQuotationManagerUser) {
			preFilterSalesmanStore.getSalesmanOptions();
		}
		preFilterReservationStatusStore.getOptions(t);
		preFilterStatusSASStore.getOptions(t);
	}, [t]);

	return (
		<PreFilter
			id="pre-filter-edition"
			title={t("preFilters.quickFilter", { ns: "order" })}
			onUndo={onUndo}
			onSubmit={applyFilters}
			hasPreFiltersApplied={hasPreFiltersApplied}
			hasFormatError={hasFormatError}
			filters={filters}
			chassiType={"chassi"}
			removeFilter={removeFilter}
		>
			<QuotationIDPreFilter preFilterStore={preFilterQuotationIDStore} />
			<QuotationDatePreFilter preFilterStore={preFilterQuotationDateStore} />
			<FactoryOrderPreFilter preFilterStore={preFilterFactoryOrderStore} />
			<ChassiAOPreFilter
				vehicleType={"Truck"}
				type={ChassiType.Chassi}
				preFilterStore={preFilterChassiStore}
				sm={6}
				md={5}
				lg={4}
				xl={4}
			/>
			<QuotationStatusSASPreFilter
				preFilterStore={preFilterStatusSASStore}
				xs={6}
			/>
			<QuotationReservationStatusPreFilter
				preFilterStore={preFilterReservationStatusStore}
				xs={6}
			/>
			{isChileQuotation(region) && isQuotationManagerUser && (
				<QuotationSalesmanACPreFilter
					preFilterStore={preFilterSalesmanStore}
					sm={6}
				/>
			)}
		</PreFilter>
	);
};

export default observer(QuotationPreFilters);
