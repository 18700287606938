import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";
import "./styles/titlebox.css";
export interface BreadcrumbsProps {
	title: string;
	href?: string;
	main: boolean;
	onClick?: () => void;
}
interface TitleBoxProps {
	pageTitle?: string;
	breadcrumbs: Array<BreadcrumbsProps>;
	children?: ReactNode;
}
export const TitleBox: React.FC<TitleBoxProps> = ({
	pageTitle,
	breadcrumbs,
	children,
}) => {
	pageTitle = pageTitle ?? breadcrumbs.find((f) => f.main)?.title ?? "";
	return (
		<Box sx={{ p: 2, pl: 0, flexGrow: 1 }}>
			<Helmet>
				<title>
					{process.env.REACT_APP_NAME_ABV + " | "} {pageTitle}
				</title>
			</Helmet>
			<div className="title-line-wrapper">
				<Breadcrumbs aria-label="breadcrumb">
					{breadcrumbs.map((breadcrumb) => (
						<div key={breadcrumb.title}>
							{breadcrumb.main ? (
								<Typography color="text.primary" variant="subtitle2">
									{breadcrumb.title}
								</Typography>
							) : (
								<Link
									underline="hover"
									color="inherit"
									href={breadcrumb.href}
									variant="subtitle2"
									onClick={breadcrumb.onClick}
								>
									{breadcrumb.title}
								</Link>
							)}
						</div>
					))}
				</Breadcrumbs>
				{children}
			</div>
		</Box>
	);
};
