import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../LoadingPage/LoadingPage";
import { BreadcrumbsProps, TitleBox } from "../../../../TitleBox/TitleBox";
import SaveBar from "../../../../SaveBar/SaveBar";
import NoContentResult from "../../../../msal/NoContentResult";
import GeneralAccordion from "./Accordions/GeneralAccordion";
import ProductionAccordion from "./Accordions/ProductionAccordion";
import InvoiceDeliveryAccordion from "./Accordions/InvoiceDeliveryAccordion";
import { EuropeStore } from "../../../../../pages/Order/Details/stores/EuropeStore";
import { FollowUpChlEuropeStore } from "../../../../../pages/FollowUpChl/Details/stores/FollowUpChlEuropeStore";
import LocalizationExportAccordion from "../../../Details/containers/Accordions/LocalizationExportAccordion";
import VehicleAccordion from "../../../Details/containers/Accordions/VehicleAccordion";

interface EuropeBaseIndexProps {
	breadcrumbs: Array<BreadcrumbsProps>;
	store: EuropeStore | FollowUpChlEuropeStore;
	menu?: string;
}

const EuropeBaseIndex: React.FC<EuropeBaseIndexProps> = ({
	breadcrumbs,
	store,
	menu,
}) => {
	const { t } = useTranslation(["ctadmven", "menu", "general"]);
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = React.useState(true);

	const {
		fields,
		setEditableFieldByKeyOnChange,
		hasChanges,
		loadAllDetails,
		update,
	} = store;

	const fetchDetails = React.useCallback(async () => {
		setLoading(true);
		await loadAllDetails(id ?? "", menu).finally(() => setLoading(false));
	}, [id, loadAllDetails, menu]);

	React.useEffect(() => {
		fetchDetails();
	}, [fetchDetails]);

	const handleSubmit = async () => {
		setLoading(true);
		await update(menu)
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err))
			.finally(() => fetchDetails());
	};

	return (
		<>
			<LoadingPage loading={loading} />
			{!loading && fields.factoryOrder && (
				<>
					<TitleBox breadcrumbs={breadcrumbs} />
					<Box p={1}>
						<Grid container spacing={2}>
							<Grid item xs>
								<Grid container spacing={2}>
									<GeneralAccordion
										generalDetails={{ readOnlyFields: { ...fields } }}
										customerDetails={{
											readOnlyFields: { ...fields },
											editableFields: { ...fields },
											setFieldByKeyOnChange: setEditableFieldByKeyOnChange,
											isChilean: fields.isChilean,
										}}
									/>
									<VehicleAccordion
										modelDetails={{ readOnlyFields: { ...fields } }}
										vehicleIdentificationDetails={{
											readOnlyFields: { ...fields },
										}}
										variantsDetails={{
											readOnlyFields: { ...fields },
											loadVariants: () => store.loadVariantsDetails(menu),
										}}
										vehicleType="TruckEurope"
									/>
									<ProductionAccordion
										productionDatesDetails={{
											readOnlyFields: { ...fields },
										}}
									/>
									<LocalizationExportAccordion
										localizationExportDetails={{
											fields: fields.localizationExport,
											loadLocalizationExport: () =>
												store.loadLocalizationDetails(menu),
										}}
									/>
									<InvoiceDeliveryAccordion
										exportationInvoiceProps={{
											readOnlyFields: { ...fields },
										}}
										exportationLocationProps={{
											readOnlyFields: { ...fields },
										}}
										invoicingDeliveryMarketCompanyProps={{
											fields: fields.invoicingDeliveryMarketCompany,
											loadInvoicingDeliveryMarketCompany: () =>
												store.loadInvoiceDeliveryDetails(menu),
										}}
										reservationExportDetails={{
											readOnlyFields: { ...fields },
										}}
									/>
								</Grid>
								<SaveBar
									onResetClick={() => fetchDetails()}
									resetLabel={t("undoChanges", { ns: "general" })}
									onSaveClick={() => handleSubmit()}
									saveLabel={t("saveChanges", { ns: "general" })}
									hasFormChanges={hasChanges}
								/>
							</Grid>
						</Grid>
					</Box>
				</>
			)}
			{!loading && !fields.factoryOrder && <NoContentResult />}
		</>
	);
};

export default observer(EuropeBaseIndex);
