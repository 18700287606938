import React from "react";
import { configure } from "mobx";
import CtadmvenApi from "../../../../../apis/CtadmvenApi";
import FilterStore from "./FilterStore";
import PreFilterQuotationIDStore from "../containers/PreFilters/stores/PreFilterQuotationIDStore";
import PreFilterQuotationDateStore from "../containers/PreFilters/stores/PreFilterQuotationDateStore";
import PreFilterStatusSASStore from "../containers/PreFilters/stores/PreFilterStatusSASStore";
import PreFilterFactoryOrderStore from "../containers/PreFilters/stores/PreFilterFactoryOrderStore";
import PreFilterChassiStore from "../containers/PreFilters/stores/PreFilterChassiStore";
import PreFilterSalesmanStore from "../containers/PreFilters/stores/PreFilterSalesmanStore";
import PreFilterReservationStatusStore from "../containers/PreFilters/stores/PreFilterReservationStatusStore";
import QuotationBatchEditionStore from "./QuotationBatchEditionStore";
import ListBaseStore from "./ListBaseStore";

configure({ enforceActions: "always" });

class RootStore {
	filterStore: any = {};
	listStore: any = {};
	preFilterQuotationIDStore: any = {};
	preFilterQuotationDateStore: any = {};
	preFilterStatusSASStore: any = {};
	preFilterFactoryOrderStore: any = {};
	preFilterChassiStore: any = {};
	preFilterSalesmanStore: any = {};
	preFilterReservationStatusStore: any = {};
	quotationBatchEditionStore: any = {};

	constructor() {
		const api = new CtadmvenApi();

		this.filterStore = new FilterStore(this);
		this.listStore = new ListBaseStore(this, api);
		this.preFilterQuotationIDStore = new PreFilterQuotationIDStore(this);
		this.preFilterQuotationDateStore = new PreFilterQuotationDateStore(this);
		this.preFilterStatusSASStore = new PreFilterStatusSASStore(this);
		this.preFilterFactoryOrderStore = new PreFilterFactoryOrderStore(this);
		this.preFilterChassiStore = new PreFilterChassiStore(this);
		this.preFilterSalesmanStore = new PreFilterSalesmanStore(this, api);
		this.preFilterReservationStatusStore = new PreFilterReservationStatusStore(this);
		this.quotationBatchEditionStore = new QuotationBatchEditionStore(this, api);
	}
}
const StoresContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(StoresContext);
export default RootStore;
