import { action, configure, makeObservable, observable } from "mobx";
import { getFilterObject } from "../../../../../../../utils/FilterOperations";
import BasePreFilterStringStore from "../../../../../../../components/PreFilter/Stores/BasePreFilterStringStore";
import { SelectOptionProps } from "../../../../../../../utils/GenericInterfaces";
import { getQuotationStatusSASOptions } from "../../../../../../../utils/SelectOptions";
import { TFunction } from "i18next";

configure({ enforceActions: "always" });

class PreFilterStatusSASStore extends BasePreFilterStringStore {
	@observable preFilterOptions: Array<SelectOptionProps> = [];

	constructor(rootStore: any) {
    super(rootStore);
    makeObservable(this);
  }

	getSpecificFilter(): any {
		return this.preFilter === ""
			? []
			: [[getFilterObject("statusSAS", this.preFilter, "eq")]];
	}

	@action hasPreFilter = () => this.preFilter !== "";

	@action getOptions = async (t: TFunction<"quotation", undefined>) => {
		this.preFilterOptions = getQuotationStatusSASOptions(t);
	};
}

export default PreFilterStatusSASStore;
