import React from "react";
import { configure, makeObservable } from "mobx";
import { QuotationDetailBaseStore } from "../../../Components/Details/store/QuotationDetailBaseStore";
import { getUpdateAllQuotationPaymentTermsModel } from "../../../Components/Details/model/UpdateAllQuotationPaymentTermsModel";
import { SendRejectedWorkflowProps } from "../../../Components/Details/interfaces/SendPriceAndPaymentProps";
import { sendPriceAndPaymentModelBuilder } from "../../../Components/Details/model/SendPriceAndPaymentModel";
import { QuotationVehicleInfo } from "../../../Components/Details/interfaces/QuotationVehicleInfo";
import { getCountryName } from "../../../Components/List/utils/StoreUtils";

configure({ enforceActions: "always" });

class QuotationDetailChileStore extends QuotationDetailBaseStore {
  constructor() {
    super();
    makeObservable(this);
  }

  getVehicleInfoByFactoryOrderEurope = async (
    factoryOrder: string
  ): Promise<QuotationVehicleInfo | null> => {
    let quotationVehicleInfo: QuotationVehicleInfo | null = null;

    await this.api.europeFactoryService
      .getChassiByFactoryOrder(factoryOrder, getCountryName(this.region))
      .then((response) => {
        quotationVehicleInfo = response.data;
      });

    return quotationVehicleInfo;
  };

  getVehicleInfoByChassiFromEurope = async (
    chassi: string
  ): Promise<QuotationVehicleInfo | null> => {
    let quotationVehicleInfo: QuotationVehicleInfo | null = null;

    await this.api.europeFactoryService
      .getFactoryOrderByChassi(chassi, getCountryName(this.region))
      .then((response) => {
        quotationVehicleInfo = response.data;
      });

    return quotationVehicleInfo;
  };

  updateReservations = async () => {
    return this.api.quotationService.updateReservations(
      this.editableDetails.reservations
    );

  };

  updateLocalCustomization = async () => {
    return this.api.quotationService.updateLocalCustomization(
      this.editableDetails.customizations
    );

  };

  updateManagerFields = async () => {
    return this.api.quotationService.updateManagerEditableFields(
      this.managerEditableDetails
    );

  };

  updatePaymentTerms = async () => {
    const allPaymentTerms = getUpdateAllQuotationPaymentTermsModel(
      this.editableDetails.paymentTerms,
      this.editableDetails.paymentTermRetoma
    );
    return this.api.paymentTermsService.updateQuotationPaymentTerms(
      allPaymentTerms
    );

  };

  private updatePriceAndPayment = async () => {
    const sendPriceAndPaymentProps: SendRejectedWorkflowProps =
      sendPriceAndPaymentModelBuilder(
        this.editableDetails,
        this.glosaTextEditableDetails
      );

    if (this.hasGeneralChanges) {
      sendPriceAndPaymentProps.reservations = this.editableDetails.reservations;
    }

    sendPriceAndPaymentProps.allPaymentTerms =
      getUpdateAllQuotationPaymentTermsModel(
        this.editableDetails.paymentTerms,
        this.editableDetails.paymentTermRetoma
      );

    if (this.hasGlosaTextChanges) {
      sendPriceAndPaymentProps.glosaText = this.glosaTextEditableDetails;
    }

    return this.api.quotationService.updatePriceAndPayment(
      sendPriceAndPaymentProps
    );

  };

  updateSalesmanFields = async () => {
    return this.api.quotationService.updateSalesmanEditableFields(
      this.salesmanEditableDetails
    );

  };

  updateGlosaText = async () => {
    return this.api.quotationService.updateGlosaText(
      this.glosaTextEditableDetails
    );
  };

  updateGlosaFields = async () => {
    return this.api.quotationService.updateGlosaFields(
      this.glosaFieldsEditableDetails
    );
  };

  updateAdelanto = async () => {
    return this.api.quotationService.updateAdelanto(
      this.readOnlyDetails.id
    );
  };

  update = async (isAllowedToEditReservation: boolean) => {
    const promises: Array<any> = [];

    if (!this.isWorkflowRejected()) {
      await this.getWorkflowChanges(promises, isAllowedToEditReservation);
    } else {
      if (
        this.hasNotWorkflowChanges()
      ) {
        promises.push(await this.updatePriceAndPayment());
      }
      if (this.hasGlosaFieldsChanges) {
        promises.push(await this.updateGlosaFields());
      }
      if (this.hasSalesmanChanges) {
        promises.push(await this.updateSalesmanFields());
      }
      if (this.hasManagerChanges) {
        promises.push(await this.updateManagerFields());
      }
    }

    if (promises.length > 0) {
      await Promise.all(promises);
    }
  };

  private hasNotWorkflowChanges() {
    return this.hasPriceAndPaymentChanges ||
      this.hasGeneralChanges ||
      this.hasPaymentTermsChanges ||
      this.hasPaymentTermRetomaChanges ||
      this.hasGlosaTextChanges;
  }

  private async getWorkflowChanges(promises: any[], isAllowedToEditReservation: boolean) {
    if (this.hasSalesmanChanges) {
      promises.push(await this.updateSalesmanFields());
    }

    if (this.hasManagerChanges) {
      promises.push(await this.updateManagerFields());
    }

    await this.getGeneralChanges(isAllowedToEditReservation, promises);

    if (this.hasPaymentTermsChanges || this.hasPaymentTermRetomaChanges) {
      promises.push(await this.updatePaymentTerms());
    }

    await this.setupGlosaChanges(promises);
  }

  private async setupGlosaChanges(promises: any[]) {
    if (this.hasGlosaFieldsChanges &&
      !this.glosaFieldsEditableDetails.approved &&
      this.hasGlosaTextChanges) {
      promises.push(await this.updateGlosaFields());
      promises.push(await this.updateGlosaText());
    } else if (this.hasGlosaTextChanges && this.hasGlosaFieldsChanges) {
      promises.push(await this.updateGlosaText());
      promises.push(await this.updateGlosaFields());
    } else if (this.hasGlosaTextChanges) {
      promises.push(await this.updateGlosaText());
    } else if (this.hasGlosaFieldsChanges) {
      promises.push(await this.updateGlosaFields());
    }
  }

  private async getGeneralChanges(isAllowedToEditReservation: boolean, promises: any[]) {
    if (this.hasGeneralChanges) {
      if (isAllowedToEditReservation) {
        promises.push(await this.updateReservations());
      }

      promises.push(await this.updateLocalCustomization());
    }
  }
}

const QuotationDetailStoreContext = React.createContext(
  new QuotationDetailChileStore()
);

export const useQuotationDetailChileStore = () =>
  React.useContext(QuotationDetailStoreContext);
