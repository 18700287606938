import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../AccordionCard/AccordionCard";
import ForecastBillingSection from "../Sections/ForecastBillingSection";
import { AvailabilityInvoicingDeliveryAccordionProps } from "../../interfaces/Accordions/AvailabilityInvoicingDeliveryAccordion";

const AvailabilityInvoicingDeliveryAccordion: React.FC<
	AvailabilityInvoicingDeliveryAccordionProps
> = (details) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="invoicingAndDeliveryAccordion"
			title={t("details.accordions.availabilityInvoicingDelivery")}
		>
			<ForecastBillingSection {...details.forecastBillingDetails} />
		</AccordionCard>
	);
};

export default observer(AvailabilityInvoicingDeliveryAccordion);
