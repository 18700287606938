import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import SelectForm from "../../../../../../components/FormInputs/SelectForm";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { PaymentTermsSectionProps } from "../../interfaces/Sections/PaymentTermsSection";
import { SelectOptionProps } from "../../../../../../utils/GenericInterfaces";
import { getLabel } from "../../../../../../utils/PaymentTermsUtil";
import { PaymentTermContainerComponent } from "./Component/PaymentTermContainerComponent";
import { hasMinValueRequiredValidator } from "../../../../../../utils/FormValidators";

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: 300,
			width: 250,
		},
	},
};

const PaymentTermsSection: React.FC<PaymentTermsSectionProps> = ({
	editable,
	store
}) => {
	const { t } = useTranslation(["quotation", "general"]);

	const {
		setPaymentTerms,
		paymentTermsOptions,
		editableDetails,
		addNewPaymentTerm,
		removePaymentTerm,
	} = store();

	const { paymentTerms } = editableDetails;

	const valueInClpValidator = (value?: number) =>
		!hasMinValueRequiredValidator(value, 1)
			? t("validator.minNumber", { min: 1, ns: "general" })
			: "";

	const formValidators = (value: any, key?: string) => {
		if (key === "valueInClp") return valueInClpValidator(value);
		return "";
	};

	const { i18n } = useTranslation();
	const language = Boolean(i18n.language) ? i18n.language : "en-US";

	const paymentTermsSelectOptions = paymentTermsOptions.map(
		(m: any) =>
			({
				label: getLabel(m, language),
				value: m.id,
			}) as SelectOptionProps
	);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.paymentTerms.title")}
			</Grid>
			{paymentTerms &&
				paymentTerms.map((paymentTerm, i) => (
					<PaymentTermContainerComponent key={i}>
						<Grid item justifyContent="end" alignItems="end">
							{editable && (
								<Tooltip
									title={`${t("delete", { ns: "general" })}`}
									arrow
									placement="left"
								>
									<IconButton
										color="error"
										size="large"
										onClick={() => removePaymentTerm(paymentTerm)}
									>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							)}
						</Grid>
						<Grid item xs={4}>
							<SelectForm
								disabled={!editable}
								label={t("details.sections.paymentTerms.type")}
								labelId={"details.sections.paymentTerms.type"}
								value={paymentTerm.id}
								options={paymentTermsSelectOptions}
								onChange={(e) => setPaymentTerms("id", i, e.target.value, "")}
								inputProps={{ shrink: "true" }}
								hasEditIcon
								MenuProps={MenuProps}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextForm
								label={t("details.sections.paymentTerms.valueInClp")}
								value={paymentTerm.value}
								onChange={(
									e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
									_?: boolean,
									error?: string
								) => setPaymentTerms("value", i, e.target.value, error ?? "")}
								type="number"
								isCurrency
								inputProps={{ min: 1 }}
								name="valueInClp"
								editable={editable}
								validator={(value, key) => formValidators(value, key)}
							/>
						</Grid>
					</PaymentTermContainerComponent>
				))}
			<Grid container direction="row" justifyContent="end" alignItems="end">
				<Button
					variant="contained"
					color="secondary"
					size="small"
					disabled={!editable}
					onClick={() => addNewPaymentTerm()}
				>
					<AddIcon /> {t("addRegister", { ns: "general" })}
				</Button>
			</Grid>
		</>
	);
};

export default observer(PaymentTermsSection);
