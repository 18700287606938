import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
	Alert,
	Button,
	DialogActions,
	DialogContent,
	Stack,
	Tooltip,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";
import DialogForm from "../../../../../components/Dialog/DialogForm";
import CancelButton from "../../../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../../../components/Dialog/Buttons/ApplyButton";
import { useStores } from "../stores/RootStore";
import SwitchForm from "../../../../../components/FormInputs/SwitchForm";
import { toast } from "react-toastify";
import { CSV_DISABLED_QUOTATIONS_CHL, CSV_DISABLED_QUOTATIONS_ARG } from "../../../../../constants/csvButtonStorage";
import checkButtonTimer from "../../../../../utils/CheckCsvTimer";
import { isChileQuotation } from "../utils/StoreUtils";
const HomePageCsvExport: React.FC = () => {
	const { t } = useTranslation("general");
	const { i18n } = useTranslation();
	const { csvExport, loading, setIncludeVariants, includeVariants, region } =
		useStores().listStore;

	const [open, setOpen] = React.useState(false);

	const handleSubmit = () => {
		const culture = i18n.language;
		setOpen(false);
		csvExport(culture);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		if ((isChileQuotation(region) && checkButtonTimer(CSV_DISABLED_QUOTATIONS_CHL))
			|| (!isChileQuotation(region) && checkButtonTimer(CSV_DISABLED_QUOTATIONS_ARG))) {
			toast.warning(t("csvExportDownloadTimeWarning"));
			return;
		}
		setIncludeVariants(false);
		setOpen(true);
	};

	return (
		<>
			<LoadingPage loading={loading} />
			<Tooltip title={`${t("csvExport")}`} arrow>
				<Button onClick={handleOpen} color="secondary">
					<FileDownloadIcon fontSize="medium" />
				</Button>
			</Tooltip>
			<DialogForm
				id="dialog-csv-export"
				open={open}
				title={t("csvQuotationExportDialogTitle")}
				onClose={handleClose}
				onEnterSubmit={handleSubmit}
			>
				<DialogContent dividers>
					<Stack>
						<SwitchForm
							editable
							label={`${t("include")} ${t("variants")}`}
							checked={includeVariants}
							onChange={(e) => setIncludeVariants(e.target.checked)}
						/>
						<Alert severity="warning">{t("csvExportDialogTimeWarning")}</Alert>
					</Stack>
				</DialogContent>
				<DialogActions>
					<CancelButton
						id="dialog-csv-export"
						onCancel={handleClose}
						title={t("cancel")}
					/>
					<ApplyButton
						id="dialog-csv-export"
						onSubmit={handleSubmit}
						title={t("confirm")}
						startIcon={<FileDownloadIcon />}
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(HomePageCsvExport);
