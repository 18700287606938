import { Box } from "@mui/material";
import { CountryRegionIso } from "../../../../../enums/CountryRegionIso";
import { QuotationDetailBaseStore } from "../store/QuotationDetailBaseStore";
import { TitleBox } from "../../../../../components/TitleBox/TitleBox";
import { isChileQuotation } from "../../List/utils/StoreUtils";
import QuotationApproveButton from "./QuotationApproveButton";
import QuotationRestoreCancelButton from "./QuotationRestoreCancelButton";
import QuotationGoToSharePointButton from "./QuotationGoToSharePointButton";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { AuthGroupsType } from "../../../../../constants/AuthGroups/authGroups";
import { QuotationSalesmanEditableDetails } from "../interfaces/QuotationSalesmanEditableDetails";
import { QuotationManagerEditableDetails } from "../interfaces/QuotationManagerEditableDetails";
import { observer } from "mobx-react";

export interface QuotationDetailTitleProps {
  groupsAllowedToSendToWorkflowApproval: Array<AuthGroupsType>;
  region: CountryRegionIso;
  store: () => QuotationDetailBaseStore;
  fetchDetails: () => Promise<void>;
  baseEditableFields: QuotationSalesmanEditableDetails | QuotationManagerEditableDetails;
}

const QuotationDetailTitle: React.FC<QuotationDetailTitleProps> = ({ groupsAllowedToSendToWorkflowApproval,
  region, store, fetchDetails, baseEditableFields }) => {

  const { t } = useTranslation(["menu"]);
  const { isAuthorizedGroup } = useAuthStore();
  const userIsAllowedToSendToWorkflowApproval = isAuthorizedGroup(groupsAllowedToSendToWorkflowApproval);
  const {
    readOnlyDetails,
    isCanceledQuotation,
  } = store();

  const breadcrumbs = [
    {
      title: t("home", { ns: "menu" }),
      href: "/",
      main: false,
    },
    {
      title: t("quotation.title", { ns: "menu" }),
      href: "#/quotation",
      main: false,
    },
    {
      title: t("quotation.details", { ns: "menu" }),
      main: true,
    },
  ];

  return (
    <Box
      p={1}
      display="flex"
      justifyContent="flex-end"
      alignItems="flex-end"
      gap="1em"
    >
      <TitleBox breadcrumbs={breadcrumbs} />
      {isChileQuotation(region) && userIsAllowedToSendToWorkflowApproval &&
        readOnlyDetails.isPermittedToSendWorkflowToApproval && (
          <QuotationApproveButton
            fetchDetails={fetchDetails}
            baseEditableFields={baseEditableFields}
            store={store}
          />
        )}
      {isChileQuotation(region) && (readOnlyDetails.isPermittedToCancel || isCanceledQuotation()) && (
        <QuotationRestoreCancelButton fetchDetails={fetchDetails} store={store} />
      )}
      {isChileQuotation(region) && <QuotationGoToSharePointButton store={store} />}
    </Box>
  );
};

export default observer(QuotationDetailTitle);