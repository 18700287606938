import React from "react";
import { observer } from "mobx-react";
import { IColumnProps } from "../../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../../components/TitleBox/TitleBox";
import { Box, Grid } from "@mui/material";
import HomePageGrid from "./QuotationGrid";
import { getQuotationGridColumns } from "../utils/GridUtils";
import { useStores } from "../stores/RootStore";
import * as AuthGroups from "../../../../../constants/AuthGroups/authGroups";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import QuotationEditionModal from "./QuotationEditionModal";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";
import {
	getQuotationReservationStatusOptions,
	getQuotationStatusSASOptions,
	getQuotationWorkflowStatusOptions,
} from "../../../../../utils/SelectOptions";
import HomePageCsvExport from "./HomePageCsvExport";
import QuotationPreFilters from "./PreFilters/QuotationPreFilters";
import { CountryRegionIso } from "../../../../../enums/CountryRegionIso";
import { isArgentinaQuotation, isChileQuotation } from "../utils/StoreUtils";

export type QuotationIndexProps = {
	groupsToExportQuotationCsv: Array<AuthGroups.AuthGroupsType>;
	groupsQuotationManager: Array<AuthGroups.AuthGroupsType>;
	groupsAllowedToSeeQuotationMenu: Array<AuthGroups.AuthGroupsType>;
	region: CountryRegionIso;
}

const QuotationIndex: React.FC<QuotationIndexProps> = ({ groupsToExportQuotationCsv, groupsQuotationManager, groupsAllowedToSeeQuotationMenu, region }) => {
	const { t } = useTranslation(["quotation", "menu"]);
	const listStore = useStores().listStore;
	listStore.setRegion(region);
	const { reset } = listStore;
	const { getSalesmanOptions } = useStores().preFilterSalesmanStore;
	const { setIsQuotationManagerUser, loading } = listStore;
	const { isAuthorizedGroup } = useAuthStore();
	const isAllowedToExportCsv = isAuthorizedGroup(groupsToExportQuotationCsv);

	const isQuotationManagerUser = setIsQuotationManagerUser(
		isAuthorizedGroup(groupsQuotationManager)
	);

	const canUserSeeBatchEdition = isAuthorizedGroup(
		groupsAllowedToSeeQuotationMenu
	);

	const [columns, setColumns] = React.useState<Array<IColumnProps> | undefined>(
		[]
	);

	const statusSASOptions = getQuotationStatusSASOptions(t);
	const reservationStatusOptions = getQuotationReservationStatusOptions(t);
	const workflowStatusOptions = getQuotationWorkflowStatusOptions(t);

	React.useEffect(() => {
		reset();
		if (isQuotationManagerUser) {
			getSalesmanOptions();
		}
		const gridColumns = getQuotationGridColumns(
			t,
			statusSASOptions,
			reservationStatusOptions,
			workflowStatusOptions,
			isChileQuotation(region) || isArgentinaQuotation(region)
		);
		setColumns(gridColumns);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("quotation.title", { ns: "menu" })}`,
			main: true,
		},
	];

	return (
		<Grid container spacing={2}>
			<Grid item lg={12}>
				<Box sx={{ display: "flex" }}>
					<TitleBox breadcrumbs={breadcrumbs} />
					{isAllowedToExportCsv && <HomePageCsvExport />}
				</Box>
			</Grid>
			{isChileQuotation(region) && canUserSeeBatchEdition && <QuotationEditionModal groupsQuotationManager={groupsQuotationManager} />}
			<QuotationPreFilters region={region} groupsQuotationManager={groupsQuotationManager} />
			<Grid item lg={12}>
				<LoadingPage loading={loading} />
				{columns && <HomePageGrid columns={columns} />}
			</Grid>
		</Grid>
	);
};

export default observer(QuotationIndex);
