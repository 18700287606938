import { GUID_EMPTY } from "../../../../../app/constants/config";
import { GlosaFieldsEditableProps } from "../interfaces/GlosaFieldsEditableProps";

export const glosaFieldsEditableModel: GlosaFieldsEditableProps = {
	id: GUID_EMPTY,
	approved: false,
	bodyBuilderType: "",
};

export function glosaFieldsEditableModelBuilder(
	data: any,
	id: string
): GlosaFieldsEditableProps {
	if (!data) return glosaFieldsEditableModel;

	return {
		id: id ?? GUID_EMPTY,
		approved: data.approved,
		modelYear: data.modelYear,
		vehicleWeight: data.vehicleWeight,
		loadWeight: data.loadWeight,
		bodyBuilderType: data.bodyBuilderType ?? "",
	};
}
