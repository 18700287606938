import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { getMenuKeyAndBreadcrumbs } from "../../../../constants/Menus/OrderMenuUtils";
import EuropeBaseIndex from "../../../../components/OrderPages/Europe/DetailsBase/containers/EuropeBaseIndex";
import { useEuropeStore } from "../stores/EuropeStore";

const EuropeIndex: React.FC = () => {
	const { t } = useTranslation("menu");
	const menuKeyAndBreadcrumbs = getMenuKeyAndBreadcrumbs(t);
	const store = useEuropeStore();
	return (
		<EuropeBaseIndex
			breadcrumbs={menuKeyAndBreadcrumbs.breadcrumbs}
			store={store}
			menu={menuKeyAndBreadcrumbs.menuKey}
		/>
	);
};

export default observer(EuropeIndex);
