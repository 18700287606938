import { VariantProps } from "../components/OrderPages/Details/interfaces/Sections/VariantDetails";

export const getLabel = (variant: VariantProps, language: string) => {
	switch (language) {
		case "pt-BR":
			return !variant.descriptionPtBr
				? variant.descriptionEnUs
				: variant.descriptionPtBr;
		case "es-ES":
			return !variant.descriptionEsEs
				? variant.descriptionEnUs
				: variant.descriptionEsEs;
		default:
			return variant.descriptionEnUs;
	}
};
