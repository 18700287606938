import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../FormInputs/TextForm";
import DateForm from "../../../../../FormInputs/DateForm";
import RadioGroupForm from "../../../../../FormInputs/RadioGroupForm";
import { getDeliveryProbabilityOptions } from "../../../../../../utils/RadioGroupOptions";
import { MktCompanyDeliverySectionProps } from "../../interfaces/Sections/MktCompanyDeliverySection";
import { propExistsInArray } from "../../../../../../utils/ArrayUtils";

const MktCompanyDeliverySection: React.FC<MktCompanyDeliverySectionProps> = ({
	userIsOwner,
	fieldsAvailableForEdition,
	fields,
	setMCDeliveryFieldByKeyOnChange,
}) => {
	const { t } = useTranslation(["order", "general"]);

	const deliveryProbabilityOptions = getDeliveryProbabilityOptions(t);

	const getDateAndUserFields = (
		name:
			| "firmBilling"
			| "financialDelivery"
			| "physicalDelivery"
			| "finalDelivery",
		editable: boolean
	) => {
		const userField = (name + "User") as
			| "firmBillingUser"
			| "financialDeliveryUser"
			| "physicalDeliveryUser"
			| "finalDeliveryUser";

		const isEditable =
			editable && propExistsInArray(name, fieldsAvailableForEdition);
		const userFieldIsVisible = userIsOwner || isEditable;
		return (
			<>
				<Grid item xs={4}>
					<DateForm
						label={t(`details.sections.mktCompanyDelivery.${name}Date`)}
						value={fields[name]}
						editable={isEditable}
						inputFormat="dd/MM/yy"
						onChange={(e: any) =>
							setMCDeliveryFieldByKeyOnChange &&
							setMCDeliveryFieldByKeyOnChange(name, e)
						}
					/>
				</Grid>
				{userFieldIsVisible ? (
					<Grid item xs={8}>
						<TextForm
							label={t(`details.sections.mktCompanyDelivery.${userField}`)}
							value={fields[userField]}
							inputProps={{ maxLength: 255 }}
						/>
					</Grid>
				) : (
					<Grid item xs={8}></Grid>
				)}
			</>
		);
	};

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.mktCompanyDelivery.title")}
			</Grid>
			<Grid item xs={3}>
				<RadioGroupForm
					editable={propExistsInArray(
						"deliveryProbability",
						fieldsAvailableForEdition
					)}
					label={t("details.sections.mktCompanyDelivery.deliveryProbability")}
					labelId={"details.sections.mktCompanyDelivery.deliveryProbability"}
					items={deliveryProbabilityOptions}
					value={fields.deliveryProbability}
					onChange={(dataItem: any) =>
						setMCDeliveryFieldByKeyOnChange &&
						setMCDeliveryFieldByKeyOnChange(
							"deliveryProbability",
							dataItem.target.value
						)
					}
				/>
			</Grid>
			<Grid container item xs={9} spacing={1}>
				{getDateAndUserFields("firmBilling", true)}
				{getDateAndUserFields("financialDelivery", true)}
				{getDateAndUserFields("physicalDelivery", true)}
				{getDateAndUserFields("finalDelivery", false)}
			</Grid>
		</>
	);
};

export default observer(MktCompanyDeliverySection);
