import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { ModelSectionProps } from "../../interfaces/Sections/ModelSection";
import TextForm from "../../../../FormInputs/TextForm";
import BaseModelSection from "./BaseModelSection";

const ModelSection: React.FC<ModelSectionProps> = ({ readOnlyFields }) => {
	const { t } = useTranslation("order");

	return (
		<>
			<BaseModelSection fields={readOnlyFields} />
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.model.commercialTrimmingPackages")}
					value={readOnlyFields.commercialTrimmingPackages}
				/>
			</Grid>
		</>
	);
};

export default observer(ModelSection);
