import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";
import NoContentResult from "../../../../../components/msal/NoContentResult";
import GeneralAccordion from "./Accordions/GeneralAccordion";
import VehicleAccordion from "./Accordions/VehicleAccordion";
import QuotationCommentsAccordion from "./Accordions/QuotationCommentsAccordion";
import SaveBar from "../../../../../components/SaveBar/SaveBar";
import VehicleCustomizationAccordion from "./Accordions/VehicleCustomizationAccordion";
import PriceAndPaymentAccordion from "./Accordions/PriceAndPaymentAccordion";
import TruckReservationAccordion from "./Accordions/TruckReservationAccordion";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { AuthGroupsType } from "../../../../../constants/AuthGroups/authGroups";
import { CountryRegionIso } from "../../../../../enums/CountryRegionIso";
import { isChileQuotation } from "../../List/utils/StoreUtils";
import { QuotationDetailBaseStore } from "../store/QuotationDetailBaseStore";
import QuotationDetailTitle from "./QuotationDetailTitle";

export type QuotationDetailIndexProps = {
  groupsAllowedToEditQuotationReservation: Array<AuthGroupsType>;
  groupsAllowedToSendToWorkflowApproval: Array<AuthGroupsType>;
  groupsQuotationManager: Array<AuthGroupsType>;
  groupsQuotationSalesman: Array<AuthGroupsType>;
  region: CountryRegionIso;
  store: () => QuotationDetailBaseStore;
}

const QuotationDetailIndex: React.FC<QuotationDetailIndexProps> = ({ groupsAllowedToEditQuotationReservation, groupsAllowedToSendToWorkflowApproval,
  groupsQuotationManager, groupsQuotationSalesman, region, store
}) => {
  const { t } = useTranslation(["ctadmven", "menu", "general", "quotation"]);
  const { id } = useParams<{ id: string }>();
  const { isAuthorizedGroup } = useAuthStore();
  const isMarketCompanyUser = isAuthorizedGroup(groupsAllowedToEditQuotationReservation);
  const isQuotationManagerUser = isAuthorizedGroup(groupsQuotationManager);
  const isAllowedToEditReservation = isAuthorizedGroup(groupsAllowedToEditQuotationReservation);

  const {
    readOnlyDetails,
    loadDetails,
    editableDetails,
    managerEditableDetails,
    setLocalCustomizationComment,
    setQuotationManagerEditableField,
    salesmanOptions,
    hasChanges,
    update,
    hasErrors,
    loading,
    setLoading,
    salesmanEditableDetails,
    setQuotationSalesmanEditableField,
    financialCustomerOptions,
    loadFinancialCustomer,
    setQuotationBaseEditableField,
    paymentTermsOptions,
    isFinancialCustomer,
    setIsFinancialCustomer,
    setRegion,
    canEditCustomerDetails,
    canEditAndIsNotRejected,
    isRejectedQuotation
  } = store();
  setRegion(region);
  const baseEditableFields = isQuotationManagerUser ? { ...managerEditableDetails } : { ...salesmanEditableDetails };
  const fetchDetails = React.useCallback(async () => {
    setLoading(true);
    await loadDetails(id ?? "", isQuotationManagerUser).finally(() => setLoading(false));
  }, [id, isQuotationManagerUser, loadDetails, setLoading]);

  const handleSubmit = async () => {
    if (
      editableDetails.paymentTerms.some(
        (x) => x.id === paymentTermsOptions.filter((y) => y.descriptionEnUs === "LEASING")[0].id
      ) && !baseEditableFields.financialCustomerCode
    ) {
      if (canEditCustomerDetails()) {
        toast.warning(t("paymentTermLeasingRequiresFinancialInstitution", { ns: "quotation", }).toString());
        setIsFinancialCustomer(true);
      } else {
        toast.warning(t("currentQuotationStatusDontAllowThisChange", { ns: "quotation", }).toString());
      }

      return;
    }

    setLoading(true);
    await update(isAllowedToEditReservation)
      .then(() => toast.success(t("editRecordWithSuccess", { ns: "general" }).toString()))
      .catch((err: any) => toast.error(err.response.data))
      .finally(() => fetchDetails());
  };

  React.useEffect(() => { fetchDetails(); }, [fetchDetails]);

  return (
    <>
      <LoadingPage loading={loading} />
      {!loading && readOnlyDetails.quotationId && (
        <>
          <QuotationDetailTitle baseEditableFields={baseEditableFields} fetchDetails={fetchDetails}
            groupsAllowedToSendToWorkflowApproval={groupsAllowedToSendToWorkflowApproval} region={region}
            store={store} />
          <Box p={1}>
            <Grid container spacing={2}>
              <Grid item xs mb={25}>
                <Grid container spacing={2}>
                  <GeneralAccordion
                    generalDetails={{
                      readOnlyFields: { ...readOnlyDetails },
                      editable: canEditAndIsNotRejected() && isQuotationManagerUser,
                      managerEditableFields: { ...managerEditableDetails },
                      salesmanOptions,
                      setQuotationManagerEditableField,
                      store
                    }}
                    customerDetails={{
                      readOnlyFields: { ...readOnlyDetails },
                      editable: canEditCustomerDetails(),
                      isQuotationManagerUser,
                      baseEditableFields: { ...baseEditableFields },
                      setQuotationSalesmanEditableField,
                      setQuotationBaseEditableField,
                      financialCustomerOptions,
                      loadFinancialCustomer,
                      isFinancialCustomer,
                      setIsFinancialCustomer,
                    }}
                  />
                  <TruckReservationAccordion
                    truckReservationDetails={{
                      readOnlyFields: { ...readOnlyDetails },
                    }}
                    reservationSectionProps={{
                      editable:
                        isMarketCompanyUser && canEditCustomerDetails(),
                      store
                    }}
                    adelantoSectionProps={{
                      fetchDetails,
                      isAllowedToEditReservation,
                      editable: canEditCustomerDetails(),
                      store
                    }}
                    adelanto={readOnlyDetails.adelanto}
                  />
                  <VehicleAccordion
                    modelDetails={{ readOnlyFields: { ...readOnlyDetails } }}
                    variantsDetails={{ readOnlyFields: { ...readOnlyDetails } }}
                  />
                  <VehicleCustomizationAccordion
                    factoryOptionalDetails={{
                      readOnlyFields: { ...readOnlyDetails },
                    }}
                    customizationsDetails={{
                      editableFields: editableDetails?.customizations,
                      setLocalCustomizationComment,
                      editable: canEditAndIsNotRejected(),
                      currency: readOnlyDetails.currency,
                      currentExchangeRate: readOnlyDetails.currentExchangeRate,
                      region
                    }}
                    region={region}
                  />
                  <PriceAndPaymentAccordion
                    priceAndPaymentDetails={{
                      editable: isChileQuotation(region) && isRejectedQuotation(),
                      region,
                      store
                    }}
                    paymentTermsSectionProps={{
                      editable: canEditCustomerDetails(),
                      store
                    }}
                    glosaSectionProps={{
                      handleSubmit,
                      editable: canEditCustomerDetails(),
                      store
                    }}
                    paymentTermRetomaSectionProps={{
                      editable: canEditCustomerDetails(),
                      store
                    }}
                  />
                  <QuotationCommentsAccordion
                    finalDetailDetails={{
                      readOnlyFields: { ...readOnlyDetails },
                    }}
                  />
                </Grid>
              </Grid>
              <SaveBar
                onResetClick={() => fetchDetails()}
                resetLabel={t("undoChanges", { ns: "general" })}
                onSaveClick={() => handleSubmit()}
                saveLabel={t("saveChanges", { ns: "general" })}
                hasFormChanges={hasChanges}
                disabled={!isChileQuotation(region) && hasErrors}
              />
            </Grid>
          </Box>
        </>
      )}
      {!loading && !readOnlyDetails.quotationId && <NoContentResult />}
    </>
  );
};

export default observer(QuotationDetailIndex);