import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { getBreadcrumbs } from "./Utils/BreadcrumbsUtils";
import EuropeBaseIndex from "../../../../components/OrderPages/Europe/DetailsBase/containers/EuropeBaseIndex";
import { useFollowUpChlEuropeStore } from "../stores/FollowUpChlEuropeStore";

const EuropeIndex: React.FC = () => {
	const { t } = useTranslation("menu");
	const breadcrumbs = getBreadcrumbs(t);
	const store = useFollowUpChlEuropeStore();
	return <EuropeBaseIndex breadcrumbs={breadcrumbs} store={store} />;
};

export default observer(EuropeIndex);
