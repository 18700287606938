import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import CtadmvenApi from "../../apis/CtadmvenApi";
import { ReservationsProps } from "../../pages/Quotations/Components/Details/interfaces/ReservationsProps";
import { LocalCustomizationProps } from "../../pages/Quotations/Components/Details/interfaces/LocalCustomizationProps";
import { QuotationManagerEditableDetails } from "../../pages/Quotations/Components/Details/interfaces/QuotationManagerEditableDetails";
import { QuotationRestoreOrCancelOptions } from "../../pages/Quotations/Components/Details/interfaces/QuotationRestoreOrCancelOptions";
import { QuotationEditableDetails } from "../../pages/Quotations/Components/Details/interfaces/QuotationEditableDetails";
import { QuotationSalesmanEditableDetails } from "../../pages/Quotations/Components/Details/interfaces/QuotationSalesmanEditableDetails";
import { GlosaTextEditableProps } from "../../pages/Quotations/Components/Details/interfaces/GlosaTextEditableProps";
import { GlosaFieldsEditableProps } from "../../pages/Quotations/Components/Details/interfaces/GlosaFieldsEditableProps";
import { SendRejectedWorkflowProps } from "../../pages/Quotations/Components/Details/interfaces/SendPriceAndPaymentProps";
import { CountryRegionIso } from "../../enums/CountryRegionIso";
import { CsvOptionsForQuotationProps } from "../../pages/Quotations/interfaces/CsvOptionsProps";

interface IQuotationService {
	getQuotations(region: CountryRegionIso, dataState: State): Promise<any>;
	getListForSalesmanQuotations(dataState: State): Promise<any>;
	getDetails(id: string, region: CountryRegionIso): Promise<any>;
	getSalesmanFilterOptions(): Promise<any>;
	updateReservations(reservations: ReservationsProps[]): Promise<any>;
	updateLocalCustomization(
		localCustomization: LocalCustomizationProps[]
	): Promise<any>;
	updateEditableFields(editableFields: QuotationEditableDetails): Promise<any>;
	restoreOrCancelQuotation(
		quotationRestoreOrCancelOptions: QuotationRestoreOrCancelOptions
	): Promise<any>;
	updateBatch(dataState: State, quotationDetails: any): Promise<any>;
	updateSalesmanEditableFields: (
		editableFields: QuotationSalesmanEditableDetails
	) => Promise<any>;
	updateGlosaText: (glosa: GlosaTextEditableProps) => Promise<any>;
	updateGlosaFields: (glosaFields: GlosaFieldsEditableProps) => Promise<any>;
	updateAdelanto: (id: string) => Promise<any>;
	sendToWorkflowApproval: (id: string) => Promise<any>;
	updatePriceAndPayment: (
		editableFields: SendRejectedWorkflowProps
	) => Promise<any>;
	csvExport(dataState: State, csvOptions: CsvOptionsForQuotationProps): Promise<any>;
	csvExportForSalesman(
		dataState: State,
		csvOptions: CsvOptionsForQuotationProps
	): Promise<any>;
}
const base = "/Quotation";

export class QuotationService implements IQuotationService {
	constructor(private api: CtadmvenApi) {}

	getQuotations = async (region: CountryRegionIso, dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}?region=${region}&${toDataSourceRequestString(dataState)}`
		);

	getListForSalesmanQuotations = async (dataState: State): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/ListForSalesman?${toDataSourceRequestString(dataState)}`
		);

	getDetails = async (id: string, region: CountryRegionIso): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/${region}/${id}`);

	getSalesmanFilterOptions = async (): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/filterSalesman`);

	updateReservations = async (
		reservations: ReservationsProps[]
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/reservation`,
			JSON.stringify(reservations)
		);

	updateEditableFields = async (
		editableFields: QuotationEditableDetails
	): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}`, JSON.stringify(editableFields));

	updateLocalCustomization = async (
		localCustomization: LocalCustomizationProps[]
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/customization`,
			JSON.stringify(localCustomization)
		);

	updateManagerEditableFields = async (
		editableFields: QuotationManagerEditableDetails
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateManagerExclusive`,
			JSON.stringify(editableFields)
		);

	restoreOrCancelQuotation = async (
		quotationRestoreOrCancelOptions: QuotationRestoreOrCancelOptions
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/restoreOrcancel`,
			JSON.stringify(quotationRestoreOrCancelOptions)
		);

	updateBatch = async (dataState: State, quotationDetails: any): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/batch?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(quotationDetails)
		);

	updateSalesmanEditableFields = async (
		editableFields: QuotationSalesmanEditableDetails
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateSalesmanExclusive`,
			JSON.stringify(editableFields)
		);

	updateGlosaText = async (glosa: GlosaTextEditableProps): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateGlosaText`,
			JSON.stringify(glosa)
		);

	updateGlosaFields = async (
		glosaFields: GlosaFieldsEditableProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateGlosaFields`,
			JSON.stringify(glosaFields)
		);

	updateAdelanto = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/updateAdelanto/${id}`);

	sendToWorkflowApproval = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.put(`${base}/sendToWorkflowApproval/${id}`);

	updatePriceAndPayment = async (
		editableFields: SendRejectedWorkflowProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/updateForRejectedWorkflow`,
			JSON.stringify(editableFields)
		);

	csvExport = async (
		dataState: State,
		csvOptions: CsvOptionsForQuotationProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csv?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	csvExportForSalesman = async (
		dataState: State,
		csvOptions: CsvOptionsForQuotationProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csvForSalesman?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);
}
