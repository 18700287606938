import { CTADMVEN_QUOTATION } from "../../../constants/routes";
import { MenuRouteProps } from "../../../utils/GenericInterfaces";
import { AppRouteProps } from "../../routes";
import { groupsAllowedToSeeQuotationChileMenu } from "../../../constants/AuthGroups/quotationChilePage";
import { getMenuAuthorized, getRouteListAndDetails } from "../../../utils/RoutesUtil";
import DetailsChile from "./Details/containers/Details";
import MainList from "./List/containers/MainList";
import _ from "lodash";
import { AuthGroupsType } from "../../../constants/AuthGroups/authGroups";
import { TFunction } from "i18next";
import MenuListSubHeader from "../../../components/header/MenuListSubHeader";

export const quotationsChileAllClaims: Array<AuthGroupsType> = _.uniq([
	...groupsAllowedToSeeQuotationChileMenu,
]);

export const quotationChileMenuRoute: MenuRouteProps = {
	path: CTADMVEN_QUOTATION,
	claims: groupsAllowedToSeeQuotationChileMenu,
	element: <MainList />,
	label: "quotation.chile.title",
	isCollapsable: true,
};

export const quotationChileRoutes: Array<AppRouteProps> = getRouteListAndDetails(
	quotationChileMenuRoute,
	<DetailsChile />
);

export function getQuotationsChileMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean,
	open: boolean
) {
	if (!isAuthorizedGroup(quotationsChileAllClaims)) {
		return <></>;
	}

	return (
		<>
			<MenuListSubHeader drawerOpen={open} text={t("chile")} />
			{getMenuAuthorized(quotationChileMenuRoute, t, isAuthorizedGroup)}
		</>
	);
}
