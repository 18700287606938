import { configure } from "mobx";
import BasePreFilterDateStore from "../../../../../PreFilter/Stores/BasePreFilterDateStore";
import { getDateRangeFilterObject } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterCertificateDateStore extends BasePreFilterDateStore {
	getSpecificFilter(): any {
		return getDateRangeFilterObject(
			this.preFilter,
			"orderMCManagementDetail.certificateDate"
		);
	}
}

export default PreFilterCertificateDateStore;
