import { getDateOrDefault, getStringOrDefault } from "../../../../utils/ModelBuilderUItils";
import { InvoicingDeliveryMarketCompanyFieldsProps } from "../interfaces/InvoicingDeliveryMarketCompanyFieldsProps";

export const invoicingDeliveryMarketCompanyFieldsModel: InvoicingDeliveryMarketCompanyFieldsProps = {
	invoicedNumber: "",
	invoicedDate: null,
	deliveredNumber: "",
	deliveredDate: null,
};

export function invoicingDeliveryMarketCompanyFieldsModelBuilder(
  data: any
): InvoicingDeliveryMarketCompanyFieldsProps {
  if (!data) {
    return invoicingDeliveryMarketCompanyFieldsModel;
  }
  return {
		invoicedNumber: getStringOrDefault(data.invoicedNumber),
		invoicedDate: getDateOrDefault(data.invoicedDate),
		deliveredNumber: getStringOrDefault(data.deliveredNumber),
		deliveredDate: getDateOrDefault(data.deliveredDate),
  };
}