import { Box, Button, Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { Search } from "@mui/icons-material";
import { QuotaReadFiltersProps } from "../../interfaces/Sections/QuotaReadFilters";
import LineFilter from "../../../../Filters/LineFilter";
import MonthFilter from "../../../../Filters/MonthFilter";

const QuotaReadFilters: React.FC<QuotaReadFiltersProps> = ({ setFilterByKeyOnChange, setFilter, filter, filterIsValid, children }) => {
    return (
        <Box sx={{ display: "flex" }}>
            <Grid container sx={{ display: "flex", height: "90px" }} spacing={3}>
                <Grid item>
                    <LineFilter
                        set={(e) => setFilterByKeyOnChange("line", e.value)}
                        value={filter.line}
                    />
                </Grid>
                <Grid item>
                    {children}
                </Grid>
                <Grid item>
                    <MonthFilter
                        set={(e) => setFilterByKeyOnChange("date", e.value, e.isValid)}
                        value={filter.date}
                    />
                </Grid>
                <Grid item sx={{ alignSelf: "center" }}>
                    <Button
                        variant="contained"
                        onClick={setFilter}
                        size="small"
                        disabled={!filterIsValid}
                    >
                        <Search fontSize="small" />
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default observer(QuotaReadFilters);
