import { configure } from "mobx";
import BasePreFilterDateStore from "../../../../../PreFilter/Stores/BasePreFilterDateStore";
import { getDateRangeFilterObject } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterGokDateStore extends BasePreFilterDateStore {
	getSpecificFilter(): any {
		return getDateRangeFilterObject(this.preFilter, "greenOk");
	}
}

export default PreFilterGokDateStore;
