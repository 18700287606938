import { configure } from "mobx";
import { FilterDescriptor, } from "@progress/kendo-data-query";
import RootStore from "./RootStore";
import BaseFilterStore from "../../../../../components/PreFilter/Stores/BaseFilterStore";

configure({ enforceActions: "always" });

class FilterStore extends BaseFilterStore {
	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	cleanSpecificPreFilters = () => {
		this.rootStore.preFilterQuotationIDStore.clean();
		this.rootStore.preFilterQuotationDateStore.clean();
		this.rootStore.preFilterStatusSASStore.clean();
		this.rootStore.preFilterFactoryOrderStore.clean();
		this.rootStore.preFilterChassiStore.clean();
		this.rootStore.preFilterSalesmanStore.clean();
		this.rootStore.preFilterReservationStatusStore.clean();
	};

	applySpecificFilters(
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>): any {
		andFilters =
			this.rootStore.preFilterQuotationIDStore.getFilterDescriptorBySize(
				andFilters,
				orFiltersGroups
			);

		andFilters =
			this.rootStore.preFilterQuotationDateStore.getFilterDescriptor(
				andFilters
			);

		this.rootStore.preFilterStatusSASStore.getFilterDescriptor(orFiltersGroups);

		this.rootStore.preFilterReservationStatusStore.getFilterDescriptor(
			orFiltersGroups
		);

		andFilters =
			this.rootStore.preFilterFactoryOrderStore.getFilterDescriptor(andFilters);

		andFilters =
			this.rootStore.preFilterChassiStore.getFilterDescriptor(andFilters);

		this.rootStore.preFilterSalesmanStore.getFilterDescriptor(orFiltersGroups);

		return { andFilters, orFiltersGroups };
	};
}

export default FilterStore;
