import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import { observer } from "mobx-react";
import CommercialSection from "../Sections/CommercialSection";
import VolvoManagementSection from "../Sections/VolvoManagementSection";
import GeneralCommentsSection from "../../../../../components/OrderPages/Details/containers/Sections/GeneralCommentsSection";
import CommercialCommentsSection from "../Sections/CommercialCommentsSection";
import { useStores } from "../../stores/RootStore";
import { Grid, Skeleton } from "@mui/material";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { groupsAllowedToSeeAllVolvoManagementDetails } from "../../../../../constants/AuthGroups/orderDetails";
import { CommercialAccordionProps } from "../../interfaces/Accordions/CommercialAccordion";
import CustomerSection from "../../../../../components/OrderPages/Details/containers/Sections/CustomerSection";

const CommercialAccordion: React.FC<CommercialAccordionProps> = ({
	customerDetails,
	generalComment,
	editVolvoManagementDetailsEnabled,
	editCommercialCommentsVolvoManagementEnabled,
}) => {
	const { t } = useTranslation("order");

	const [loading, setLoading] = React.useState(true);

	const { isAuthorizedGroup } = useAuthStore();
	const { volvoManagementStore } = useStores();

	const showVolvoManagementDetails = isAuthorizedGroup(
		groupsAllowedToSeeAllVolvoManagementDetails
	);

	const fetchCommercialManagement = React.useCallback(async () => {
		if (showVolvoManagementDetails) await volvoManagementStore.load();
		setLoading(false);
	}, [showVolvoManagementDetails, volvoManagementStore]);

	React.useEffect(() => {
		fetchCommercialManagement();
	}, [fetchCommercialManagement]);

	return (
		<AccordionCard
			id="commercialAccordion"
			title={t("details.accordions.commercial")}
		>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={600} />
				</Grid>
			) : (
				<>
					<CustomerSection {...customerDetails} />
					{showVolvoManagementDetails && (
						<>
							<CommercialSection
								editable={editVolvoManagementDetailsEnabled}
								editableFields={volvoManagementStore.editableDetails}
								setEditableFieldByKeyOnChange={
									volvoManagementStore.setEditableFieldByKeyOnChange
								}
							/>
							<VolvoManagementSection
								editable={editVolvoManagementDetailsEnabled}
								editableFields={volvoManagementStore.editableDetails}
								setEditableFieldByKeyOnChange={
									volvoManagementStore.setEditableFieldByKeyOnChange
								}
							/>
							<CommercialCommentsSection
								editable={editCommercialCommentsVolvoManagementEnabled}
								newCommercialComment={volvoManagementStore.newCommercialComment}
								setNewComment={volvoManagementStore.setNewComment}
								deleteVolvoManagementComments={
									volvoManagementStore.deleteVolvoManagementComments
								}
								orderVolvoManagementComments={
									volvoManagementStore.orderVolvoManagementComments
								}
							/>
						</>
					)}
					<GeneralCommentsSection {...generalComment} />
				</>
			)}
		</AccordionCard>
	);
};

export default observer(CommercialAccordion);
