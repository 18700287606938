import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { GeneralSectionProps } from "../../interfaces/Sections/GeneralSection";
import TextForm from "../../../../../FormInputs/TextForm";
import DateForm from "../../../../../FormInputs/DateForm";

const GeneralSection: React.FC<GeneralSectionProps> = ({ readOnlyFields }) => {
	const { t } = useTranslation("order");

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.general.title", {
					order: readOnlyFields.assemblyOrder,
				})}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.factoryOrder")}
					value={readOnlyFields.factoryOrder}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.factory")}
					value={readOnlyFields.factory}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.general.deliveryProposal")}
					value={readOnlyFields.deliveryProposal}
					inputFormat="MMMM/yy"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.chassi")}
					value={readOnlyFields.chassi}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.msp")}
					value={readOnlyFields.msp}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.brand")}
					value={readOnlyFields.brand}
				/>
			</Grid>
		</>
	);
};

export default observer(GeneralSection);
