
import { observer } from "mobx-react";
import { groupsAllowedToEditQuotationReservation, groupsQuotationManager, groupsAllowedToSendToWorkflowApproval, groupsQuotationSalesman } from "../../../../../constants/AuthGroups/quotationChilePage";
import { CountryRegionIso } from "../../../../../enums/CountryRegionIso";
import QuotationDetailIndex from "../../../Components/Details/container/QuotationDetailIndex";
import { useQuotationDetailChileStore } from "../stores/QuotationDetailChileStore";


const DetailsChile: React.FC = () => {
  return (
    <QuotationDetailIndex groupsAllowedToEditQuotationReservation={groupsAllowedToEditQuotationReservation}
      groupsAllowedToSendToWorkflowApproval={groupsAllowedToSendToWorkflowApproval}
      groupsQuotationManager={groupsQuotationManager}
      groupsQuotationSalesman={groupsQuotationSalesman}
      region={CountryRegionIso.CL}
      store={useQuotationDetailChileStore}
    />
  );
}

export default observer(DetailsChile);


