import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../AccordionCard/AccordionCard";
import ModelSection from "../Sections/ModelSection";
import EngineeringCommentsSection from "../Sections/EngineeringCommentsSection";
import ModelSectionBus from "../../../../Details/containers/Sections/Bus/ModelSectionBus";
import { ModelSectionProps } from "../../interfaces/Sections/ModelSection";
import { ModelSectionBusProps } from "../../../../Details/interfaces/Sections/Bus/ModelSectionBus";
import { VehicleAccordionProps } from "../../interfaces/Accordions/VehicleAccordion";
import VehicleIdentificationSection from "../../../../Details/containers/Sections/VehicleIdentificationSection";
import VariantsSection from "../../../../Details/containers/Sections/VariantsSection";

const VehicleAccordion: React.FC<VehicleAccordionProps> = (props) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="vehicleAccordion"
			title={t("details.accordions.vehicle")}
		>
			{props.vehicleType === "Bus" ? (
				<ModelSectionBus {...(props.modelDetails as ModelSectionBusProps)} />
			) : (
				<ModelSection {...(props.modelDetails as ModelSectionProps)} />
			)}

			<VehicleIdentificationSection {...props.vehicleIdentificationDetails} />
			<VariantsSection {...props.variantsDetails} />
			<EngineeringCommentsSection {...props.engineeringCommentsDetails} />
		</AccordionCard>
	);
};

export default observer(VehicleAccordion);
