import CtadmvenApi from "../../apis/CtadmvenApi";
import { State, toDataSourceRequestString } from "@progress/kendo-data-query";
import { CsvOptionsProps } from "../../pages/Order/List/interfaces/CsvOptionsProps";
import { EuropeEditDetailsProps } from "../../components/OrderPages/Europe/DetailsBase/interfaces/EuropeEditDetailsProps";

const base = "/europeOrderbook";

export class EuropeFactoryService {
	constructor(private api: CtadmvenApi) {}

	listPaginated = async (dataState: State, menu: string): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/list/${menu}?${toDataSourceRequestString(dataState)}`
		);

	csvExport = async (
		dataState: State,
		menuKey: string,
		csvOptions: CsvOptionsProps
	): Promise<any> =>
		this.api.ctadmvenClient.post(
			`${base}/csv/${menuKey}?${toDataSourceRequestString(dataState)}`,
			JSON.stringify(csvOptions)
		);

	getDetails = async (menu: string, europeOrderSASId: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/${menu}/${europeOrderSASId}`);

	getLocalization = async (
		menu: string,
		europeOrderSASId: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/localization/${menu}/${europeOrderSASId}`
		);

    getDetailsVariants = async (
		menu: string,
		europeOrderSASId: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/variants/${menu}/${europeOrderSASId}`
		);

	getInvoicingDeliveryMarketCompanyByEuropeOrderSASId = async (
		menu: string,
		europeOrderSASId: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/invoiceMarketcompany/${menu}/${europeOrderSASId}`
		);

	update = async (
		menu: string,
		orderDetails: EuropeEditDetailsProps
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/${menu}`,
			JSON.stringify(orderDetails)
		);

	getCountryFilterOptions = async (menu: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/filterCountry/${menu}`);

	getFactoryOrderByChassi = async (
		chassi: string,
		country: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/vehicleInfoByChassi/${chassi}?country=${country}`
		);

	getChassiByFactoryOrder = async (
		factoryOrder: string,
		country: string
	): Promise<any> =>
		this.api.ctadmvenClient.get(
			`${base}/vehicleInfoByFactoryOrder/${factoryOrder}?country=${country}`
		);
}
