import React from "react";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import { InvoicingDeliveryMarketCompanySectionProps } from "../../interfaces/Sections/InvoicingDeliveryMarketCompanySection";
import TextForm from "../../../../FormInputs/TextForm";
import DateForm from "../../../../FormInputs/DateForm";
import OverlineTextCentered from "../../../../OverlineTextCentered/OverlineTextCentered";
import { useTranslation } from "react-i18next";
import { objectHasValue } from "../../../../../utils/ObejctUtils";

const InvoicingDeliveryMarketCompanySection: React.FC<
	InvoicingDeliveryMarketCompanySectionProps
> = ({ fields, loadInvoicingDeliveryMarketCompany }) => {
	const { t } = useTranslation(["order", "general"]);
	const [loading, setLoading] = React.useState(true);

	const fetchInvoicingDeliveryMarketCompany = React.useCallback(async () => {
		await loadInvoicingDeliveryMarketCompany().finally(() => setLoading(false));
	}, []);

	React.useEffect(() => {
		fetchInvoicingDeliveryMarketCompany();
	}, [fetchInvoicingDeliveryMarketCompany]);

	return (
		<>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : (
				<>
					<Grid className="accordionSubTitle" item xs={12}>
						{t("details.sections.invoicingDeliveryMarketCompany.title")}
					</Grid>
					{objectHasValue(fields) ? (
						<>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentNumber"
									)}
									value={fields.invoicedNumber}
								/>
							</Grid>
							<Grid item xs={4}>
								<DateForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentDate"
									)}
									value={fields.invoicedDate}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentType.label"
									)}
									value={t(
										"details.sections.invoicingDeliveryMarketCompany.documentType.invoiced"
									)}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentNumber"
									)}
									value={fields.deliveredNumber}
								/>
							</Grid>
							<Grid item xs={4}>
								<DateForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentDate"
									)}
									value={fields.deliveredDate}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoicingDeliveryMarketCompany.documentType.label"
									)}
									value={t(
										"details.sections.invoicingDeliveryMarketCompany.documentType.delivered"
									)}
								/>
							</Grid>
						</>
					) : (
						<Grid item xs={12}>
							<OverlineTextCentered>
								{t("noRecordsAvailable", { ns: "general" })}
							</OverlineTextCentered>
						</Grid>
					)}
				</>
			)}
		</>
	);
};

export default observer(InvoicingDeliveryMarketCompanySection);
